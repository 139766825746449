import _logger, { LOGGER_CONFIG_DEFAULT } from "../../utils/logger";
import React, { useRef } from 'react';
import { asArray, DELETE_NESTED_KEY, getNestedKeyValue, setNestedKeyValue } from '../../utils/commonUtils';
import FieldFactory from '../../components/FieldFactory/FieldFactory';
import { FieldFactoryField } from '../../components/FieldFactory/FieldFactoryInterface';
import { getTagAttributes } from '../widgetUtils';
import { IFieldWidgetProps } from './FieldWidgetSchema';
import cloneDeep from "lodash/cloneDeep"; // SERVER

// var __filename = "FieldWidgetViewMode.ts";   // OVERRIDE FOR CLIENT BROWSER USE - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");


const FieldWidget: React.FC<IFieldWidgetProps> = (props) => {
    const contentRef = useRef<React.ReactElement | null>(null);

    const updateField = (field: FieldFactoryField): boolean => {
        if (field?.varPath) {
            logger.debug(`Update '${field.varPath} = ${field.value}'`);

            // TODO: Update both VARS AND LAYOUT as specified by varPath
            // FOR NOW -- Just setting vars and not supporting varPaths beginning with period (.message) which should modify layout itself

            // Update parent if requested and/or process varPath if updateFields returns false
            if (!props.updateField || !props.updateField?.(field)) {
                // Run if either there is no updateFields function, or updateFields returns false
                // Use varPath
                if (field.varPath) {
                    const _cKey = props._contextKey || props.hydratedLayout?._contextKey || props.layout?._contextKey;
                    const contextPath = _cKey && `contextVarsMap.${props._contextKey}`;
                    const vars = cloneDeep(props.vars);
                    setNestedKeyValue(vars, field.varPath, field.value === field.excludeVarValue ? DELETE_NESTED_KEY : field.value, contextPath);
                    props.actionDispatcher?.({ action: "BubbleChanges", vars });
                }
            }
        }

        // logger.debug(`Updated vars`, props.vars);
        return false;
    }
    const renderContent = (): React.ReactElement | null => {
        const fields = asArray<FieldFactoryField>(props.layout?.field);
        const elements = fields.map((field, index) => {
            const ctxKey = props._contextKey;
            const contextPath = ctxKey && `contextVarsMap.${props._contextKey}`;
            const key = ctxKey || field.fieldType + `${field.fieldKey || index}`;

            try {
                if (field.varPath) {
                    // field.value = getNestedKeyValue(props.hydratedVars, field.varPath, contextPath);
                    field.value = getNestedKeyValue(props.vars, field.varPath, contextPath);
                    // field.value = undefined;
                }
            } catch (e) {
                logger.error(`Error setting field`, e);
            }

            return <FieldFactory key={key} field={field} updateField={updateField} />
        });
        const element = elements ? <>{elements}</> : null;
        contentRef.current = element;
        return element;
    }

    // SSR render on first pass, otherwise use contentRef that may be updated bu hooks
    // let content: React.ReactElement | null = contentRef.current || renderContent() || null;
    let content: React.ReactElement | null = renderContent() || null;
    return content;
};

export default FieldWidget;
