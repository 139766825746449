import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './TextWidgetViewMode';

// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./TextWidgetDesignMode'));

export const TextWidgetType = "widget/Text";

export interface ITextWidgetVars {
    text: string;
}

export interface ITextWidgetLayout extends IWidgetLayout, ITextWidgetVars { }
export interface ITextWidgetInstance extends IWidgetLayoutTuple<ITextWidgetLayout, ITextWidgetVars> { }
export interface ITextWidgetProps extends IWidgetProps<ITextWidgetLayout, ITextWidgetVars> { }
export interface ITextWidgetSchema extends IWidgetSchema<typeof TextWidgetType, ITextWidgetProps> { }

export const textWidgetSchema: ITextWidgetSchema = {
    widgetType: TextWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
