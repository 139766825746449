import _logger, { LOGGER_CONFIG_DEFAULT } from "../../utils/logger";
import React, { useState, useEffect, useRef } from "react";
import Widget from "../Widget";
import { IWidgetLayout, IWidgetLayoutType } from "../WidgetInterface";
import { deepEqual } from 'fast-equals';
import { newContextKey } from "../widgetUtils";
import { ILayoutContainerWidgetProps } from "./LayoutContainerWidget";


//=====[ LOGGER ]==================================================================================

const logRender = true;
const logHookExecution = true;
const wtfChanged = true;

const __filename = "LayoutContainer_Widget.tsx";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

const LayoutContainerWidget: React.FC<ILayoutContainerWidgetProps> = (props) => {
    const childWidgetElementsRef = useRef<JSX.Element[]>();
    const [forceRenderObj, forceRender] = useState<{}>({});     // Usage: forceRender({});

    /**
     * UPDATE CHILD ELEMENTS
     */
    // const updateChildElements = useCallback(() => {
    useEffect(() => {
        logHookExecution && logger.debug(`[${props.hydratedLayout?._contextKey || props.layout?._contextKey}] useEffect regenerate childWidgetElements`);
        const _contextKeys: string[] = [];
        const _contextKeysConflicts: string[] = [];
        const vars = props.hydratedVars || props.vars;
        const stylesCache = props.stylesCache;
        const _widgets: IWidgetLayoutType = props.hydratedLayout?._widgets || props.layout?._widgets || [];
        const childWidgetElements: Array<JSX.Element> = [];

        // Update the layout array (update, add, remove widgets)
        Array.isArray(_widgets) && _widgets?.forEach((roChildLayout: IWidgetLayout | string, index: number) => {
            if (typeof roChildLayout === "object") {
                let childLayout: IWidgetLayout;
                childLayout = roChildLayout;

                // Ensure a unique key for every element in this scope
                const keyConflict = childLayout._contextKey ? _contextKeys.includes(childLayout._contextKey) : false;
                keyConflict && _contextKeysConflicts.push(childLayout._contextKey!);
                keyConflict && logger.error(`_contextKey Conflict: ${childLayout._contextKey}`);
                const key = keyConflict ? newContextKey() : (childLayout._contextKey || newContextKey());
                _contextKeys.push(key);

                // Find the existing element and use it if nothing has changed
                let element: JSX.Element | undefined = childWidgetElementsRef.current?.find((element: JSX.Element, index) => {
                    const layoutChanged = !deepEqual(childLayout, element.props.layout);
                    let varsChanged = !deepEqual(vars, element.props.vars);
                    const stylesCacheChanged = !deepEqual(stylesCache?.serializedStylesString, element?.props?.stylesCache?.serializedStylesString);
                    if (element?.key !== key || props.cardMode !== element.props.cardMode || layoutChanged || varsChanged || stylesCacheChanged) {
                        return false; // Something changed, re-render the element
                    } else {
                        return true;
                    }
                });

                if (!element) {
                    logHookExecution && logger.debug(`[${props.hydratedLayout?._contextKey || props.layout?._contextKey}] CREATE NEW CHILD ELEMENT: ${key}`);

                    // It wasn't found, or something changed -- build a new widget element
                    const propsUnion = {
                        key,
                        cardMode: props.cardMode,
                        layout: childLayout,
                        vars,
                        stylesCache,
                        actionDispatcher: props.actionDispatcher,
                        // didMount: childWidgetDidMount,   // Allows implementation of widget to override (redirect functions and update values)
                        // didLoad,
                    };
                    element = <Widget {...propsUnion} />
                }

                _contextKeysConflicts.length > 0 && logger.error(`_contxtKey conflicts within scope`, _contextKeysConflicts); // TODO: Surface this error in GUI
                element && childWidgetElements.push(element);
            }
        });

        childWidgetElementsRef.current = childWidgetElements;
        forceRender({});
        // }, [props.actionDispatcher, props.layout, props.vars, props.cardMode]);
    }, [props]);

    return (<div className={props._class}>{childWidgetElementsRef.current}</div>);
};

export default LayoutContainerWidget;
