import _logger, { LOGGER_CONFIG_DEFAULT } from "../../utils/logger";
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import FieldFactory, { valiateField } from '../../components/FieldFactory/FieldFactory';
import { FieldFactoryField, FieldFactoryFieldType, IFieldFactoryEmailField, IFieldFactoryPhoneField, IFieldFactoryStringField, IFieldFactoryTextField } from '../../components/FieldFactory/FieldFactoryInterface';
import { CardMode } from '../../state/cardState';
import FieldWidget from '../FieldWidget/FieldWidget';
import { getTagAttributes } from '../widgetUtils';
import type { ISignupFormWidgetProps } from './SignupFormWidget';
import { Button } from "@mui/material";
import { postQuery } from "../../utils/clientUtils";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import styled from "@emotion/styled";

const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

const firstNameField: IFieldFactoryStringField = {
    fieldType: FieldFactoryFieldType.String,
    fieldKey: "firstName",
    label: "First Name",
    varPath: "firstName",
    liveValidation: true,
    // value: "",
    validationTest: { validator: "isLength", min: 1, max: 30 },
    helperText: "First name must be 1-30 characters",
    // required: true,
}

const lastNameField: IFieldFactoryStringField = {
    fieldType: FieldFactoryFieldType.String,
    fieldKey: "lastName",
    label: "Last Name",
    varPath: "lastName",
    value: "",
    validationTest: { validator: "isLength", min: 0, max: 30 },
    helperText: "Last name must be 0-30 characters",
}

const phoneField: IFieldFactoryPhoneField = {
    fieldType: FieldFactoryFieldType.Phone,
    fieldKey: "phone",
    label: "Phone",
    varPath: "phone",
    value: "",
    validationTest: [],
    // validationPipeline: [
    //     { validator: "isLength", min: 0, max: 0 },
    //     {
    //         validator: "condition", any: [
    //             { validator: "isLength", min: 0, max: 0 }
    //         ]
    //     },
    //     { validator: "isEmail" }
    // ],
    helperText: "Enter valid mobile phone number",
    // required: true,
}

const emailField: IFieldFactoryEmailField = {
    fieldType: FieldFactoryFieldType.Email,
    fieldKey: "email",
    label: "Email",
    varPath: "email",
    value: "",
    validationTest: [
        {
            validator: "any", of: [
                // { validator: "isLength", value: 0 },
                { validator: "isEmail" },
            ]
        }
    ],
    helperText: "Enter a valid email address",
    // required: true,
}

const messageField: IFieldFactoryTextField = {
    fieldType: FieldFactoryFieldType.Text,
    fieldKey: "message",
    label: "Message",
    varPath: "message",
    value: "",
}

const SignupForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: unset;
`

const SignupButtonBox = styled.div`
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
`

const FormMessage = styled.h3`
    color: red;
`

const SignupFormWidgetViewMode: React.FC<ISignupFormWidgetProps> = (props) => {
    const contentRef = useRef<React.ReactElement | null>(null);
    const [formVars, setFormVars] = useState<any>({});
    const { register, handleSubmit, formState: { errors } } = useForm();
    // const onSubmit = (data: any) => console.log(data);
    const [liveValidation, setLiveValidation] = useState(false);
    const [formMessage, setFormMessage] = useState<React.ReactElement | null>(null);
    const [response, setResponse] = useState<React.ReactElement | null>(null);
    const [fields, setFields] = useState<{ [fieldName: string]: FieldFactoryField }>({
        firstName: firstNameField,
        lastName: lastNameField,
        phone: phoneField,
        email: emailField,
        message: messageField,
    })

    const updateField = (field: FieldFactoryField) => {
        if (field.fieldKey) {
            setFields((prev: any) => {
                const uFields = { ...prev };
                // uFields[field.fieldKey!] = { ...uFields[field.fieldKey!], ...field };
                uFields[field.fieldKey!].value = field.value;
                uFields[field.fieldKey!].focused = field.focused;
                uFields[field.fieldKey!].touched = field.touched;
                uFields[field.fieldKey!].changed = field.changed;
                uFields[field.fieldKey!].validationResult = field.validationResult;
                return uFields;
            })
            // fieldsRef.current[field.fieldKey].value = field.value;
            return true;
        } else {
            return false;
        }
    }

    const validateForm = (live: boolean = true): boolean => {
        let isValid = true;
        Object.keys(fields).forEach((fieldName) => {
            const field = fields[fieldName];
            if (live) {
                field.validationResult = valiateField(field);
                isValid = !isValid ? isValid : field.validationResult?.isValid;
            } else {
                const validationResult = valiateField(field);
                isValid = !isValid ? isValid : validationResult.isValid;
            }
        })
        return isValid;
    }

    const submit = () => {
        if (!props.layout.url) throw new Error("invalid form endpoint");

        const isValid = validateForm();
        if (!isValid) {
            setFormMessage(<FormMessage>Please fix incorrect fields.</FormMessage>);
            setLiveValidation(true);
            return;
        }

        setFormMessage(null);
        const formData: any = {
            firstName: fields.firstName.value,
            lastName: fields.lastName.value,
            phone: fields.phone.value,
            email: fields.email.value,
            message: fields.message.value,
        }
        // Remove Empty Fields
        for (const key in formData) {
            const value = formData[key];
            if (!value || value === "") {
                delete formData[key];
            }
        }
        /**
         * EXAMPLE
         * curl --request POST https://dev1.rolocard.com/api/v1/pub -H "Content-Type: application/json" -d '{"query": "query hello {hello}"}'
         */
        // const query = `query hello { hello }`;
        const query = `mutation formSub { formSub(formData: """${JSON.stringify(formData)}""") }`;

        // setRequest(JSON.stringify({ url: props.layout.url, query }));
        postQuery(props.layout.url, query)
            .then((resp: AxiosResponse<{ data: any }>) => {
                // setResponse(resp.data.data.formSub);
                setResponse(
                    <>
                        <div>
                            <h1>Yay!</h1>
                            <h2><>{fields.firstName.value ? `${fields.firstName.value}, thanks for signing up.` : "Thanks for signing up."}</></h2>
                            <br />
                        </div>
                        <Button size="large" variant="outlined" onClick={goHome}>Go Home</Button>
                    </>
                );
            })
            .catch((err) => {
                // setResponse(JSON.stringify(err));
                setResponse(
                    <>
                        <div>
                            <h1>Whoops!</h1>
                            <h2>Something went wrong.</h2><br />
                        </div>
                        <Button size="large" variant="outlined" onClick={retry}>Go Back</Button>
                    </>);
            })
    }

    const retry = () => {
        setResponse(null);
    }

    const goHome = () => {
        props.actionDispatcher?.({
            action: "Navigate",
            url: "/"
        })
    }

    const renderContent = () => {
        // logger.debug("*** RE-RENDER CONTENT ***");

        {/* <div {...getTagAttributes(props)} style={{ height: props.hydratedLayout?.captcha }} />; */ }
        // const fieldProps: IFieldFactoryProps = {
        //     field: firstNameField,
        //     manualUpdates: false,
        //     updateFields: () => { },
        //     actionDispatcher: undefined,
        // }

        const element = <SignupForm>
            {response
                ? <>
                    {response}
                </>
                : <>
                    <h1>Sign up for Deets™</h1>
                    <FieldFactory key={"firstNameField"} field={fields.firstName} liveValidation={liveValidation} updateField={updateField} />
                    <FieldFactory key={"lastNameField"} field={fields.lastName} liveValidation={liveValidation} updateField={updateField} />
                    <FieldFactory key={"phoneField"} field={fields.phone} liveValidation={liveValidation} updateField={updateField} />
                    <FieldFactory key={"emailField"} field={fields.email} liveValidation={liveValidation} updateField={updateField} />
                    <FieldFactory key={"messageField"} field={fields.message} liveValidation={liveValidation} updateField={updateField} />
                    {formMessage}
                    <SignupButtonBox>
                        <Button size="large" variant="outlined" onClick={submit}>Sign Up</Button>
                    </SignupButtonBox>
                </>
            }
        </SignupForm>
        contentRef.current = element;
        return element;
    }

    // useEffect(() => {
    //     console.log("SignupFormWidgetViewMode: useEffect");
    //     contentRef.current = renderContent() || null;
    // }, [props.vars, props.hydratedVars])

    // SSR render on first pass, otherwise use contentRef that may be updated bu hooks
    // let content: React.ReactElement | null = contentRef.current || renderContent() || null;


    // let content: React.ReactElement | null = renderContent() || null;

    let content: React.ReactElement | null;
    if (!contentRef.current) {
        content = renderContent();
        contentRef.current = content;
    } else {
        content = contentRef.current;
    }

    // logger.debug("*** RENDER ***");
    content = renderContent();
    return content;
};

export default SignupFormWidgetViewMode;



            // <div>
            //     <div>{fields?.firstName?.focused ? <b>First Name:</b> : <>First Name:</>} {fields?.firstName?.value}</div>
            //     <div>{fields?.lastName?.focused ? <b>Last Name:</b> : <>Last Name:</>} {fields?.lastName?.value}</div>
            //     <div>{fields?.phone?.focused ? <b>Phone:</b> : <>Phone:</>} {fields?.phone?.value}</div>
            //     <div>{fields?.email?.focused ? <b>Email:</b> : <>Email:</>} {fields?.email?.value}</div>
            //     <div style={{ whiteSpace: "pre-line" }}>{fields?.message?.focused ? <b>Message:</b> : <>Message:</>} {fields?.message?.value}</div>
            //     {/* <div><b>Requst:</b> {request}</div> */}
            //     <div>Response: {response}</div>
            // </div><br />

