/**
 *
 * Copyright 2022, Sebastian Rapport
 * Rapport License
 *
 * Created by srapport@troppar.com
 *
 */

import _logger, { LOGGER_CONFIG_DEFAULT } from "../../utils/logger";
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import { IStylesCache, prepareCss } from "../../utils/styleUtils";
import { ErrorBoundary } from 'react-error-boundary';
import { _contextKey, IWidgetLayout, WidgetAction, _widgetType, ILayoutChanges, IUpdateLayoutArgs, IWidgetProps } from "../WidgetInterface";
import { ICardRendererProps, IPageVars } from "../CardInterface";
import { CardMode, cardModeAtom } from "../../state/cardState";
import Widget from "../Widget";
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useRecoilState } from "recoil";
import { seedLayoutKeys, hydrateLayoutChanges, hydrateDryLayout, resolveStyleName } from "../widgetUtils";
//  import ShareCardWidget from "../ShareCard_Widget/ShareCard_Widget_View";
//  import { TopNavIcon, TopNavStyle, useTopNav } from "../../../components/TopNav/TopNav";

//=====[ LOGGER ]==================================================================================

const logRender = true;
const logHookExecution = true;

const __filename = "DynamicCard.tsx";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

export const DynamicCard_widgetType = "system/DynamicCard_v1";

const CardRoot = styled.div<{ cardRootStyle?: string }>`
     width: 100%;
     height: 100%;
     overflow: auto auto;
     box-sizing: border-box;
     ${(props: any) => props.cardRootStyle || ""}
 `

const ShareCardContainer = styled.div`
     @keyframes revealShare { 0% {opacity: 0;} 100% {opacity: 1;} }
     opacity: 0;
     animation: revealShare 1s forwards;
 `

// const RenderLayer = styled.div`
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     /* border: 4px dotted lime; */
// `

// const ModeWrapper = styled.div<{ cardMode: CardMode }>`
//     height: 100%;
//     width: 100%;
//     overflow: hidden auto;
//     ${props => props.cardMode === CardMode.Share && {
//         padding: "20px 20px 20px 20px",
//     }}
// `;

export interface IDynamicCardProps extends ICardRendererProps { };
export interface IDynamicCardWidgetLayout extends IWidgetLayout { };
export interface IDynamicCardWidgetVars extends IPageVars { };

let refresherIntervalTimerSingleton: any = undefined;
let refresherCounterSingleton = 25;

const DynamicCard: React.FC<IDynamicCardProps> = (props) => {
    const [cardMode, setCardMode] = useRecoilState(cardModeAtom);
    const varsRef = useRef<IPageVars | undefined>();
    const hydratedVarsRef = useRef<IPageVars | undefined>();
    const layoutRef = useRef<IWidgetLayout | undefined>();
    const hydratedLayoutRef = useRef<IWidgetLayout | undefined>();
    const stylesCacheRef = useRef<IStylesCache | undefined>();
    const globalStylesCacheRef = useRef<IStylesCache | undefined>();
    const contentRef = useRef<React.ReactNode>(undefined);
    //  const { setTopNav, showTopNav } = useTopNav();

    const [forceRenderObj, forceRender] = useState<{}>({});     // Usage: forceRender({});
    const [layoutRev, setLayoutRev] = useState<number>(0);      // Usage: setLayoutRev( rev => (rev + 1));
    const layoutRevRef = useRef(-1);                            // Last layout revision rendered

    const bumpLayoutRev = useCallback((layout: IWidgetLayout | undefined, vars?: IPageVars, bubble: boolean = false) => {
        setLayoutRev(prev => {
            // logHookExecution && logger.debug(`bumpLayoutRev of [${_contextKey}] to ${prev + 1}`);
            return (prev + 1);
        });
        if (bubble) {
            props.actionDispatcher?.({
                action: "BubbleChanges",
                layout,
                vars,
            });
        }
    }, [props.actionDispatcher]);

    /**
     * Process new layout
     */
    const processNewLayout = (updatedLayout: IWidgetLayout | undefined, updatedVars?: IPageVars, bubble: boolean = false): ILayoutChanges | undefined => {
        if (updatedLayout || updatedVars) {
            // const keyedLayout = seedLayoutKeys(updatedLayout);
            const layoutChangeArgs: IUpdateLayoutArgs = {
                prevVars: varsRef.current,
                prevHydratedVars: hydratedVarsRef.current,
                prevLayout: layoutRef.current,
                prevHydratedLayout: hydratedLayoutRef.current,
                prevStylesCache: stylesCacheRef.current,
                prevGlobalStylesCache: globalStylesCacheRef.current,
                // updatedLayout: keyedLayout,
                updatedLayout,
                updatedVars,
            }
            const changes = hydrateLayoutChanges(layoutChangeArgs);

            // UPDATE THE CHANGES CACHED HERE IN THIS CONTEXT
            if (changes?.varsChanged) varsRef.current = changes.vars;
            if (changes?.hydratedVarsChanged) hydratedVarsRef.current = changes.hydratedVars;
            if (changes?.layoutChanged) layoutRef.current = changes.layout;
            if (changes?.hydratedLayoutChanged) hydratedLayoutRef.current = changes.hydratedLayout;
            if (changes?.stylesChanged) stylesCacheRef.current = changes.stylesCache;
            if (changes?.globalStylesChanged) globalStylesCacheRef.current = changes.globalStylesCache;

            changes && bumpLayoutRev(changes.layout, changes.vars, bubble);
            return changes;
        }

        return undefined;
    }

    const renderContent = (): React.ReactNode => {
        // if (layoutRev === 0 || !layoutRef.current) return;
        if (!layoutRef.current) return;

        const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = {
            _key: hydratedLayoutRef.current?._key,
            cardMode,
            vars: varsRef.current,                  // Passed down for upstream changes
            hydratedVars: hydratedVarsRef.current,
            layout: layoutRef.current,              // Passed down for upstream changes
            hydratedLayout: hydratedLayoutRef.current,
            stylesCache: stylesCacheRef.current,
            resolveStyleName,
            hydrateDryLayout,
            actionDispatcher,
            // actionDispatcher: props.actionDispatcher,
            // didMount: childWidgetDidMount,   // Allows implementation of widget to override (redirect functions and update values)
            // didLoad,
        };

        let element: React.ReactElement;
        switch (cardMode) {
            //  case CardMode.Share:
            //      element = <ShareCardContainer>
            //          <ShareCardWidget {...propsUnion} />
            //          <Widget {...propsUnion} />
            //      </ShareCardContainer>;
            //      break;

            default:
            case CardMode.Configure:
            case CardMode.SiteView:
            case CardMode.View:
                element = <Widget {...propsUnion} />;
                break;
            // default:
            //     element = <ErrorPage>Unsupported Card Mode</ErrorPage>
        }

        return element;
    }

    useEffect(() => {
        if (props.cardAttributes.layout?._cardMode === CardMode.SiteView) {
            setCardMode(CardMode.SiteView);
        }
    }, [props.cardAttributes.layout]);

    //
    // The layout or vars that were passed in by the parent are new or changed -- hydrate and bump the layoutRev
    //
    // useEffect(() => {
    //     // logHookExecution && logger.debug(`[${_contextKey}] useEffect props.layout`);

    //     let vars = props.cardAttributes.vars;
    //     let layout: IWidgetLayout | undefined;
    //     //  const topNavObj = {
    //     //      visible: props.cardAttributes.layout?._cardMode !== CardMode.SiteView,
    //     //      navStyle: TopNavStyle.PushContent,
    //     //      title: props.cardAttributes?.title || "",
    //     //      leftPrompt: "",
    //     //      rightPrompt: "",
    //     //  }

    //     switch (cardMode) {
    //         case CardMode.Configure:
    //             if (!props.cardAttributes.configureLayout) throw new Error("Configuration missing");
    //             layout = props.cardAttributes.configureLayout;
    //             //  Object.assign(topNavObj, {
    //             //      leftIcon: TopNavIcon.Cancel,
    //             //      leftFunction: () => setCardMode(CardMode.View),
    //             //      rightIcon: TopNavIcon.Eye,
    //             //      rightFunction: () => setCardMode(CardMode.View),
    //             //  });
    //             break;
    //         case CardMode.Share:
    //             if (!props.cardAttributes.shareLayout) throw new Error("Configuration missing");
    //             layout = props.cardAttributes.shareLayout;
    //             //  Object.assign(topNavObj, {
    //             //      leftIcon: TopNavIcon.Cancel,
    //             //      leftFunction: () => setCardMode(CardMode.View),
    //             //      rightIcon: TopNavIcon.Eye,
    //             //      rightFunction: () => setCardMode(CardMode.View),
    //             //  });
    //             break;
    //         case CardMode.SiteView:
    //         case CardMode.View:
    //         default:
    //             // layout = layoutRef.current || props.cardAttributes.layout;
    //             layout = props.cardAttributes.layout;
    //             //  Object.assign(topNavObj, {
    //             //      leftIcon: TopNavIcon.Back,
    //             //      // leftFunction: () => setLocation("/mycards"),
    //             //      leftFunction: () => {
    //             //          window.history.back();
    //             //      },
    //             //      ...(props.cardAttributes.shareLayout && {
    //             //          rightIcon: TopNavIcon.Share,
    //             //          rightFunction: () => setCardMode(CardMode.Share),
    //             //      }),
    //             //  });
    //             break;
    //     }

    //     //
    //     // Set
    //     //
    //     // const layoutChangeArgs: IUpdateLayoutArgs = {
    //     //     prevVars: varsRef.current,
    //     //     prevHydratedVars: hydratedVarsRef.current,
    //     //     prevLayout: layoutRef.current,
    //     //     prevHydratedLayout: hydratedLayoutRef.current,
    //     //     prevStylesCache: stylesCacheRef.current,
    //     //     prevGlobalStylesCache: globalStylesCacheRef.current,
    //     // }
    //     // if (props.cardAttributes.vars) layoutChangeArgs.updatedVars = props.cardAttributes.vars;
    //     // if (props.cardAttributes.layout) layoutChangeArgs.updatedLayout = props.cardAttributes.layout;
    //     // const changes = hydrateLayoutChanges(layoutChangeArgs);
    //     const changes = processNewLayout(props.cardAttributes.layout, props.cardAttributes.vars);
    //     //  setTopNav(topNavObj);


    //     // update content
    //     contentRef.current = renderContent();
    //     contentRef.current = <div>RENDERED CONTENT</div>;

    // }, [props.cardAttributes.layout, props.cardAttributes.vars, cardMode]);

    const errorFallback = useCallback(({ error, componentStack, resetErrorBoundary }: any) => {
        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <pre>{componentStack}</pre>
                <button onClick={() => {
                    // setLocation("/mycards");
                    resetErrorBoundary();
                }}>Go Back</button><br /><br />
                Or, you could ... <div style={{ width: "auto", border: "2px dashed white" }} onClick={resetErrorBoundary}>[ Try Again ]</div>

            </div>
        )
    }, []);

    //
    // DO NOT DELETE THIS HACK (REFERENCE PURPOSES)
    //
    // TODO: FIX THIS STUPID HACK!!!
    // REF: https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.refresh()
    //
    // refresherIntervalTimerSingleton && clearInterval(refresherIntervalTimerSingleton);
    // const refresher = function () {
    //     ScrollTrigger.refresh();
    //     refresherCounterSingleton--;
    //     if (refresherCounterSingleton <= 0) {
    //         clearInterval(refresherIntervalTimerSingleton);
    //     }
    // }
    // refresherIntervalTimerSingleton = setInterval(refresher, 1000);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 250);
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 500);
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 1000);
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 1500);
    }, []);

    //
    // DO NOT DELETE, FOR REFERENCE IN HEIGHT TRIGGERS (REFERENCE PURPOSES)
    //
    // const { ref: resizeRef, height = 1 } = useResizeObserver<HTMLDivElement>();
    // useEffect(() => {
    //     // Resize Observer: https://github.com/ZeeCoder/use-resize-observer
    //     ScrollTrigger.refresh();
    // }, [height]);

    // --------------------------------------------------------------------------------------------
    // ---------- ACTION DISPATCHER ---------------------------------------------------------------
    // ----------

    // TODO: Do we need to defer (any/all) actions until root context?

    const actionQueue = useRef<WidgetAction[]>([]);
    const [processActionQueue, setProcessActionQueue] = useState<{}>(); // Usage: setProcessActionQueue({});
    const actionDispatcher = useCallback((action?: WidgetAction, deferAction: boolean = false) => {

        const doAction = (action: WidgetAction) => {
            // logHookExecution && logger.debug(`[${_contextKey}] doAction ${action?.action}`);
            switch (action?.action) {
                case "BubbleChanges": {
                    const updateLayoutArgs: IUpdateLayoutArgs = {
                        // layoutRef,
                        // hydratedLayoutRef,
                        // varsRef,
                        // hydratedVarsRef,
                        // stylesCacheRef,
                        // // ...("layout" in action && { updatedLayout: action.layout }),
                        // ...("vars" in action && { updatedVars: action.vars }),
                    };

                    // const layoutChangeArgs: IUpdateLayoutArgs = {
                    //     prevVars: varsRef.current,
                    //     prevHydratedVars: hydratedVarsRef.current,
                    //     prevLayout: layoutRef.current,
                    //     prevHydratedLayout: hydratedLayoutRef.current,
                    //     prevStylesCache: stylesCacheRef.current,
                    //     prevGlobalStylesCache: globalStylesCacheRef.current,
                    // }
                    // if (action.vars) layoutChangeArgs.updatedVars = action.vars;
                    // if (action.layout) layoutChangeArgs.updatedLayout = action.layout;
                    // const changes = hydrateLayoutChanges(layoutChangeArgs);
                    const changes = processNewLayout(action.layout, action.vars);

                    // if (changes) {
                    //     bumpLayoutRev(true);
                    // }
                    break;
                }

                case "UpdateLayout": {
                    // const layoutChangeArgs: IUpdateLayoutArgs = {
                    //     prevVars: varsRef.current,
                    //     prevHydratedVars: hydratedVarsRef.current,
                    //     prevLayout: layoutRef.current,
                    //     prevHydratedLayout: hydratedLayoutRef.current,
                    //     prevStylesCache: stylesCacheRef.current,
                    //     prevGlobalStylesCache: globalStylesCacheRef.current,
                    // }
                    // if (action.vars) layoutChangeArgs.updatedVars = action.vars;
                    // if (action.layout) layoutChangeArgs.updatedLayout = action.layout;
                    // const changes = hydrateLayoutChanges(layoutChangeArgs);
                    const changes = processNewLayout(action.layout, action.vars)


                    // const updateLayoutArgs: IUpdateLayoutArgs = {
                    //     layoutRef,
                    //     hydratedLayoutRef,
                    //     varsRef,
                    //     hydratedVarsRef,
                    //     stylesCacheRef,
                    //     ...("layout" in action && { updatedLayout: action.layout }),
                    //     ...("vars" in action && { updatedVars: action.vars }),
                    // };
                    // const changes = updateLayout(updateLayoutArgs);

                    // if (changes) {
                    //     bumpLayoutRev(true);
                    // }
                    break;
                }

                // case "Navigate":
                //     window.location.href = action.url;
                //     break;

                default:
                    // Bubble everything else to parent
                    props.actionDispatcher?.(action);
                    break;
            }
        }

        // Push new action and then either defer or process queue now
        action && actionQueue.current.push(action);
        if (deferAction || action?.deferAction) {
            // Defer or process pending queue
            // logHookExecution && logger.debug(`[${_contextKey}] defer doAction ${action?.action}`);
            debugger
            setProcessActionQueue({});
        } else {
            // Process all pending actions
            // logHookExecution && logger.debug(`[${_contextKey}] process actionQueue[${actionQueue.current.length}]`);
            while (actionQueue.current.length > 0) {
                const action = actionQueue.current.shift();
                action && doAction(action);
            }
        }
    }, [props.actionDispatcher, setProcessActionQueue]);

    // Process pending actions
    useEffect(() => {
        // logHookExecution && logger.debug(`[${_contextKey}] useEffect processActionQueue`);
        actionDispatcher();
    }, [processActionQueue]);

    // ----------
    // ---------- ACTION DISPATCHER ---------------------------------------------------------------
    // --------------------------------------------------------------------------------------------

    // const shareWidget = useMemo(() => <ShareCardWidget cardId={props.cardAttributes.id} />, [props.cardAttributes.id]);

    // --------------------------------------------------------------------------------------------
    // ---------- RENDER
    // ----------


    // Run SSR once and then again on Client-Side
    let content: React.ReactNode | null;
    if (layoutRev != layoutRevRef.current) {
        logger.debug(`DynamicCard ### PROCESS NEW LAYOUT/VARS RE-RENDER CONTENT ### Rev ${layoutRev}`);
        if (layoutRev === 0) {
            const seededLayout = seedLayoutKeys(props.cardAttributes.layout);
            processNewLayout(seededLayout, props.cardAttributes.vars);
        }
        // const seededLayout = seedLayoutKeys(props.cardAttributes.layout);
        // const changes = processNewLayout(seededLayout, props.cardAttributes.vars);
        content = renderContent() || null;
        contentRef.current = content;
        layoutRevRef.current = layoutRev;
    } else {
        content = contentRef.current;
    }

    logRender && logger.debug(`DynamicCard ### RENDER ###`);
    return (<div>
        {/* <ErrorBoundary FallbackComponent={errorFallback}> */}
        <Global styles={globalStylesCacheRef.current?.serializedObjects} />
        {/* <CardContextBox className={"cardRoot"}> */}
        {/* <CardRoot id={"main_scroller"} className={"cardRoot"} cardRootStyle={hydratedLayoutRef.current?._cardRootStyle}> */}
        {/* <CardRoot id={"main_scroller"} className={"cardRoot"} cardRootStyle={hydratedLayoutRef.current?._cardRootStyle}> */}
        <CardRoot id={hydratedLayoutRef.current?._cardRootId} className={hydratedLayoutRef.current?._cardRootClassName || ""} cardRootStyle={hydratedLayoutRef.current?._cardRootStyle || ""}>

            {/* <div style={{ color: "red" }}>{`DynamicCard`}</div> */}
            {content || <div style={{ color: "orange" }}>{`NO DynamicCard content`}</div>}

            {/* DO NOT DELETE FOR REFERENCE IN HEIGHT TRIGGERS */}
            {/* <RenderLayer ref={node => { resizeRef(node); setCardContentNode(node); }}>{childWidgetElementsRef.current}</RenderLayer> */}
            {/* <RenderLayer ref={setCardContentNode}>{childWidgetElementsRef.current}</RenderLayer> */}
            {/* <RenderLayer>{childWidgetElementsRef.current}</RenderLayer> */}
        </CardRoot>
        {/* </ErrorBoundary> */}
    </div>);

    // ----------
    // ---------- RENDER
    // --------------------------------------------------------------------------------------------

}

export default DynamicCard;
