
import { atom, selector } from "recoil";
import { ICardLayout, IPageVars } from "../widgets/CardInterface";

export enum CardMode {
    SiteView = "SiteView",
    View = "View",
    OwnerView = "OwnerView",
    Share = "Share",
    SharePreview = "SharePreview",
    Configure = "Configure",
    Design = "Design",
    Debug = "Debug",
    Hidden = "Hidden",
}

export const cardModeAtom = atom<CardMode>({ key: 'cardModeAtom', default: CardMode.View });
export const cardIsVisible = selector({
    key: 'cardIsVisible',
    get: ({ get }) => get(cardModeAtom) !== CardMode.Hidden ? true : false,
});

export const pageLayoutAtom = atom<ICardLayout>({ key: 'layoutAtom', default: undefined });
export const pageVarsAtom = atom<IPageVars>({ key: 'varsAtom', default: undefined });