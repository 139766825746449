//=====[ IMPORTS ]=================================================================================

import _logger, { LOGGER_CONFIG_DEFAULT } from '../../utils/logger';
import React, { lazy, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { styled as muiStyled } from '@mui/material/styles';
// import * as yup from "yup";
import Slider, { SliderThumb } from '@mui/material/Slider';
import { FormGroup, FormControlLabel, Checkbox, TextField, Divider, Select, MenuItem } from '@mui/material';
import { FieldFactoryField, FieldFactoryFieldType } from './FieldFactoryInterface';
import { WidgetActionDispatcher } from '../../widgets/WidgetInterface';
import { asArray, isValidEmail } from '../../utils/commonUtils';
import type { CardMode } from '../../state/cardState';
import type { MuiTelInputCountry } from 'mui-tel-input';
import validate, { isValidationResultValid, ValidationTest, ValidationResult } from '../../utils/validator';
import { values } from 'lodash';

// import Box from '@mui/material/Box'
// import Typography from '@mui/material/Typography'


//=====[ LOGGER ]==================================================================================

const logRender = true;
const logHookExecution = true;
const wtfChanged = true;

// const __filename = "FieldFactory.tsx";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

// import Widget from '../../widgets/Widget';
const Widget = lazy(() => import("../../widgets/Widget"));

let isValidPhoneNumber: (text: string, defaultCountry?: MuiTelInputCountry | { defaultCountry?: MuiTelInputCountry, defaultCallingCode?: string }) => boolean = () => false;
const MuiTelInput = React.lazy(
    () => import('mui-tel-input').then(module => {
        isValidPhoneNumber = module.isValidPhoneNumber;
        return { default: module.MuiTelInput };
    })
);


export const getTextInputStyle = () => {
    const style = {
        fontSize: "18px",
    }
    return style;
}

const Field = styled.div`
    margin: 5px 0px 5px 0px;
    /* box-sizing: border-box;
    border: 2px pink dashed; */
    /* margin: 5px 10px 30px 10px; */
`

const FieldLabel = styled('h3')`
    /* box-sizing: border-box;
    border: 2px orange dashed; */
`;

const StyledTextField = styled(TextField)`
    color: red;
`

const StyledSelect = styled(Select)`
    width: 200px;
`

// const useStyles = makeStyles((theme: Theme) =>
// ({
//     root: {
//         '& .MuiTextField-root': {
//             margin: theme.spacing(1),
//             width: 200,
//         },
//     },
// }),
// );

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const marks = [
    {
        value: 0,
    },
    // {
    //     value: 20,
    // },
    // {
    //     value: 37,
    // },
    // {
    //     value: 100,
    // },
];

const IOSSlider = muiStyled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        // color: theme.palette.text.primary,
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            // color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));

export interface IFieldFactoryProps {
    field: FieldFactoryField;
    manualUpdates?: boolean;
    liveValidation?: boolean;
    updateField?: (field: FieldFactoryField) => boolean; // Return true to stop propogation
    actionDispatcher?: WidgetActionDispatcher;
}

export const valiateField = (field: FieldFactoryField): ValidationResult => {
    let vResult: ValidationResult = { isValid: false };
    switch (field.fieldType) {
        case FieldFactoryFieldType.Email:
            vResult = field?.validationTest
                ? validate(field.validationTest, field.value)
                : validate([{ validator: "isEmail" }], field.value);
            break;
        default:
            if (field.validationTest) {
                vResult = validate(field.validationTest, field.value);
            } else if (field.validateFn) {
                vResult = field.validateFn?.(field.value);
            } else {
                vResult.isValid = true;
            }
    }
    return vResult;
}

const FieldFactory: React.FC<IFieldFactoryProps> = (props) => {
    const inputFieldRef = useRef<any>(); // input field ref
    const [touched, setTouched] = useState(false);
    const [changed, setChanged] = useState(false);
    const [focused, setFocused] = useState(false);
    const contentRef = useRef<React.ReactElement | null>(null);

    // const [isValid, setIsValid] = React.useState(false)

    /**
     * 
     * Fix Jumping Cursor?
     * https://stackoverflow.com/questions/58425007/material-ui-text-field-current-cursor-position
     * 
     */
    // const isInvalid = (validationResult: ValidationResult, touched: boolean) => {
    //     return isValidationResultValid(validationResult) && touched;
    //     if (validationResult === undefined) return false;
    //     return !validationResult?.isValid && touched;
    // }

    const renderContent = () => {
        if (!props.field) return null;

        const field = props.field;
        const key = field.fieldKey;
        let control: React.ReactNode = null;

        // logger.debug(`** RENDER field [${field.fieldKey}] with val=${field.value}`);
        // const label = isValid ? (field?.label || "") : `Invalid ${(field?.label || "")}`;
        // const liveInvalid = isInvalid(field.validationResult, touched && changed);

        const isValid = isValidationResultValid(field.validationResult);
        const error = !isValid && (touched || (props.liveValidation === true) || (field.liveValidation === true));
        const helperText = (!isValid && (touched || (props.liveValidation === true) || (field.liveValidation === true))) ? field.helperText : undefined;
        switch (field.fieldType) {
            case FieldFactoryFieldType.String: {
                const value = field.trim ? (field.value || "").trim() : (field.value || "");
                // if (value !== field.value) {
                //     props.updateField?.({ ...field, value } as any);
                // }

                control = <StyledTextField
                    // key={key}
                    style={{ width: "100%" }}
                    label={field.label}
                    error={error}
                    helperText={helperText}
                    // defaultValue={field.value || ""}
                    value={value}
                    variant="outlined"
                    // size="small"
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...field, focused: true });
                    }}
                    onBlur={() => {
                        // changed && setTouched(true);
                        setTouched(true);
                        setFocused(false);
                        const validationResult = valiateField({ ...field, value });
                        props.updateField?.({ ...props.field, touched: true, focused: false, validationResult } as any);
                    }}
                    onChange={async (event) => {
                        const target = event.target as HTMLInputElement;
                        const value = field.trim ? (target.value || "").trim() : (target.value || "");
                        const validationResult = valiateField({ ...field, value });
                        setChanged(true);
                        props.updateField?.({ ...props.field, changed: true, value, validationResult } as any);
                    }}
                    // onSelect={(event: React.SyntheticEvent) => {
                    //     const target = event.target as HTMLInputElement;
                    //     setSel((prev: any) => ({
                    //         start: prev.ignoreNext ? prev.start : target.selectionStart,
                    //         end: prev.ignoreNext ? prev.end : target.selectionEnd,
                    //         ignoreNext: false
                    //     }));
                    // }}
                    inputRef={inputFieldRef}
                />
                break;
            }
            case FieldFactoryFieldType.Text: {
                const value = field.trim ? (field.value || "").trim() : (field.value || "");
                // if (value !== field.value) {
                //     props.updateField?.({ ...field, value } as any);
                // }

                control = <StyledTextField
                    // key={key}
                    style={{ width: "100%" }}
                    label={field.label}
                    error={error}
                    helperText={helperText}
                    value={value}
                    variant="outlined"
                    maxRows={4}
                    multiline
                    // size="small"
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...field, focused: true });
                    }}
                    onBlur={() => {
                        // changed && setTouched(true);
                        setTouched(true);
                        setFocused(false);
                        const validationResult = valiateField({ ...field, value });
                        props.updateField?.({ ...props.field, touched: true, focused: false, validationResult } as any);
                    }}
                    onChange={async (event) => {
                        const target = event.target as HTMLInputElement;
                        const value = field.trim ? (target.value || "").trim() : (target.value || "");
                        const validationResult = valiateField({ ...field, value });
                        setChanged(true);
                        props.updateField?.({ ...props.field, changed: true, value, validationResult } as any);
                    }}
                    inputRef={inputFieldRef}

                />
                break;
            }
            case FieldFactoryFieldType.Email: {
                const value = (field.value || "").trim();
                // if (value !== field.value) {
                //     props.updateField?.({ ...field, value } as any);
                // }

                control = <StyledTextField
                    // key={key}
                    style={{ width: "100%" }}
                    label={field.label}
                    error={error}
                    helperText={helperText}
                    value={value}
                    variant="outlined"
                    // size="small"
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...field, focused: true });
                    }}
                    onBlur={() => {
                        // changed && setTouched(true);
                        setTouched(true);
                        setFocused(false);
                        const validationResult = valiateField({ ...field, value });
                        props.updateField?.({ ...props.field, touched: true, focused: false, validationResult } as any);
                    }}
                    onChange={async (event) => {
                        const target = event.target as HTMLInputElement;
                        let value = target.value.trim();
                        const validationResult = props.field?.validationTest
                            ? valiateField({ ...field, value })
                            : validate([{ validator: "isEmail" }], value);
                        setChanged(true);
                        props.updateField?.({ ...props.field, value, validationResult, changed } as any);
                    }}
                    inputRef={inputFieldRef}
                />
                break;
            }
            case FieldFactoryFieldType.Number: {
                const value = field.validationResult;
                control = <StyledTextField
                    // key={key}
                    type="number"
                    style={{ width: "100%" }}
                    label={field.label}
                    error={error}
                    helperText={helperText}
                    value={value}
                    // defaultValue={field.value || field.default}
                    // value={field.value || field.min || 0}
                    variant="outlined"
                    // size="small"
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...props?.field, focused: true } as any);
                    }}
                    onBlur={() => {
                        changed && setTouched(true);
                        setFocused(false);
                        props.updateField?.({ ...props?.field, focused: false } as any);
                    }}
                    onChange={async (event) => {
                        const target = event.target as HTMLInputElement;
                        let value = target.value;
                        const validationResult = valiateField({ ...field, value });
                        props.updateField?.({ ...props.field, value, validationResult, focused, touched, changed } as any);
                        setChanged(true);
                    }}
                />
                break;
            }
            case FieldFactoryFieldType.Slider: {
                const value = field.value;
                control = <IOSSlider
                    // key={key}
                    aria-label="ios slider"
                    value={value}
                    // defaultValue={field.value || field.default}
                    // value={field.value || field.min || 0}
                    // marks={marks}
                    valueLabelDisplay="on"
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...props?.field, focused: true } as any);
                    }}
                    onBlur={() => {
                        changed && setTouched(true);
                        setFocused(false);
                        props.updateField?.({ ...props?.field, focused: false } as any);
                    }}
                    onChange={async (event: Event, newValue: number | number[]) => {
                        const target = event.target as HTMLInputElement;
                        let value = target.value;
                        const validationResult = valiateField({ ...field, value });
                        props.updateField?.({ ...props.field, value, validationResult, focused, touched, changed } as any);
                        setChanged(true);
                    }}
                    // onMouseUp={doSnapshot}
                    // onTouchEnd={doSnapshot}
                    min={field.min}
                    max={field.max}
                    scale={field.scale}
                    step={field.step || 1}
                />
                break;
            }
            case FieldFactoryFieldType.Checkbox: {
                const value = field.value;
                control = <FormControlLabel
                    // key={key}
                    label={field.label}
                    disabled={field.disabled}
                    checked={true}
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...props?.field, focused: true } as any);
                    }}
                    onBlur={() => {
                        changed && setTouched(true);
                        setFocused(false);
                        props.updateField?.({ ...props?.field, focused: false } as any);
                    }}
                    control={
                        <Checkbox
                            // checked={field.value}
                            defaultValue={value}
                            // defaultValue={field.value || field.default}
                            // value={field.value || ""}
                            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                const target = event.target as HTMLInputElement;
                                let value = target.value;
                                const validationResult = valiateField({ ...field, value });
                                props.updateField?.({ ...props.field, value, validationResult, focused, touched, changed } as any);
                                setChanged(true);
                            }}
                        />
                    }
                />

                // <Checkbox
                //     key={key}
                //     aria-label="ios slider"
                //     value={field.value || ""}
                //     // onChange={(event: Event, newValue: number | number[]) => {
                //     //     field.value = newValue;
                //     //     props.updateField?.(field);
                //     // }}
                // />
                break;
            }
            case FieldFactoryFieldType.Choice: {
                const value = field.value;
                control =
                    <StyledSelect
                        // key={key}
                        label={field.label}
                        error={error}
                        displayEmpty
                        defaultValue={value}
                        // defaultValue={field.value || field.default}
                        // value={field.value || []}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onFocus={() => {
                            setFocused(true);
                            props.updateField?.({ ...props?.field, focused: true } as any);
                        }}
                        onBlur={() => {
                            changed && setTouched(true);
                            setFocused(false);
                            props.updateField?.({ ...props?.field, focused: false } as any);
                        }}
                        onChange={async (event) => {
                            const target = event.target as HTMLInputElement;
                            let value = target.value;
                            const validationResult = valiateField({ ...field, value });
                            props.updateField?.({ ...props.field, value, validationResult, focused, touched, changed } as any);
                            setChanged(true);

                        }}>
                        {
                            field.choices.map((choice) => {
                                return (
                                    <MenuItem key={`choice_${choice.label}`} value={choice.value}>{choice.label}</MenuItem>
                                );
                            })
                        }
                    </StyledSelect>
                break;
            }
            case FieldFactoryFieldType.Phone: {
                const value = (field.value || "").trim();
                // if (value !== field.value) {
                //     props.updateField?.({ ...field, value } as any);
                // }

                // continents={["NA"]}
                // focusOnSelectCountry={true}
                // disableDropdown={true}
                // valid = touched ? isValid : true;
                const countries: MuiTelInputCountry[] = ['US', 'MX', 'CA'];
                control = <MuiTelInput
                    // key={key}
                    label={field.label}
                    error={error}
                    helperText={helperText}
                    onlyCountries={countries}
                    preferredCountries={countries}
                    forceCallingCode={true}
                    defaultCountry={"US"}
                    value={value}
                    onFocus={() => {
                        setFocused(true);
                        props.updateField?.({ ...props?.field, focused: true } as any);
                    }}
                    onBlur={() => {
                        changed && setTouched(true);
                        setFocused(false);
                        props.updateField?.({ ...props?.field, focused: false } as any);
                    }}
                    onChange={async (newValue: string) => {
                        let value = newValue;
                        const validationResult = props.field?.validationTest
                            ? valiateField({ ...field, value })
                            : { isValid: isValidPhoneNumber(value) };

                        props.updateField?.({ ...props.field, value, validationResult, focused, touched, changed } as any);
                        setChanged(true);
                    }}
                />
                break;
            }
            case FieldFactoryFieldType.Divider: {
                control = field.label && <Divider>{field.label}</Divider>
                break;
            }
            case FieldFactoryFieldType.Spacer: {
                control = <div style={{ height: field.space || 0 }} />
                break;
            }
            case FieldFactoryFieldType.Widget: {
                control = field.layout ? <Widget cardMode={field.cardMode} layout={field.layout} actionDispatcher={props.actionDispatcher} /> : null;
                break;
            }
            default: {
                control = <>[ {field.fieldType} not supported ]</>;
            }
        }

        return (
            <div key={field.fieldKey} style={{ paddingBottom: "5px" }}>
                <Field key={`fieldFactory_${field.fieldKey}`}>
                    {field.heading &&
                        <FieldLabel>
                            {field.heading}
                        </FieldLabel>
                    }
                    {control}
                </Field>
            </div>
        )
    }

    let content: React.ReactElement | null;
    // if (!contentRef.current) {
    //     content = renderContent();
    //     contentRef.current = content;
    // } else {
    //     content = contentRef.current;
    //     if (inputFieldRef.current && !focused) {
    //         const val = props.field?.value || "";
    //         inputFieldRef.current.value = val;
    //     }
    // }

    // content = renderContent();
    content = renderContent();
    // logRender && logger.debug(`[${__filename}] ### RENDER ###`);
    return content;
}

export default FieldFactory;