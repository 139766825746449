import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import _logger, { LOGGER_CONFIG_DEFAULT } from "../utils/logger";
import React, { useState, useEffect, useRef } from "react";
import cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_PAGE_SCROLLER_ID_STR } from "./WidgetInterface";
import { _debug, _debugAction } from "../utils/_debug";

//=====[ LOGGER ]==================================================================================

const logRender = false;
const logHookExecution = false;

const __filename = "WidgetAnimation.tsx";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

export type WAnimGsapFuncName =
    "config" |
    "set" |
    "from" |
    "to" |
    "fromTo" |
    "forEach" |
    "timeline" |
    "matchMedia";

// export enum WAnimGsapFuncName  {
//     config = "config",
//     set = "set",
//     from = "from",
//     to = "to",
//     fromTo = "fromTo",
//     forEach = "forEach",        // Execute a toArray(selector).forEach(target=>{WAnimpipeline})
//     timeline = "timeline",
//     matchMedia = "matchMedia",  // REF: https://greensock.com/forums/topic/25270-can-we-use-matchmedia-for-timelines-outside-the-scrolltrigger/
// }

export interface IWAnimGsapFunc {
    funcName: WAnimGsapFuncName;
    useVar?: string;
    returnVar?: string;
    chainTo?: WAnimGsapFunc;
    //
    _debug?: _debugAction;
}

export interface IWAnimGsapConfig extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.config;
    funcName: "config";
    vars: gsap.GSAPConfig;
}

export interface IWAnimGsapSet extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.set;
    funcName: "set";
    targets: gsap.TweenTarget;
    vars: gsap.TweenVars;
}

export interface IWAnimGsapFrom extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.from;
    funcName: "from";
    targets: gsap.TweenTarget;
    vars: gsap.TweenVars;
}

export interface IWAnimGsapTo extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.to;
    funcName: "to";
    targets: gsap.TweenTarget;
    vars: gsap.TweenVars;
    position?: string | number;
}

export interface IWAnimGsapFromTo extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.fromTo;
    funcName: "fromTo";
    targets: gsap.TweenTarget;
    fromVars: gsap.TweenVars;
    toVars: gsap.TweenVars;
}

export interface IWAnimGsapTimeline extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.timeline;
    funcName: "timeline";
    vars: gsap.TimelineVars;
}

export interface IWAnimGsapMatchMedia extends IWAnimGsapFunc {
    // funcName: WAnimGsapFuncName.matchMedia;
    funcName: "matchMedia";
    vars: ScrollTrigger.MatchMediaObject;
}

export type WAnimGsapFunc =
    IWAnimGsapSet |
    IWAnimGsapFrom |
    IWAnimGsapTo |
    IWAnimGsapFromTo |
    IWAnimGsapConfig |
    IWAnimGsapTimeline |
    IWAnimGsapMatchMedia;

export type WAnimpipeline = Array<WAnimGsapFunc>;

export interface IWidgetAnimation {
    pipeline?: WAnimpipeline;
    // smartPipeline: {};  // Prepackaged GSAP
    // cssPipeline: {};
}

type GsapChainFromType =
    gsap.core.Tween |
    gsap.core.Timeline |
    gsap.GSAPConfig;

// let refresherIntervalTimerSingleton: any = undefined;
// let refresherCounterSingleton = 25;

export interface IUseAnimationProps {
    _id?: string;
}

// export const useAnimationPipeline = (props: IUseAnimationProps) => {
export const useAnimationPipeline = (props?: IUseAnimationProps) => {
    const defaultTargetRef = useRef<string>();
    const varMapRef = useRef<{ [key: string]: any }>({});
    const [pipeline, setPipeline] = useState<WAnimpipeline | undefined>();
    const gsapTweensRef = useRef<gsap.core.Tween[]>([]);
    // const scrollerNode = useRecoilValue(scrollerNodeAtom);
    // const triggerNode = useRecoilValue(contentNodeAtom);
    // const refreshIntervalRef = useRef<any>();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    }, []);

    const setAnimationPipeline = (newAnimationPipeline?: WAnimpipeline, defaultTarget?: string) => {
        defaultTarget && (defaultTargetRef.current = defaultTarget);
        setPipeline(newAnimationPipeline);
    }

    const processFunction = (func: WAnimGsapFunc, chainFrom?: GsapChainFromType | any) => {
        let gsapResult: any;

        (process.env.NODE_ENV === 'development' && _debug) && _debug(func._debug, logger, func);

        // Determine which object to use when calling this function, the
        // chainFrom, chainFromVar, or gsap?
        const gsapObj = (chainFrom || (func.useVar && varMapRef.current[func.useVar]) || gsap);
        // let targets: gsap.TweenTarget = (('targets' in func && func.targets) || animationProps?.node) as gsap.TweenTarget;

        let targets: gsap.TweenTarget = (('targets' in func && func.targets) || (defaultTargetRef.current && `#${defaultTargetRef.current}` || undefined)) as gsap.TweenTarget;

        try {
            switch (func.funcName) {
                // case WAnimGsapFuncName.set: {
                case "set": {
                    // Call gsap function or chain to previous function result
                    gsapResult = (chainFrom || gsap).set(targets, func.vars);
                    if (gsapResult && func.chainTo) {
                        gsapResult = processFunction(func.chainTo, gsapResult);
                    }
                    break;
                }

                // case WAnimGsapFuncName.timeline: {
                case "timeline": {
                    let vars: any;
                    if (func.vars?.scrollTrigger) {
                        vars = cloneDeep(func.vars);
                        if (vars.scrollTrigger.start) vars.scrollTrigger.start = () => (func.vars.scrollTrigger as gsap.AnimationVars).start;
                        if (vars.scrollTrigger.end) vars.scrollTrigger.end = () => (func.vars.scrollTrigger as gsap.AnimationVars).end;
                        vars.scrollTrigger.scroller = vars.scrollTrigger.scroller || DEFAULT_PAGE_SCROLLER_ID_STR; // scrollerNode;
                        !vars.scrollTrigger.trigger && defaultTargetRef.current && (vars.scrollTrigger.trigger = `#${defaultTargetRef.current}`) as gsap.DOMTarget;
                    } else {
                        vars = func.vars;
                    }

                    // Call gsap function or chain to previous function result
                    gsapResult = gsapObj.timeline(vars);
                    if (gsapResult && func.chainTo) {
                        gsapResult = processFunction(func.chainTo, gsapResult);
                    }
                    break;
                }

                // case WAnimGsapFuncName.matchMedia: {
                case "matchMedia": {
                    logger.error(`ScrollTrigger.matchMedia(...) not yet supported.`);
                    break;
                }

                // case WAnimGsapFuncName.from: {
                case "from": {
                    // gsapResult = (chainFrom || gsap).from(targets, func.vars);
                    gsapResult = gsapObj.from(targets, func.vars);
                    if (gsapResult && func.chainTo) {
                        gsapResult = processFunction(func.chainTo, gsapResult);
                    }
                    break;
                }

                // case WAnimGsapFuncName.to: {
                case "to": {
                    let vars: any;
                    if (func.vars?.scrollTrigger) {
                        vars = cloneDeep(func.vars);
                        if (vars.scrollTrigger.start) vars.scrollTrigger.start = () => (func.vars.scrollTrigger as gsap.AnimationVars).start;
                        if (vars.scrollTrigger.end) vars.scrollTrigger.end = () => (func.vars.scrollTrigger as gsap.AnimationVars).end;
                        vars.scrollTrigger.scroller = vars.scrollTrigger.scroller || DEFAULT_PAGE_SCROLLER_ID_STR; // scrollerNode;
                        !vars.scrollTrigger.trigger && defaultTargetRef.current && (vars.scrollTrigger.trigger = `#${defaultTargetRef.current}`) as gsap.DOMTarget;
                    } else {
                        vars = func.vars;
                    }

                    // If we need to call nested funcitons first, do that
                    // TODO: Call nested functions, first

                    // Call gsap function or chain to previous function result
                    // NOTE: THERE COULD BE A CONFLICT IF THERE IS A chainFrom AND func.chainFromVar
                    if (chainFrom && (func.useVar && varMapRef.current[func.useVar])) {
                        logger.error(`Conflicting chainFrom and chainFromVar. Using chainFrom schema shape and ignoreing explicit chainFromVar reference.`);
                    }

                    gsapResult = gsapObj.to(targets, vars, func.position);
                    if (gsapResult && func.chainTo) {
                        gsapResult = processFunction(func.chainTo, gsapResult);
                    }
                    break;
                }

                // case WAnimGsapFuncName.fromTo: {
                case "fromTo": {
                    let toVars: any;
                    if (func.toVars?.scrollTrigger) {
                        toVars = cloneDeep(func.toVars);
                        toVars.scrollTrigger.scroller = toVars.scrollTrigger.scroller || DEFAULT_PAGE_SCROLLER_ID_STR; // scrollerNode;
                        !toVars.scrollTrigger.trigger && defaultTargetRef.current && (toVars.scrollTrigger.trigger = `#${defaultTargetRef.current}`) as gsap.DOMTarget;
                    } else {
                        toVars = func.toVars;
                    }

                    gsapResult = gsapObj.fromTo(targets, func.fromVars, toVars);
                    if (gsapResult && func.chainTo) {
                        gsapResult = processFunction(func.chainTo, gsapResult);
                    }
                    break;
                }

                default: {
                    throw new Error(`Unsupported GSAP Pipeline Function: ${func.funcName}`);
                    break;
                }
            }
        } catch (e) {
            logger.error(e);
        }

        // Save result as a named variable
        if (func.returnVar) {
            varMapRef.current[func.returnVar] = gsapResult;
        }


        // If this is a new tween, add it to a list of tweens to cleanup/kill before rebuilding
        gsapResult && gsapTweensRef.current.push(gsapResult);

        return gsapResult;
    }

    useEffect(() => {
        //
        // TODO: Figure out how to update when the DOM is done! Need all nodes with their classes and ID's rendered
        //
        logHookExecution && logger.debug(`[${defaultTargetRef.current}] useEffect 1`);

        // Kill old tweens
        while (gsapTweensRef.current.length > 0) {
            const tween = gsapTweensRef.current.pop();
            tween?.kill();
        }
        varMapRef.current = {};

        // Process the Animation Pipeline
        if (pipeline) {
            // Build new tween(s) with recursive chaining
            pipeline.forEach((pipelineFunc: WAnimGsapFunc) => {
                // logger.debug(`process animation pipeline function: `, pipelineFunc);
                const result = processFunction(pipelineFunc);
            });

            //
            // TODO: FIX THIS STUPID HACK!!! (moved over to Widget_ViewMode for now)
            // REF: https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.refresh()
            //
            // refresherIntervalTimerSingleton && clearInterval(refresherIntervalTimerSingleton);
            // const refresher = function () {
            //     ScrollTrigger.refresh();
            //     refresherCounterSingleton--;
            //     if (refresherCounterSingleton <= 0) {
            //         clearInterval(refresherIntervalTimerSingleton);
            //     }
            // }
            // refresherIntervalTimerSingleton = setInterval(refresher, 500);
        }

        // Cleanup
        return () => { }
        // }, [animationProps, scrollerNode, triggerNode]);
        // }, [animationProps, scrollerNode]);

    }, [pipeline]);

    // ScrollTrigger.refresh();
    logRender && logger.debug(`[${defaultTargetRef.current}] ### RENDER useAnimation ###`);
    return {
        setAnimationPipeline,
    }
}
