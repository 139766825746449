// ---------------------------------------------------------------------------------------------------------------------/
// ----- DO NOT IMPORT ANY FILES THAT MAY RECURSIVELY IMPORT THIS LOGGER -----------------------------------------------/
//
// import { AppVars } from "../env";
import { inspect } from "util";
import { getUnixTime, getMilliseconds } from "date-fns";


/*
  USAGE EXAMPLE

import _logger, { LOGGER_CONFIG_DEFAULT } from '../utils/logger';
const __filename = "thefilename.tsx";   // OVERRIDE FOR CLIENT BROWSER USE - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

*/

const STRINGIFY_ARGS = false; // true (converts all arguments to a stringified output)

//
// LOGGING LEVELS
//
export enum LogLevel {
  Off = 0,
  Fatal = 1,
  Error = 2,
  Warn = 3,
  // ----
  Info = 4,
  Verbose = 5,
  Debug = 6,
  All = 7,
}

export const DEFAULT_LOG_LEVEL = LogLevel.Warn;

const productName = process.env.NEXT_PUBLIC_PRODUCT_NAME;

let logLevel: LogLevel = parseInt(process.env.NEXT_PUBLIC_LOG_LEVEL || DEFAULT_LOG_LEVEL.toString());
export const getLogLevel = (): number => logLevel;
export const setLogLevel = (level: LogLevel): void => {
  logLevel = level;
  console.log(`LOG_LEVEL = ${logLevel2Name(level)}`);
}

export type LogFilter = RegExp | { test: (s: string) => boolean } | undefined;
const DEFAULT_LOG_FILTER = undefined;
let logFilter: LogFilter = (process.env.NEXT_PUBLIC_LOG_FILTER && new RegExp(process.env.NEXT_PUBLIC_LOG_FILTER)) || DEFAULT_LOG_FILTER;
// DEBUG: ["*"],             // Match Everything 
// DEBUG: ["sequelize:*"],   // Match All Sequelize
// LOG_FILTER: "/.+(userEntity).*/i", // Match userEntity
// LOG_FILTER: "/.+(userEntity|orgEntity).*/i", // Match userEntity or orEntity
// LOG_FILTER: "/.+().*/i",  // Match Everything
//
// RegEx: 
//  Match Everything: undefined or /.+().*/i
//  Match either *abc* or *def* : .+(abc).*|.+(def).*/i
//
// GlobalProcessVars.loggingMatchExp = undefined;
export const getLogFilter = (): LogFilter => logFilter;
export const setLogFilter = (exp?: RegExp | undefined): void => {
  logFilter = exp || { test: (s: string): boolean => true }
}

export const logLevel2Name = (level: number) => LogLevel[level];
export const logLevel2Value = (name: string) => (LogLevel as any)[name];

export enum LoggingColor {
  Undefined = "",
  Reset = "\x1b[0m",
  Bright = "\x1b[1m",
  Dim = "\x1b[2m",
  Underscore = "\x1b[4m",
  Blink = "\x1b[5m",
  Reverse = "\x1b[7m",
  Hidden = "\x1b[8m",

  FgBlack = "\x1b[30m",
  FgRed = "\x1b[31m",
  FgGreen = "\x1b[32m",
  FgYellow = "\x1b[33m",
  FgBlue = "\x1b[34m",
  FgMagenta = "\x1b[35m",
  FgCyan = "\x1b[36m",
  FgWhite = "\x1b[37m",

  BgBlack = "\x1b[40m",
  BgRed = "\x1b[41m",
  BgGreen = "\x1b[42m",
  BgYellow = "\x1b[43m",
  BgBlue = "\x1b[44m",
  BgMagenta = "\x1b[45m",
  BgCyan = "\x1b[46m",
  BgWhite = "\x1b[47m",
}

export const FatalColor = LoggingColor.BgRed + LoggingColor.FgBlack;
export const ErrorColor = LoggingColor.BgRed + LoggingColor.FgWhite;
export const WarnColor = LoggingColor.FgYellow;
export const InfoColor = LoggingColor.Reset;
export const VerboseColor = LoggingColor.Reset;
export const DebugColor = LoggingColor.Reset;
export const LogColor = LoggingColor.Reset;

export interface ILogger {
  name: string;
  color: string,
  silenceThreadInstance: boolean;
  silenceContext: boolean;
  silenceTimestamp: boolean;
  contextString: string;
  parent: ILogger;
  //
  log: ((...args: any[]) => void);
  fatal: ((...args: any[]) => void);
  error: ((...args: any[]) => void);
  warn: ((...args: any[]) => void);
  info: ((...args: any[]) => void);
  verbose: ((...args: any[]) => void);
  debug: ((...args: any[]) => void);
  //
  getNamespace: () => string,
  getFilename: (filename: string) => string,
  getInstanceRef: (tag?: string) => string;
  newLogger: ((context?: ILogger | {}) => ILogger);
  // getLoggingLevel?: () => LoggingLevel;
  // setLoggingLevel?: (level: LoggingLevel) => void;
}

export const LOGGER_CONFIG_DETAILED = {};
export const LOGGER_CONFIG_MODERATE = { silenceThreadInstance: true, silenceTimestamp: true };
export const LOGGER_CONFIG_QUIET = { silenceThreadInstance: true, silenceTimestamp: false };
export const LOGGER_CONFIG_DEFAULT = LOGGER_CONFIG_QUIET;

// const newLogger = (name: string = "", !silenceContext: boolean = true, !silenceTimestamp: boolean = true, color: string = LogColor.Reset, incThreadRef: boolean = true, parentContext: LoggerContext | null = null): LoggerContext => {
const newLogger = (config: ILogger | {} = {}): ILogger => {

  // if (getLoggingLevel() >= LoggingLevel.Debug) {
  //   console.debug(`newLogger context: ${JSON.stringify(config)}`);
  // }

  const _config = config as ILogger;
  const name = _config.name || "";
  const color = _config.color; // || LoggingColor.Reset;
  const silenceThreadInstance: boolean = _config.silenceThreadInstance ? true : false;
  const silenceContext: boolean = _config.silenceContext ? true : false;
  const silenceTimestamp: boolean = _config.silenceTimestamp ? true : false;
  const parent = _config.parent;
  // const parentContextString = (_config.parent && _config.parent.contextString) || (AppLogger && AppLogger.contextString) || "";
  const parentContextString = (_config.parent && _config.parent.contextString) || "";
  const contextString = `${parentContextString && (parentContextString + ".")}${name}${silenceThreadInstance ? "" : (" (" + _newTimekey() + ")")}`;

  return {
    name,
    color,
    silenceThreadInstance,
    silenceContext,
    silenceTimestamp,
    contextString: contextString,
    parent: _config.parent,

    getInstanceRef: (tag: string = "") => `${tag && (tag + "{")}${_newTimekey()}${tag && "}"}`,

    getNamespace: () => {
      const parentNamespace = (parent && parent.getNamespace()) || "";
      return `${parentNamespace}${parentNamespace && name && ":"}${name}`;
    },

    // getFilename: (filename: string, dirname?: string) => filename.slice((dirname || "").length).split('.').slice(0, 0).join('.'),
    getFilename: (filename: string) => filename.split("/").pop() || "",

    log: (...args: any[]) => {
      if (getLogLevel() > LogLevel.Off) {

        const preamble: string = `${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          console.log(color || LogColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
        }
      }
    },

    fatal: (...args: any[]) => {
      if (getLogLevel() >= LogLevel.Fatal) {

        const preamble: string = `FATAL ${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          _config.fatal ?
            _config.fatal(...args) : // Redirect to override function
            console.error((color && color) || FatalColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
        }
      }
    },

    error: (...args: any[]) => {
      if (getLogLevel() >= LogLevel.Error) {

        const preamble: string = `ERROR ${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          _config.error ?
            _config.error(...args) : // Redirect to override function
            console.error((color && color) || ErrorColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
        }
      }
    },

    warn: (...args: any[]) => {
      if (getLogLevel() >= LogLevel.Warn) {

        const preamble: string = `WARN ${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          _config.warn ?
            _config.warn(...args) : // Redirect to override function
            console.warn((color && color) || WarnColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
        }
      }
    },

    info: (...args: any[]) => {
      if (getLogLevel() >= LogLevel.Info) {

        const preamble: string = `INFO ${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          _config.info ?
            _config.info(...args) : // Redirect to override function
            console.info((color && color) || InfoColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
        }
      }
    },

    verbose: (...args: any[]) => {
      if (getLogLevel() >= LogLevel.Verbose) {

        const preamble: string = `VERBOSE ${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          _config.verbose ?
            _config.verbose(...args) : // Redirect to override function
            // console.debug((color && color) || VerboseColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
            console.debug(silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]));
        }
      }
    },

    debug: (...args: any[]) => {
      if (getLogLevel() >= LogLevel.Debug) {
        // TODO: Percolate upstream, passing this namespace up to be appended on the full-path namespace

        const preamble: string = `DEBUG ${contextString}: `;
        const logFilter = getLogFilter();
        if (!logFilter || logFilter.test(preamble + args.toString())) {
          _config.debug ?
            _config.debug(...args) : // Redirect to override function
            // console.debug((color && color) || DebugColor, silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]), LoggingColor.Reset);
            console.debug(silenceTimestamp ? "" : new Date().toISOString(), silenceContext ? "" : preamble, ...outputArguments([...args]));
        }
      }
    },

    newLogger,
    // getLoggingLevel,
    // setLoggingLevel,
  }
}

/**
 * Generate an array of strings suitable for logging.
 * NOTE: Arrow Function arguments cannot be accessed via arguments variable
 * 
 * @param args            must pass as [...arguments]
 * @returns [strings]
 */
export const outputArguments = STRINGIFY_ARGS ? (...args: any[]) => {
  let argsArray: any[] = [];
  args.forEach((arg) => {
    // argsArray.push(typeof(arg) === 'object' ? JSON.stringify(arg) : arg);
    // argsArray.push(typeof(arg) === 'object' ? JSON.stringify(arg, null, '\t') : arg);
    argsArray.push(typeof (arg) === 'object' ? inspect(arg, { showHidden: false, depth: null }) : arg);
  });

  return argsArray;
} : (args: any) => args;

// const _epochTimeBase36 = () => getUnixTime(Date.now()).toString(36);

/**
 * THIS IS A DUPLICATE OF THE UTILS VERSION TO PREVENT COMPILE ERRORS
 */
const _newTimekey = () => {
  const pad = "000000";
  // const cLen = 4;
  // const cMax = 1679615;     // Math.pow(36,cLen)-1 = (36^4-1) = 1679615, (36^3-1) = 46655, (36^2-1) = 1295
  const rLen = 4;
  const rMax = 1679615;     // Math.pow(36,cLen)-1 = (36^4-1) = 1679615, (36^3-1) = 46655, (36^2-1) = 1295
  const now = Date.now();

  // Build epoc time string
  const nowEpochTime = getUnixTime(now).toString(36);

  // Build millisecond padded string
  let millisecs = getMilliseconds(now).toString(36);
  millisecs = millisecs.length < 2 ? "0" + millisecs : millisecs;

  // Build counter padded string
  // const counter = shortKeyCounter.toString(36);
  // counter = pad.substring(0, cLen - counter.length) + counter;   //ct = ct.length < 2 ? "0" + ct : ct;
  // shortKeyCounter = shortKeyCounter >= cMax ? 0 : shortKeyCounter + 1;     // 3 characters (36^3-1) = 46655 / (36^2-1) = 1295

  // Generate Random Suffix
  let randNum = Math.floor(Math.random() * rMax).toString(36);
  randNum = pad.substring(0, rLen - randNum.length) + randNum;    //rn = rn.length < 2 ? "0" + rn : rn;

  // return assembled short key
  return nowEpochTime + millisecs + randNum;  // Removed counter: (epochTime + millisecs + counter + randNum)
};

//
// INITIALIZE LOG LEVEL IF NOT ALREADY SET
//
const appLogger = newLogger({ name: productName, silenceThreadInstance: true });

console.log(`INITIALIZE LOGGER WITH LOG_LEVEL = ${logLevel2Name(getLogLevel())}`);

export default appLogger as ILogger;
