// Hydrator - Gen 3

// ----- IMPORTS -------------------------------------------------------------------------------------------------------/

import _logger, { LOGGER_CONFIG_DEFAULT } from "./logger";
import { getNestedKeyValue, undefinedDefault } from "./commonUtils";
// CLIENT IMPORTS
// import cloneDeep from "lodash-es/cloneDeep"; // CLIENT
// SERVER IMPORTS
import cloneDeep from "lodash/cloneDeep"; // SERVER

// --- LOGGER -------------------------------------------------------------------------------------
var __filename = "hydrator.ts";   // OVERRIDE FOR CLIENT BROWSER USE - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");
// ------------------------------------------------------------------------------------------------

// ----- FUNCTIONS -----------------------------------------------------------------------------------------------------/

//
// NEVER EVER USE eval(...) --- IT IS A HUGE SECURITY RISK
//
// This is a collection of functions that substitute for eval(...)
//
// REF: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
// SEE: Accessing member properties
//

export const CONTEXT_KEYWORD = "_contextKey";
export const MALFORMED_ASSET_PATH = "MALFORMED_ASSET_PATH";

const MAX_RECURSION_DEPTH = 10000;

const UNSET_OBJECT = Symbol("unset");
const INVALID_OBJECT = Symbol("INVALID");
const VALUE_NOT_FOUND_SYMBOL = Symbol("![not_found]");
// const VALUE_UNDEFINED_SYMBOL = Symbol("![undefined]");

export const VALUE_NOT_FOUND_PLACEHOLDER = "![not_found]";
export const VALUE_UNDEFINED_PLACEHOLDER = "![undefined]";
export const VALUE_OBJECT_PLACEHOLDER = "![object]";
export const OVERFLOW_ERROR_PLACEHOLDER = "![overflow_error]";

export const HYDRATION_ERROR_PLACEHOLDER = "![hydration_error]";
export const MAX_HYDRATION_DEPTH_ERROR_PLACEHOLDER = "![max_depth_hydration_error]";
export const CIRCULAR_HYDRATION_ERROR_PLACEHOLDER = "![circular_hydration_error]";

const CIRCULAR_HYDRATION_ERROR_MESSAGE = `circular hydration error`;
const MAX_HYDRATION_DEPTH_ERROR_MESSAGE = `maximum hydration depth error`;

enum HYDRATION_PHRASE_TYPE {
  VALUE = "VALUE",
  ASSET = "ASSET",
}

interface IHydrateObj { [key: string]: any }

const VarHydrationPhraseRegEx = /\^\[(.*?)\]/g; // Var hydration phrase
const LateVarHydrationPhraseRegEx = /\^late\[(.*?)\]/g; // Var hydration phrase
const AssetHydrationPhraseRegEx = /\@\[(.*?)\]/g; // Asset hydration phrase

// const QuotedVarHydrationPhraseRegEx = /\"(\^\[.*?\])\"/g; // Quoted replacement
// const AnyHydrationPhraseRegEx = /[\^|\@]\[(.*?)\]/g; // Matches any hydration phrase

export type onHydrateKeyFn = (key: string, parentObj: any) => boolean;

export interface IHydrateOptions {
  pure?: boolean;
  stringifyObjects?: boolean,
  verbose?: boolean;
  // showUndefinedValues?: boolean
  lateBindingHydration?: boolean;
  maxDepth?: number;
  //
  onHydrateKey?: onHydrateKeyFn;
  varsUsed?: {  // Undefined is ignored. Object passed will be populated with variables used to hydrate
    [varPath: string]: any;
  }
  onVarUsed?: (varName: string, prevValue?: any, varsUsed?: any) => any;
}

export const HydrateOptionDefaults: IHydrateOptions = {
  pure: true,
  stringifyObjects: false,
  verbose: false,
  // showUndefinedValues: false,   // delete keys that are undefined and insert empty string
  lateBindingHydration: false,
  maxDepth: MAX_RECURSION_DEPTH,
}


type StackElement = any;
export interface IStack {
  objStack: any[];
  altStack: any[];
  deepestStack: number;
};

const newStack = () => ({
  objStack: [],
  altStack: [],
  deepestStack: 0,
});

/**
 * Circular Reference
 * Prevents overflowing when working recursively on objects that contain ciruclar references
 * 
 * @param obj     object to check and remember
 * @param stack   array of objects seen
 * @returns       true if seen, false if not
 */
export const circularReferencePush = (element: StackElement, stack: IStack = newStack(), maxDepth: number = MAX_RECURSION_DEPTH): IStack => {
  if (stack.objStack.length > maxDepth) throw new Error(MAX_HYDRATION_DEPTH_ERROR_MESSAGE);

  // Push the element on the correct stack (objects or alternate types)
  let _stack = typeof element === "object" ? stack.objStack : stack.altStack;
  if (_stack.includes(element)) throw new Error(CIRCULAR_HYDRATION_ERROR_MESSAGE);
  _stack.push({});
  _stack[_stack.length - 1] = element;

  // Record the deepest stack we've seen
  const totalDepth = stack.objStack.length + stack.altStack.length;
  if (totalDepth > stack.deepestStack) stack.deepestStack = totalDepth;
  return stack;
}

export const circularReferencePop = (stack: IStack, obj: boolean) => {
  obj ? stack.objStack.pop() : stack.altStack.pop();
}

const errorPlaceholder = (err: Error) => {
  if (err?.message.toLowerCase().includes("circular")) return CIRCULAR_HYDRATION_ERROR_PLACEHOLDER
  else if (err?.message.toLowerCase().includes("depth")) return MAX_HYDRATION_DEPTH_ERROR_PLACEHOLDER
  else return HYDRATION_ERROR_PLACEHOLDER;
}

/**
 * 
 * hydrateString is the magic workhorse! <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
 * 
 */
export const hydrateString = (
  str: string,
  vars?: IHydrateObj | undefined | null,
  _contextKey: string | undefined = undefined,
  // localVars: IHydrateObj | undefined = undefined,
  options: IHydrateOptions | undefined = undefined,
  stack: IStack = newStack()
): any => {

  if (!str || typeof str !== "string") return str; // Make sure we have a valid string

  // Prep context
  const _options = { ...HydrateOptionDefaults, ...(options || { pure: true }) };

  const wasEmptyStack: boolean = stack.objStack.length === 0;
  if (wasEmptyStack) stack.deepestStack = 0;

  /**
   * Hydrate variable phrases
   * @param _str 
   * @param depth 
   * @returns 
   */
  const _hydrateVars = (_str: string, stack: IStack): any => {
    if (!_str || typeof _str !== "string") return _str; // Make sure we have a valid string

    let hyObject: any = UNSET_OBJECT;
    const contextPath = _contextKey && `contextVarsMap.${_contextKey}`;

    let hyString: string | undefined = _str.replace(VarHydrationPhraseRegEx, (match, p1, offset, originalString) => {
      let _hyString = "";
      const singlePhrase: boolean = match.length === originalString.length;
      let [varPath, fallbackValue] = p1?.split("|"); // get the varPath and fallback value

      // Get local variable scope from stack
      const [vpMatch, backpath, varName] = varPath?.match(/(^\.*)(.*)/);

      // Resolve variable scope (global, or local stack scope)
      let useVars: any;
      if (backpath.length < 1) {
        // No dot notation, use globals
        useVars = vars;
      } else {
        const stackPos = stack.objStack.length - backpath.length;
        if (stackPos < 0) throw new Error(HYDRATION_ERROR_PLACEHOLDER);
        useVars = stack.objStack[stackPos];
      }

      let resolvedValue = getNestedKeyValue(useVars, varName, contextPath, VALUE_NOT_FOUND_SYMBOL);

      // Record the var used
      if (options?.varsUsed) {
        if (options.onVarUsed) {
          options.varsUsed[p1] = options.onVarUsed(p1, options.varsUsed[p1], options.varsUsed);
        } else {
          options.varsUsed[p1] = true;
        }
      }

      // Post-process the hyValue
      // if (offset > 0) hyObject = INVALID_OBJECT;
      switch (typeof resolvedValue) {
        case "string":
          if (resolvedValue === match) {
            throw new Error(CIRCULAR_HYDRATION_ERROR_MESSAGE);
          }

          if (VarHydrationPhraseRegEx.test(resolvedValue)) {
            try {
              // _hyString = hydrateString(resolvedValue, vars, _contextKey, localVars, { ...options, stringifyObjects: true }, stack);
              // _hyString = hydrateString(resolvedValue, vars, _contextKey, localVars, { ...options, stringifyObjects: options?.stringifyObjects || !singlePhrase }, stack);

              circularReferencePush(varName, stack, _options.maxDepth); // Prevent ciruclar references
              // const hyVal = hydrateString(resolvedValue, vars, _contextKey, localVars, options, stack);
              const hyVal = hydrateString(resolvedValue, vars, _contextKey, options, stack);
              const hyValType = typeof hyVal;
              if (hyValType === "string") _hyString = hyVal;
              else hyObject = hyVal;
              circularReferencePop(stack, false);

            } catch (e: any) {
              _hyString = errorPlaceholder(e);
            }
          } else {
            _hyString = resolvedValue;
          }
          break;

        case "object":
          if (resolvedValue === null && singlePhrase && !_options.stringifyObjects) {
            // We have a real null value
            hyObject = null;
            break;
          };

          // resolvedValue = hydrateObject(resolvedValue, vars, _contextKey, { ...options, stringifyObjects: true }, stack);
          try {
            circularReferencePush(varName, stack, _options.maxDepth); // Prevent ciruclar references
            resolvedValue = hydrateObject(resolvedValue, vars, _contextKey, options, stack);
            circularReferencePop(stack, false);
          } catch (e: any) {
            _hyString = errorPlaceholder(e);
            // throw(e); // <----- THIS AN EXPERIMENT TO TRIM A STRING WITH MULTIPLE HYDRATION PHRASES INTO A SINGLE ERROR STRING. IT'S INCORRECT AND SHOULD BE AN ERROR FOR EACH INSTANCE!!
            break;
          }

          if (!singlePhrase || !!_options.stringifyObjects) {
            // Sub-part of a string, OR explicity stringifying
            _hyString = JSON.stringify(resolvedValue);
            break;
          }

          // Use the real object
          hyObject = resolvedValue;

          break;

        case "symbol": {
          switch (resolvedValue) {
            case VALUE_NOT_FOUND_SYMBOL:
              if (_options?.lateBindingHydration) {
                // If late-binding, convert seen phrases to ^late[varName]
                _hyString = p1 ? `^late[${p1}]` : fallbackValue;
                break;
              }

              if (fallbackValue !== undefined) {
                // Fallback was provided, even an empty string
                if (singlePhrase) {
                  if (fallbackValue === "undefined") {
                    hyObject = undefined;
                    break;
                  }

                  try {
                    hyObject = JSON.parse(fallbackValue);
                  } catch (e: any) {
                    _hyString = fallbackValue;
                  }
                  break;
                }

                _hyString = fallbackValue;
                break;
              }

              if (!!_options.verbose) {
                // Inject not_found placeholder
                _hyString = VALUE_NOT_FOUND_PLACEHOLDER;
                break;
              }

              if (singlePhrase) {
                hyObject = undefined;
                break;
              }

              break;

            default:
              if (!!_options.verbose) {
                // CATCH ALL OTHER SYMBOLS AND RETURN AN ERROR (DON'T KNOW WHAT TO DO WITH IT)
                _hyString = HYDRATION_ERROR_PLACEHOLDER;
              }
              break;
          }

          break;
        }

        case "number":
        case "boolean":
        case "bigint":
        case "undefined":
        default:
          if (singlePhrase) {
            hyObject = resolvedValue;
            break;
          }

          if (resolvedValue !== undefined || !!_options.verbose) {
            _hyString = JSON.stringify(resolvedValue);
          }
          break;
      }

      return _hyString;
    });

    return typeof hyObject !== "symbol" ? hyObject : hyString;
  }

  /**
   * Hydrate asset phrases
   * 
   * @param _str 
   * @returns 
   */
  const _hydrateAssets = (_str: string): any => {
    if (!_str || typeof _str !== "string") return _str; // Make sure we have a valid string

    const contextPath = _contextKey && `contextVarsMap.${_contextKey}`;

    const hyString: string | undefined = _str.replace(AssetHydrationPhraseRegEx, (match, p1, offset, originalString) => {
      try {
        let assetPath = "";
        let [assetDirKey, filePath] = p1.split(":");
        if (!filePath) {
          filePath = assetDirKey;
          assetDirKey = undefined;
        }
        if (!filePath) {
          assetPath = assetPath = `${MALFORMED_ASSET_PATH}[${p1}]`;
        }
        else {
          // TODO: Support variable hydration phrase inside of asset phrase

          let assetDirPath = assetDirKey ? getNestedKeyValue(vars, assetDirKey, contextPath, VALUE_NOT_FOUND_SYMBOL) : "";
          if (assetDirPath === VALUE_NOT_FOUND_SYMBOL) {
            return `${MALFORMED_ASSET_PATH}[${p1}]`;
          }
          assetPath = assetDirPath ? `${assetDirPath}/${filePath}` : filePath;

          // Try to resolve assetPath in signed assetMap, otherwise fallback to @[assetPath] for subsequent hydration
          assetPath = (vars?.assetMap && vars.assetMap[assetPath])
            || `${EscapedAssetPreamble}${assetPath}${EscapedAssetPostamble}`;
        }
        return assetPath;
      } catch (e: any) {
        return `${MALFORMED_ASSET_PATH}[${p1}]`;
      }
    });

    return hyString;
  }

  /*
      MUST SUPPORT:
  
              {
                a: "@[cardAsset:foo.jpg]"
                b: "^[.a]"
              }

              {
                a: "cardAsset:foo.jpg"
                b: "@[^[a]]"
              }

              {
                a: "cardAsset:foo.jpg"
                b: "@[^[a]]"
              }

*/

  let hyValue = str;
  hyValue = _hydrateVars(hyValue, stack);

  // Hydrate asset phrases cyclically until done
  hyValue = _hydrateAssets(hyValue);

  // Remove late-binding placeholders
  if (typeof hyValue === "string") {
    hyValue = hyValue.replace(LateVarHydrationPhraseRegEx, (match, p1, offset, originalString) => {
      return `^[${p1}]`;
    });
  }

  // wasEmptyStack && logger.debug(`Deepest Stack: ${stack.deepestStack}`);

  return hyValue;
};

/**
 * This is a necessary pre-flight hydration support function that hydrates vars themselves.
 * 
 * @param vars Vars to be hydrated
 * @param pure Create a new deep object
 * @returns Hydrated vars
 */
export const hydrateVars = (vars: IHydrateObj | undefined | null = undefined, options: IHydrateOptions | undefined = undefined): IHydrateObj | undefined | null => {
  return hydrateObject(vars, vars, undefined, options);
}

/**
 * hydrateObject
 * 
 * @param obj Object to be hydrated, and used as localVars that override vars.
 * @param vars Variables used to populate the string
 * @param localVars closest local vars obj (uses _contextKey to establish local context)
 * @param _contextKey seed if not CONTEXT_KEYWORD ("_contextKey") exists in obj
 * @param showPlaceholders show placeholders
 * @param pure Create a new deep object
 * @param depth internal depth counter used to prevent stack overflows (infinite recusion)
 * @returns 
 */
export const hydrateObject = (
  obj: IHydrateObj | undefined | null = undefined,
  vars: IHydrateObj | undefined | null = undefined,
  _contextKey: string | undefined = undefined,
  options: IHydrateOptions | undefined = undefined,
  stack: IStack = newStack()): IHydrateObj | undefined | null => {

  if (!obj) return obj; // Return immediatley if nothing to hydrate

  // Prep context
  const _options = { ...HydrateOptionDefaults, ...(options || { pure: true }) };
  let _obj = !!_options?.pure ? cloneDeep(obj) : obj;
  let _vars = obj === vars ? _obj : vars; // If hydrating self (obj and vars are the same object), then carry over clone
  const wasEmptyStack: boolean = stack.objStack.length === 0;
  if (wasEmptyStack) stack.deepestStack = 0;
  circularReferencePush(_obj, stack, _options.maxDepth); // Prevent ciruclar references

  // Record the deepest stack we've seen
  const totalDepth = stack.objStack.length + stack.altStack.length;
  if (totalDepth > stack.deepestStack) stack.deepestStack = totalDepth;
  const nearest_contextKey = _obj[CONTEXT_KEYWORD] || _contextKey; // Pick up the context upon handling of each object context
  const inNewContext = CONTEXT_KEYWORD in _obj;
  const _pinnedScopeVars = inNewContext && _obj; // contextLocalScope (what shortcut to use?    !.foo     &.foo     *.foo     %.foo   )

  for (let key in _obj) {
    const value = _obj[key];
    const valType = typeof value;
    switch (valType) {
      case "object":
        if (value !== null) {
          if (!options?.onHydrateKey || options.onHydrateKey(key, _obj)) {
            try {
              _obj[key] = hydrateObject(value, _vars, nearest_contextKey, { ..._options, pure: false }, stack);
            } catch (e: any) {
              _obj[key] = errorPlaceholder(e);
            }
          }
          // else {
          //   logger.debug(`Hydrator Ignored Key: ${key}`);
          // }
        }
        break;
      case "string":
        try {
          let hyValue: any;
          try {
            hyValue = hydrateString(value, _vars, nearest_contextKey, { ..._options, pure: false }, stack); // This could return the same value if lateBinding
          } catch (e: any) {
            hyValue = errorPlaceholder(e);
          }

          const _valType = typeof hyValue;
          if (_valType === "object" && hyValue !== null) {
            const _hyValue = hydrateObject(hyValue, _vars, nearest_contextKey, { ..._options, pure: false }, stack);
            _obj[key] = _hyValue;
          } else {
            if (hyValue === undefined && !_options.verbose) {
              delete _obj[key];
            } else {
              _obj[key] = hyValue;
            }
          }
        } catch (e: any) {
          _obj[key] = errorPlaceholder(e);
        }
        break;
      case "undefined":
        if (_obj[key] === undefined && !_options.verbose) delete _obj[key];
        break;
      case "number":
      case "boolean":
      case "bigint":
      case "symbol":
        break;
      default:
        _obj[key] = HYDRATION_ERROR_PLACEHOLDER;
        break;
    }
  }

  circularReferencePop(stack, true);
  // wasEmptyStack && logger.debug(`Deepest Stack: ${stack.deepestStack}`);

  return _obj;
};

/**
 * Hydrate an object where values point to vars. This method first stringifies and then uses the
 * string hydrator. The recursive object walking function below was deprocated but may need to be
 * reimplemented if this funciton proves to be unreliable. NOTE: hydrateObject_Old is not functional
 * and will need to be rewritten to enable tests to pass.
 *
 * THIS DOES NOT SUPPORT LOCALPATH!!!!!
 *
 * @param obj
 * @param vars
 */
export const hydrateStringifiedObject = (
  obj?: IHydrateObj | undefined,
  vars?: IHydrateObj | undefined,
  showUndefinedVariables: boolean = false
): IHydrateObj | undefined => {

  // Return immediatley if nothing to hydrate
  if (!obj) return obj;

  const jsonString = JSON.stringify(obj);
  const stack = circularReferencePush(obj);
  const hydratedString = hydrateString(
    jsonString,
    vars,
    undefined,
    // obj,  // Pass object itself as local (override values)
    { stringifyObjects: true, verbose: showUndefinedVariables },
    stack
  );
  const hydratedObj = JSON.parse(hydratedString);
  // cleanUndefinedKeysFromObject(hydratedObj);
  return hydratedObj;
};

//
// SEE END OF FILE FOR ASSET SIGNING REGEX NOTES
//

// New Asset Shorthand
export const AssetPreamble = "@[";
export const EscapedAssetPreamble = "@[";
export const AssetPostamble = "]";
export const EscapedAssetPostamble = "]";
export const assetPathRegEx = /@\[(.*?)\]/gm;
export const escapedAssetPathRegEx = /@\[(.*?)\]/gm;

/**
 * Scrape assets and return a unique list of asset strings
 *
 * Finds: All unique instances of asset:\\url_to_be_signed\\
 * RegEx: /asset:\\\\(.*?)\\\\/gm; <-- double escaped for JSON output
 *
 * @param input string or object to scrape
 * @param escapedInput true if the string has been escaped by JSON.stringify or other means
 * @param assetPaths array of unique assets
 * @returns string array of unique assets
 */
export const scrapeAssets = (
  input: any,
  escapedInput: boolean = false,
  assetPaths?: string[]
): string[] => {
  const paths: string[] = [...(assetPaths || [])]; // Build upon an existing array
  const isString = typeof input === "string";
  const inputStr = isString ? input : JSON.stringify(input);
  const re: RegExp =
    isString && !escapedInput ? assetPathRegEx : escapedAssetPathRegEx;
  let match;

  do {
    match = re.exec(inputStr); // Find next match
    match && match[1] && paths.push(match[1]); // If we got one, push it
  } while (match);

  return [...new Set(paths)]; // Remove duplicates
};














// const makeHydratedStringValue = (value: any, isJsonValue: boolean = false): any => {
//   const valueType = typeof value;
//   switch (valueType) {
//     case "undefined":
//       return VALUE_UNDEFINED_PLACEHOLDER;
//     case "number":
//     case "boolean":
//     case "string":
//     case "object":
//     default:
//       return value;
//     // return value === null ? null : JSON.stringify(value);
//     // case "object":
//     //   // return value === null ? null : isJsonValue ? JSON.stringify(value) : OBSCURED_OBJECT_PLACEHOLDER;
//     //   return value === null ? null : JSON.stringify(value);
//     // default:
//     //   return isJsonValue ? JSON.stringify(value) : value;
//   }
// };

// export const cleanUndefinedKeysFromString = (str: string): string => {
//   const cleanStr = str.replace(/\!\[undefined\]/, ""); // Remove any lingering undefined variables
//   return cleanStr;
// };

// export const removePlaceholdersFromString = (str: string, isJsonValue: boolean = false): string => {
//   let cleanStr = str;

//   if (isJsonValue) {
//     cleanStr = cleanStr.replace(/,?\s*\"[^"]*"\s*?:\s*?\!\[undefined\]/g, ""); // Remove all undefined JSON keys
//     cleanStr = cleanStr.replace(/,?\s*\"[^"]*"\s*?:\s*?\!\[object\]/g, ""); // Remove all object JSON keys
//     cleanStr = cleanStr.replace(/^\[\s*,\s*/, "["); // Cleanup leading comma if untitled was first key of a JSON file
//     cleanStr = cleanStr.replace(/\!\[undefined\]/, ""); // Remove any lingering undefined variables
//   } else {
//     cleanStr = cleanStr.replace(/\!\[undefined\]/g, ""); // Remove any lingering undefined variables
//     cleanStr = cleanStr.replace(/\!\[object\]/g, ""); // Remove any lingering undefined variables
//   }

//   return cleanStr;
// };

// export const cleanUndefinedKeysFromObject = (obj?: IHydrateObj | undefined) => {

//   // Return immediatley if nothing to hydrate
//   if (!obj) return obj;

//   //
//   // Recusive Traverse Object Function
//   //
//   const _traverseObj = (obj?: IHydrateObj | undefined, objStack: any[] = [], depth: number = 0) => {
//     // Return immediatley if nothing to hydrate
//     if (!obj) return obj;

//     // Prevent ciruclar references
//     circularReferencePush(obj, objStack);
//     // if (circularReferenceHelper(obj, seenObjects, depth)) return;

//     for (let key in obj) {
//       const value = obj[key];
//       const valueType = typeof value;

//       // If it's undefined or the undefind placeholder, delete the key
//       if (
//         valueType === "undefined" ||
//         (valueType === "string" && value === VALUE_UNDEFINED_PLACEHOLDER)
//       ) {
//         delete obj[key];
//       }

//       // Move recursively through the object
//       if (valueType === "object") {
//         _traverseObj(obj[key]);
//       }
//     }

//     circularReferencePop(obj, objStack);
//   }

//   // Traverse Object
//   return _traverseObj(obj);
// };

/*
    //
    // *** DO NOT DELETE ***
    //
    // This is a method-pair to wrap a an object and return it as a string that can then be unwrapped
    //

    //
    //
    // UNWRAP OBJECTS -- Necessary if we cannt get the hyObject out of the match closure
    //
    // RegEx: /%\[START:(?!.*%\[START:)(.+?):END\]%/      <---- RegEx Magic
    //
    // let isObj = false;
    // const objStr = hyValue.replace(/%\[START:(?!.*%\[START:)(.+?):END\]%/,
    //   (match, p1, offset, originalString) => {
    //     isObj = true;
    //     return p1;
    //   }) || hyValue;
    // hyValue = isObj ? JSON.parse(objStr) : hyValue;
    //

    //
    // UNWRAP OBJECTS -- Necessary if we cannt get the hyObject out of the match closure
    //
    // RegEx: /%\[START:(?!.*%\[START:)(.+?):END\]%/      <---- RegEx Magic
    //
    // let isObj = false;
    // const objStr = hyValue.replace(/%\[START:(?!.*%\[START:)(.+?):END\]%/,
    //   (match, p1, offset, originalString) => {
    //     isObj = true;
    //     return p1;
    //   }) || hyValue;
    // hyValue = isObj ? JSON.parse(objStr) : hyValue;
    //


*/

/*

RegEx - Match asset to hydrate signed url

(.*)asset:\\\\(.*)\\\\(.*)  <— WORKS in reverse order and no bucket group

// WITH BUCKET NAME (repeat in reverse)
'position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; background-image: url(asset:\\bucket1:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\); background-size: 100%; background-position: 30% 30%; background-repeat: no-repeat;; background-image: url(asset:\\bucket2:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\);'

'position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; background-image: url(asset:\\bucket1:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\); background-size: 100%; background-position: 30% 30%; background-repeat: no-repeat;; background-image: url(http://v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg);'

'position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; background-image: url(http://v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg); background-size: 100%; background-position: 30% 30%; background-repeat: no-repeat;; background-image: url(http://v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg);'

// WITHOUT BUCKET
'position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; background-image: url(asset:\\v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\); background-size: 100%; background-position: 30% 30%; background-repeat: no-repeat;; background-image: url(asset:\\v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\);'

'position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; background-image: url(asset:\\v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\); background-size: 100%; background-position: 30% 30%; background-repeat: no-repeat;; background-image: url(http://v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg);'

-------------

asset:\\bucket:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\

asset:\\bucket:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\blah_blah_foo_bar

blah_blah_/asset:\\bucket:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\blah_blah_foo_bar\\


'position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; background-image: url(asset:\\v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\); background-size: 100%; background-position: 30% 30%; background-repeat: no-repeat;'

asset:\\bucket:v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg

asset:\\v1/sec/user/96c1c0c1-56dd-4dcd-a2e4-141134b96ccc/card/fd16b701-0d9c-4933-bc19-7752afe3dd05/assets/leaf.jpg\\blah_blah_foo_bar

*/
