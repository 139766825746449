import { isBefore, getUnixTime, getMilliseconds } from "date-fns";

/**
 * Return Unix Epoch Time (with option to add moment duration
 * @param addTimePeriod = milliseconds or object {seconds: 2,minutes: 2,hours: 2,days: 2,weeks: 2,months: 2,years: 2}
 * @param useMoment
 * @returns {*}
 *
 */
// TODO: Convet to Date-FNS
// export const getEpochTime = (addTimePeriod?: moment.DurationInputObject, altMoment?: moment.Moment) => {
//     const m = altMoment || moment();
//     return addTimePeriod ?
//         m.add(moment.duration(addTimePeriod)).unix() : // Add addTimePeriod of either milliseconds or object {seconds: 2,minutes: 2,hours: 2,days: 2,weeks: 2,months: 2,years: 2}
//         m.unix();
// };

// TODO: Convet to Date-FNS
// export const getExpirationMoment = (addTimePeriod?: moment.DurationInputObject, altMoment?: moment.Moment) => {
//     const m = altMoment || moment();
//     return addTimePeriod ?
//         m.add(moment.duration(addTimePeriod)).toDate() : // Add addTimePeriod of either milliseconds or object {seconds: 2,minutes: 2,hours: 2,days: 2,weeks: 2,months: 2,years: 2}
//         m.toDate();
// };

// export const isMomentExpired = (expMoment: moment.Moment, altMoment?: moment.Moment) => {
//     const m = altMoment || moment();
//     return expMoment.isAfter(m);
// }

export const isTimeExpired = (time: Date, altNow?: Date) => {
    return isBefore(time, (altNow || new Date(Date.now())));
}

// TODO: Convet to Date-FNS
// export const isEpocTimeExpired = (epocTime: number, altEpocTime?: number) => {
//     return isMomentExpired(moment.unix(epocTime), altEpocTime ? moment.unix(altEpocTime) : undefined);
// }

/**
 * Base62
 */
const base62 = {
    charset: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        .split(''),
    encode: (n: number) => {
        let int = n;
        if (int === 0) {
            return 0;
        }
        let s: string[] = [];
        while (int > 0) {
            s = [base62.charset[int % 62], ...s];
            int = Math.floor(int / 62);
        }
        return s.join('');
    },
    decode: (str: string) => str.split('').reverse().reduce((prev, curr, i) =>
        prev + (base62.charset.indexOf(curr) * (62 ** i)), 0)
};

/**
 *
 * Time Semi-Sortable Epoc Time Key      [ timekey ]
 * 12 characters
 *
 * Sorts to millisecond. If multiple keys are generated within that same milisecond, the random suffix is likely to
 * sequence the keys out of order within that same millisecond. This is not a major issue for most situations.
 *
 * Example: o0icajdw6ens -> EPOC(6) + Milliseconds(2) + random(4)
 *   No longer using counter as part of key because in micro-services it will always start at 0 which would be pointless.
 *
 * WARNING - MUST USE BASE 36 FOR COMPATIBILITY WITH CASE-INSENSITIVE QUERIES.
 *
 */
// const shortKeyCounter = 0;
const tLen = 8;
const iLen = 2;
const rLen = 6;
const rMax = Math.pow(62, rLen) - 1; // 60466175 = (62^6-1) = Math.pow(62,rLen)-1
export const TIMEKEY_LENGTH = tLen + rLen + iLen;

export const newTimekey = (instanceTag: string = "00"): string => {
    if (instanceTag.length !== iLen) throw new Error("Invalid instance tag");
    const pad = "000000";
    const now = Date.now();
    // const nowStr = now.toString(36);
    let nowStr = base62.encode(now) || "0";
    nowStr = pad.substring(0, tLen - nowStr.length) + nowStr;

    // Build epoc time string
    // const nowEpochTime = getUnixTime(now).toString(36);

    // Build millisecond padded string
    // let millisecs = getMilliseconds(now).toString(36);
    // millisecs = millisecs.length < 2 ? "0" + millisecs : millisecs;
    // Build counter padded string
    // const counter = shortKeyCounter.toString(36);
    // counter = pad.substring(0, cLen - counter.length) + counter;   //ct = ct.length < 2 ? "0" + ct : ct;
    // shortKeyCounter = shortKeyCounter >= cMax ? 0 : shortKeyCounter + 1;     // 3 characters (36^3-1) = 46655 / (36^2-1) = 1295

    // Generate Random Suffix
    // let randNum = Math.floor(Math.random() * rMax).toString(36);
    let randNum: string = base62.encode(Math.floor(Math.random() * rMax)) || "0";
    randNum = pad.substring(0, rLen - randNum.length) + randNum;    //rn = rn.length < 2 ? "0" + rn : rn;

    // return assembled short key
    // return nowEpochTime + millisecs + randNum;  // Removed counter: (epochTime + millisecs + counter + randNum)

    return nowStr + randNum + instanceTag;
};

/**
 * Return Microtime from a TimeKey
 *
 * @param {string} timekey
 * @returns {number}
 */
export const getTimekeyTime = (timekey: string): number => {
    const b36: string = timekey.substr(0, tLen);  // Strip any preTag and postTag, then drop the 4 random chars = 10
    // return parseInt(b36, 36);
    return base62.decode(b36);
}

export const generateRandomNumericString = (length: number): string => {
    let codeString = '';

    for (let i = 0; i < length; i++) {
        codeString += ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',][Math.floor(Math.random() * 10)];
    }
    return codeString;
};

/**
 * Return an instance Key
 * 
 * @param prefix Optional prefix string
 * @param spacer Optional spacer between prefix and instance timeKey
 * @returns 
 */
export const newInstanceKey = (prefix?: string, spacer: string = "_", key?: string | number) => `${prefix ? (prefix + (spacer ? spacer : "")) : ""}${key || newTimekey()}`;
