import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './SpacerWidgetViewMode';

// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./SpacerWidgetDesignMode'));

export const SpacerWidgetType = "widget/Spacer";

export interface ISpacerWidgetVars {
    space: string | number;
}

export interface ISpacerWidgetLayout extends IWidgetLayout, ISpacerWidgetVars { }
export interface ISpacerWidgetInstance extends IWidgetLayoutTuple<ISpacerWidgetLayout, ISpacerWidgetVars> { }
export interface ISpacerWidgetProps extends IWidgetProps<ISpacerWidgetLayout, ISpacerWidgetVars> { }
export interface ISpacerWidgetSchema extends IWidgetSchema<typeof SpacerWidgetType, ISpacerWidgetProps> { }

export const spacerWidgetSchema: ISpacerWidgetSchema = {
    widgetType: SpacerWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
