// Gen 2

// ----- IMPORTS -------------------------------------------------------------------------------------------------------/

import _logger, { LOGGER_CONFIG_DEFAULT } from "./logger";
import { promisify } from "util";
import { Buffer } from 'buffer';
// CLIENT IMPORTS
// import cloneDeep from "lodash-es/cloneDeep"; // CLIENT
// import isEqual from "lodash-es/isEqual"; // CLIENT
// SERVER IMPORTS
import cloneDeep from "lodash/cloneDeep"; // SERVER
import isEqual from "lodash/isEqual"; // SERVER

// --- LOGGER -------------------------------------------------------------------------------------
var __filename = "commonUtils.ts";   // OVERRIDE FOR CLIENT BROWSER USE - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");
// ------------------------------------------------------------------------------------------------

export const enumKeys = (enumType: any): string[] => {
    const keys: string[] = Object.keys(enumType);
    return keys;
}

export const enumValues = <T>(enumType: any): T[] => {
    const values: T[] = Object.values<T>(enumType);
    return values;
}

export const enumEntries = <T>(enumType: any): [string, T][] => {
    const entries: [string, T][] = Object.entries<T>(enumType);
    return entries;
}

export const enum2Obj = <T>(enumType: any): { [key: string]: T } => {
    const obj: { [key: string]: T } = {};
    Object.keys(enumType).forEach((key: string) => {        // Was map
        obj[key] = enumType[key];
    })
    return obj;
}

export const asArray = <T>(value: T | T[] | undefined): Array<T> => {
    if (value === undefined) return Array();               // If undefined, return empty array (do NOT push undefined)
    return Array.isArray(value) ? value : Array(value);
}

export const generateRandomNumericString = (length: number): string => {
    let codeString = '';

    for (let i = 0; i < length; i++) {
        codeString += ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',][Math.floor(Math.random() * 10)];
    }
    return codeString;
};

/**
 * Get key in object. Use best effort, so if either obj or path is undefined, return undefined without error
 * 
 * @param obj           The Object
 * @param path          Path to key. Empty path points to the object. Tilde "~" inserts contextPath to path.
 * @param contextPath   ContextPath will prefix the path if path starts with a tilde (~). For example, contextPath=x.y and path=~c results in transformedPath=x.y.c
 * @param notFoundValue Return this value if key not found
 * @param throwErrors   If key not found, or missing inputs, throw an error
 */
export const getNestedKeyValue = (
    obj: { [key: string]: any } | undefined | null,
    path: string | undefined,
    contextPath: string | undefined = undefined,
    notFoundValue: any = undefined,
    throwErrors: boolean = false
): any => {
    const cleanPath = path?.trim();

    // Handle empty object and empty path situations
    if (!obj || !cleanPath) {
        if (throwErrors) throw new Error("Invalid key path");
        return notFoundValue;
    }

    // If obj is not an object, exit
    if (typeof obj !== "object") {
        if (throwErrors) throw new Error("Invalid object");
        return notFoundValue;
    }

    // Resolve path
    const resolvedPath = `${cleanPath?.charAt(0) === '~' ? ((contextPath ? contextPath : "") + cleanPath.substring(1)) : cleanPath}`;
    const pathKeys: string[] = resolvedPath.split(".").map(s => s.trim());   // Split on . and trim each part
    const value = pathKeys.reduce((previous: any, nextKey: string, index, arr) => {
        if (typeof previous !== "object") {
            // Eject early, not found
            arr.splice(1);
            if (throwErrors) throw new Error("Key not found");
            return notFoundValue;
        }
        if (nextKey in previous) return previous[nextKey];

        // Eject early, not found
        arr.splice(1);
        if (throwErrors) throw new Error("Key not found");
        return notFoundValue;
    }, obj);
    return value;
};

/**
 * 
 * @param obj The object to operate on
 * @param keyPath Path to the key to set
 * @param value New value to set on key
 * @param contextPath Context path prefix to use if value uses Tilde ~.key
 * @param throwErrors 
 * @returns The object
 */
export const DELETE_NESTED_KEY = Symbol();
export const UNDEFINED_CONTEXT_PATH = "UNDEFINED_CONTEXT";

export const setNestedKeyValue = (
    obj: { [key: string]: any } | undefined,
    keyPath: string | undefined,
    value: any = undefined,
    contextPath: string | undefined = undefined,
    pure: boolean = false,
    throwErrors: boolean = false
): { [key: string]: any } | undefined => {
    const deleting = value == DELETE_NESTED_KEY;
    const cleanPath = keyPath?.trim();

    // Handle empty object and empty path situations
    if (!cleanPath) {
        if (throwErrors) throw new Error("Invalid key path");
        return undefined;
    }

    const useContextPath: boolean = cleanPath?.charAt(0) === '~';
    // if (useContextPath && !contextPath) logger.error(`No context when setting nested key/value [${keyPath} = ${value}]`);

    const resolvedPath = `${useContextPath ? ((contextPath ? contextPath : UNDEFINED_CONTEXT_PATH) + cleanPath.substring(1)) : cleanPath}`;
    const pathKeys: string[] = resolvedPath.split(".").map(s => s.trim());   // Split on . and trim each part

    // Build out the new path
    const modifiedObj = pure ? cloneDeep(obj || {}) : (obj || {});
    let tObj = modifiedObj;
    let key: string | undefined;
    while (pathKeys.length) {
        key = pathKeys.shift();  // Get next key in path

        if (!key) {
            if (throwErrors) throw new Error("invalid key path");
            logger.error(`No local variable context when setting nested key/value [${keyPath} = ${value}]`);
            return modifiedObj;
        }

        // Found the final target, act on it!!
        if (pathKeys.length === 0) {
            if (deleting && key in tObj) {
                delete tObj[key];
            } else {
                tObj[key] = value;
            }
            return modifiedObj;
        }

        // Handle key/value existence and recusive logic
        const exists = key in tObj;
        if (!exists && deleting) {
            // We hit a dead to our path and are deleting the target key so we're done, simply exit.
            return modifiedObj;
        }

        // If the key doesn't exist OR if the current value is not an object, overwrite it with a new empty object
        if (!exists || typeof tObj[key] !== "object") {
            tObj[key] = {};
        }

        // Step into the next context
        tObj = tObj[key];
    }

    // Something went wrong, we should never get here
    if (throwErrors) throw new Error("unkonwn error parsing key path");
    return modifiedObj;
};

/**
 * Circular Reference Helper
 * Prevents overflowing when working recursively on objects that contain ciruclar references
 * 
 * @param obj           object to check and remember
 * @param seenObjects   array of objects seen
 * @returns             true if seen, false if not
 */
export const circularReferenceHelper = (obj: { [key: string]: any },
    seenObjects: any[] = [], depth: number = 0): boolean => {

    // Make sure we've not seen this object and we're not in an overflow stack situation
    if (seenObjects.includes(obj)) return true;
    if (depth > 10000) throw new Error(`circular reference overflow`);

    // Push this object
    seenObjects.push(obj);
    return false;
}

/**
 * Deep delete missing keys
 * Used after Object.assign() to delete residual keys not present in source object
 * 
 * @param targetObj 
 * @param sourceObj 
 * @param pure      true returns new cleansed object, false modifies the original object
 * @returns         { changed: boolean, targetObj: { [key: string]: any } }
 */
export const deepDeleteMissingObjectKeys = (targetObj: { [key: string]: any }, sourceObj: { [key: string]: any },
    pure: boolean = false): { changed: boolean, targetObj: { [key: string]: any } } => {

    //
    // Recusive Traverse Object Function
    //
    const _traverseObj = (targetObj: { [key: string]: any }, sourceObj: { [key: string]: any },
        seenObjects: any[] = [], depth: number = 0): { changed: boolean, targetObj: { [key: string]: any } } => {

        // Prevent ciruclar references
        if (circularReferenceHelper(targetObj, seenObjects, depth)) return { changed: false, targetObj };

        // Traverse everything!
        let changed = false;
        for (let key in targetObj) {
            if (!(key in sourceObj)) {
                // Does't exist in source, delete key
                delete targetObj[key];
                changed = true;
            } else {
                // Key exists in source. If an object, call recursively.
                if (typeof targetObj[key] === "object" && targetObj[key] !== null) {
                    const result = _traverseObj(targetObj[key], sourceObj[key], seenObjects, depth + 1);
                    changed = changed || result.changed;
                }
            }
        }

        return { changed, targetObj };
    }

    // Traverse the object and delete the missing keys
    const _targetObj = pure ? cloneDeep(targetObj) : targetObj;
    return _traverseObj(_targetObj, sourceObj);
}

/**
 * Update an object's values, preserving as many containing objects as possible
 * 
 * @param target
 * @param sourceObj 
 * @param pure 
 * @returns 
 */
export const deepUpdateObjectKeys = (targetObj: { [key: string]: any }, sourceObj: { [key: string]: any },
    pure: boolean = false): { changed: boolean, updatedObj: { [key: string]: any } } => {

    if (targetObj === sourceObj) return { changed: false, updatedObj: targetObj };

    const changed = !isEqual(targetObj, sourceObj);
    let updatedObj = pure ? {} : targetObj; // Revise to only clone at recusion level zero

    Object.assign(updatedObj, sourceObj);
    deepDeleteMissingObjectKeys(updatedObj, sourceObj);
    // const saftyCheck = isEqual(updatedObj, newObj);

    return { changed, updatedObj };
}

export const deleteUndefinedKeys = (obj?: { [key: string]: any }, pure: boolean = false): { [key: string]: any } | undefined => {
    if (!obj) return obj;
    const tempObj = pure ? cloneDeep(obj) : obj;
    Object.keys(tempObj).forEach(key => tempObj[key] === undefined && delete tempObj[key]);
    return tempObj;
}

/**
 * Convert seconds to Days, Hours, Minutes, Seconds, Text
 * 
 * @param seconds
 * @returns 
 */
export const secondsToDhmst = (seconds: number): { d: number, h: number, m: number, s: number, t: string } => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dText = d > 0 ? d + (d == 1 ? " day" : " days") : "";
    const hText = h > 0 ? h + (h == 1 ? " hour" : " hours") : "";
    const mText = m > 0 ? m + (m == 1 ? " minute" : " minutes") : "";
    const sText = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    const t = `${dText}${hText && (dText) && ", "}${hText}${mText && (dText || hText) && ", "}${mText}${sText && (dText || hText || mText) && ", "}${sText}`;
    return { d, h, m, s, t };
}

/**
 * Create a path string from an array of nested directories 
 * 
 * @param basePath base directory (if any)
 * @param dirArray array of nested directory names
 * @param fallback 
 * @param throwError 
 * @returns 
 */
export const makePathString = (basePath: string | undefined, dirArray: (string | undefined | null)[], fallback: string | undefined = undefined, throwError: boolean = true): string | undefined => {
    let path = basePath;
    dirArray.forEach((dir) => {
        path = (path || dir) ? (`${path ? `${path}/${dir || ""}` : `${dir || ""}`}`) : path;
    })
    path = path?.replace(/(\/*$)/, '');
    if (path?.includes("//")) {
        path = fallback;
        logger.error("Invalid path", path);
        if (throwError) throw new Error(`INVALID PATH: ${path}`);
    }
    return path;
}

/**
 * Minify Template Literal Whitespace
 * 
 * This is a somewhat dangerous brute force function for deleting a CR/LF and subsequent newline leading whitespace.
 * It is useful when using Template Literal strings; as in `query` GraphQL query strings in code
 * 
 * A better alternative is drwpow/gqlmin @ https://github.com/drwpow/gqlmin
 * 
 * @param str 
 * @returns 
 */
export const minifyTLStringWhitespace = (str: string) => str.replace(/(\r|\n|\r\n|\n\r)\s+/g, " ");

/**
 *  MISC FUNCTIONS
 */
export const defer = (f: () => void, nextTick: boolean = true) => nextTick ? process.nextTick(f) : f();
export const sleep = promisify(setTimeout);
export const getRandom = (min = 0, max = 0) => Math.floor(Math.random() * (max - min + 1)) + min;
export const clampNumber = (num: number, min: number = Number.MIN_VALUE, max: number = Number.MAX_VALUE): number => Math.max(Math.min(num, Math.max(min, max)), Math.min(min, max));
export const undefinedDefault = (value: any, defaultValue: any) => value === undefined ? defaultValue : value;

export const encodeBase64 = (data: any) => Buffer.from(data).toString('base64');
export const decodeBase64 = (data: any) => Buffer.from(data, 'base64').toString('ascii');

/**
 * Validate Email Address
 * REF: https://www.delftstack.com/howto/javascript/email-regex-javascript/
 * REF: https://www.rfc-editor.org/rfc/rfc1034
 */
// export const emailRegEx =  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
export const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/
export const isValidEmail = (address: string) => emailRegEx.test(address);
