import { seedSnippetMapKeys } from "../widgets/widgetUtils";
import { ICatalogProduct } from "./catalogInterface";

/**
 * Default/System Catalog Product
 */
export const defaultProduct: ICatalogProduct = {
    pageMap: {},
    snippetMap: {
        "ROOT_FRAGMENT": {
            "layout": {
                "_widgetType": "htmltag/fragment",
                "_contextKey": "^[%._contextKey]--ROOT_FRAGMENT",
                "_widgets": "^[%._widgets]"
            }
        },
        "ROOT_SCROLLABLE_FRAGMENT": {
            "name": "Root Scrollable Fragment [snippet]",
            "detail": "Root layout container. CONTAINS BUG THAT FREEZES WHEN CONTINUOUS SWIPING ON FIRST RENDER",
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--STANDARD_LAYOUT",
                "_stylizer": {
                    "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden auto;}",
                    "className": "Widget"
                },
                "_widgets": [
                    {
                        "_widgetType": "snippet/ROOT_FRAGMENT",
                        "_widgets": "^[%._widgets]"
                    }
                ]
            }
        },
        "DIV": {
            "layout": {
                "_widgetType": "htmltag/div",
                "_stylizer": {
                    "css": "Widget{box-sizing: border-box;border: 4px dashed pink;height: 250px;}",
                    "className": "Widget"
                },
                "_widgets": "^[%._widgets]"
            }
        },
        "DIV_BOX": {
            "layout": {
                "_widgetType": "snippet/DIV",
                "_stylizer": {
                    "css": "Widget{box-sizing: border-box;border: 2px dashed orange;height: 250px;}",
                    "className": "Widget"
                },
                "_widgets": "^[%._widgets]"
            }
        },
        "ROOT_SCROLLABLE_LAYOUT": {
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--ROOT_SCROLLABLE_LAYOUT",
                "_stylizer": {
                    "css": "WidgetOBox{width: 100%;height: auto;box-sizing: border-box;border: 4px dotted aqua;}Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden auto;}",
                    "className": "XWidget",
                    "oBoxClassName": "WidgetOBox"
                },
                "_widgets": "^[%._widgets]"
            }
        },
        "BCO_SCROLLABLE_LAYOUT": {
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "bco_root",
                "_widgets": [
                    {
                        "//******************************** BACKGROUND LAYER ********************************": "",
                        "_widgetType": "htmltag/div",
                        "_contextKey": "bco_background",
                        "_stylizer": {
                            "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0;}",
                            "className": "Widget"
                        },
                        "_widgets": "^[%.backgroundWidgets]"
                    },
                    {
                        "//******************************** CONTENT LAYER ********************************": "",
                        "_widgetType": "htmltag/div",
                        "_contextKey": "main_scroller",
                        "_stylizer": {
                            "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden auto;}",
                            "className": "Widget"
                        },
                        "_widgets": "^[%.contentWidgets]"
                    },
                    {
                        "//******************************** OVERLAY LAYER ********************************": "",
                        "_widgetType": "htmltag/div",
                        "_contextKey": "bco_overlay",
                        "_stylizer": {
                            "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0;pointer-events: none;}",
                            "className": "Widget"
                        },
                        "_widgets": "^[%.overlayWidgets]"
                    }
                ],
                "_stylizer": {
                    "css": "Widget{position: absolute;top: 0;right:0;bottom:0;left:0;}",
                    "className": "Widget"
                }
            }
        },
        "BCO_SCROLLABLE_LAYOUT_FADE_IN": {
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "bco_root",
                "_widgets": [
                    {
                        "//******************************** BACKGROUND LAYER ********************************": "",
                        "_widgetType": "htmltag/div",
                        "_contextKey": "bco_background",
                        "_stylizer": {
                            "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0;}",
                            "className": "Widget"
                        },
                        "_widgets": "^[%.backgroundWidgets]"
                    },
                    {
                        "//******************************** CONTENT LAYER ********************************": "",
                        "_widgetType": "htmltag/div",
                        "_contextKey": "main_scroller",
                        "_stylizer": {
                            "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden auto;}",
                            "className": "Widget"
                        },
                        "_widgets": "^[%.contentWidgets]"
                    },
                    {
                        "//******************************** OVERLAY LAYER ********************************": "",
                        "_widgetType": "htmltag/div",
                        "_contextKey": "bco_overlay",
                        "_stylizer": {
                            "css": "Widget{position: absolute; top: 0; right: 0; bottom: 0; left: 0;pointer-events: none;}",
                            "className": "Widget"
                        },
                        "_widgets": "^[%.overlayWidgets]"
                    }
                ],
                "_stylizer": {
                    "css": "Widget{opacity: 0;position: absolute;top: 0;right:0;bottom:0;left:0;}",
                    "className": "Widget",
                    "pipeline": [
                        {
                            "funcName": "fromTo",
                            "targets": [
                                "#bco_root"
                            ],
                            "fromVars": {
                                "opacity": 0
                            },
                            "toVars": {
                                "duration": 2,
                                "opacity": 0.999
                            }
                        }
                    ]
                }
            }
        },
        "ROOT": {
            "name": "Layout Root [snippet]",
            "detail": "Root layout container",
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--ROOT",
                "_stylizer": {
                    "css": "Widget{height: 100%; width: 100%;}",
                    "className": "Widget"
                },
                "_widgets": "^[%._widgets]"
            }
        },
        "ROOT_FADEIN": {
            "name": "Layout Root [snippet]",
            "detail": "Root layout container. CONTAINS BUG THAT FREEZES WHEN CONTINUOUS SWIPING ON FIRST RENDER",
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--ROOT_FADEIN",
                "_stylizer": {
                    "css": "@keyframes fadeIn { 0% {opacity:0;} 100% {opacity:1;} } WidgetOBox{ opacity: 1; box-sizing: border-box; animation: fadeIn 5s;}",
                    "oBoxClassName": "WidgetOBox"
                },
                "_widgets": "^[%._widgets]"
            }
        },
        "ROOT_FADEIN_WORKS1": {
            "name": "Layout Root [snippet]",
            "detail": "Root layout container. CONTAINS BUG THAT FREEZES WHEN CONTINUOUS SWIPING ON FIRST RENDER",
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--ROOT_FADEIN",
                "_stylizer": {
                    "css": "@keyframes fadeIn { 0% {opacity:0;} 100% {opacity:1;} } Widget{ box-sizing: border-box; postion: absolute; top: 0; right: 0; bottom: 0; left: 0;  height: 100%; width: 100%; Xanimation: fadeIn 5s;overflow: hidden;}",
                    "className": "Widget"
                },
                "_widgets": [
                    {
                        "_widgetType": "htmltag/div",
                        "_contextKey": "^[%._contextKey]--LAYERS",
                        "_stylizer": {
                            "css": "@keyframes fadeIn { 0% {opacity:0;} 100% {opacity:1;} } WidgetOBox{ box-sizing: border-box; postion: absolute; top: 0; right: 0; bottom: 0; left: 0;  height: 100%; width: 100%; animation: fadeIn 5s;overflow: hidden;}",
                            "oBoxClassName": "WidgetOBox"
                        },
                        "_widgets": "^[%._widgets]"
                    }
                ]
            }
        },
        "LAYERS": {
            "name": "Layer Container [snippet]",
            "detail": "This is a container to wrap layers at the same level within a Layout Container",
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--LAYERS",
                "_stylizer": {
                    "css": "WidgetOBox{position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden;}",
                    "oBoxClassName": "WidgetOBox"
                },
                "_widgets": "^[%._widgets]"
            }
        },
        "STANDARD_LAYOUT_REF": {
            "name": "Standard Layout [snippet]",
            "detail": "All-in-one snippet building upon ROOT and LAYERS snippets. ROOT + LAYERS helper snippet TODO: FIX BUG THAT FREEZES RENDER/EVENTS @ 5 SECONDS.",
            "layout": {
                "_widgetType": "snippet/ROOT",
                "_contextKey": "^[%._contextKey]--STANDARD_LAYOUT",
                "_widgets": [
                    {
                        "_widgetType": "snippet/LAYERS",
                        "_contextKey": "^[%._contextKey]--STANDARD_LAYOUT_LAYERS",
                        "_widgets": "^[%._widgets]"
                    }
                ]
            }
        },
        "STANDARD_LAYOUT_FADEIN": {
            "name": "Standard Layout [snippet]",
            "detail": "All-in-one snippet building upon ROOT and LAYERS snippets. CONTAINS BUG THAT FREEZES WHEN CONTINUOUS SWIPING ON FIRST RENDER",
            "layout": {
                "_widgetType": "snippet/ROOT_FADEIN",
                "_contextKey": "^[%._contextKey]--STANDARD_LAYOUT",
                "_widgets": [
                    {
                        "_widgetType": "snippet/LAYERS",
                        "_contextKey": "^[%._contextKey]--STANDARD_LAYOUT_LAYERS",
                        "_widgets": "^[%._widgets]"
                    }
                ]
            }
        },
        "SVGMask": {
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--SVGMask",
                "_eventActions": "^[%._eventActions]",
                "_stylizer": {
                    "css": "WidgetOBox{width: ^[%.width|unset]; height: ^[%.height|unset]; background: ^[%.background|inherit];}Widget{width: 100%; height: 100%;background: ^[%.fill|currentColor]; mask-image: url(^[%.src]); mask-size: 100% 100%; mask-position: ^[%.position|center]; mask-repeat:^[%.repeat|no-repeat];}",
                    "oBoxClassName": "WidgetOBox",
                    "className": "Widget"
                }
            }
        },
        "divy": {
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--divy",
                "_eventActions": "^[%._eventActions]",
                "_stylizer": {
                    "css": "Widget{height: ^[.height]px; width: 100%; background: ^[.color];}",
                    "className": "Widget"
                },
                "tagAttributes": {
                    "onClick": "alert(1)"
                },
                "height": 400,
                "color": "gold",
                "Xcolor": "rgba(0,255,255,0.5)"
            }
        },
        "IMAGE_SWIPER": {
            "detail": "BCO Snippet (Background Content Overlay). Inputs: backgroundWidgets, contentWidgets, overlayWidgets, RETURNS: main_scroller",
            "layout": {
                "_widgetType": "htmltag/fragment",
                "_contextKey": "^[%._contextKey]--IMAGE_SWIPER",
                "_widgets": [
                    {
                        "X_ignore": "^[%.title&true]",
                        "_widgetType": "widget/Text",
                        "text": "^[%.title|]",
                        "_stylizer": {
                            "css": "Widget{background: black; font-size: 2em; font-weight: 800; color: white;font-variant: small-caps;padding: 20px;}",
                            "className": "Widget"
                        },
                        "X_eventActions": {
                            "onClick": {
                                "action": "Navigate",
                                "url": "^[%.url|]"
                            }
                        }
                    },
                    {
                        "_widgetType": "widget/Swiper",
                        "preset": "standard",
                        "config": "^[%.config]",
                        "images": "^[%.images]",
                        "slides": "^[%.slides]",
                        "_stylizer": {
                            "css": "Widget{position: relative; height: 500px; width: 100%; background: black;}",
                            "className": "Widget"
                        },
                        "X_eventActions": {
                            "onClick": {
                                "action": "Navigate",
                                "url": "^[%.url|]"
                            }
                        }
                    },
                    {
                        "_widgetType": "widget/Text",
                        "X_ignore": "^[%.subtext?false:true]",
                        "text": "^[%.subtext|]",
                        "_stylizer": {
                            "css": "Widget{background: black; font-size: 1em; font-weight: 800; color: lightgray;padding: 20px;}",
                            "className": "Widget"
                        },
                        "_eventActions": {
                            "onClick": {
                                "action": "Navigate",
                                "url": "^[%.url|]"
                            }
                        }
                    }
                ]
            }
        },
        "DEV_NavigateButton": {
            "version": 1,
            "layout": {
                "_widgetType": "htmltag/div",
                "_contextKey": "^[%._contextKey]--DEV_PageButton",
                "_stylizer": {
                    "css": "WidgetOBox{position: relative; width: auto; height: 100%; padding: 5px;}button{align-content: center; justify-content: center; height: 100%; padding: 10px; text-align: center; background: ^[%.background|rgba(0,0,0,0.5)]; color: ^[%.color|white]; font-weight: 800;box-sizing: border-box;border: 2px solid rgba(0,0,0,0.5);^[%.style]}",
                    "className": "button",
                    "oBoxClassName": "WidgetOBox"
                },
                "_eventActions": {
                    "onClick": {
                        "action": "Navigate",
                        "url": "^[%.url]",
                        "preventDefault": false
                    }
                },
                "_widgets": [
                    {
                        "_widgetType": "widget/Text",
                        "text": "^[%.text]"
                    }
                ]
            }
        },
        "nameInABox": {
            "version": 1,
            "layout": {
                "_widgetType": "htmltag/fragment",
                "_widgets": [
                    {
                        "_contextKey": "^[%._contextKey]_graybox",
                        "_widgetType": "htmltag/div",
                        "_stylizer": {
                            "css": "Widget{background: ^[%.background]; padding: 40px; opacity: 1;}",
                            "className": "Widget",
                            "pipeline": [
                                {
                                    "funcName": "timeline",
                                    "vars": {
                                        "scrollTrigger": {
                                            "trigger": "#^[%._contextKey]_graybox",
                                            "toggleActions": "play none none reverse",
                                            "start": "top top",
                                            "end": "bottom top-=100",
                                            "scrub": true,
                                            "markers": false
                                        }
                                    },
                                    "returnVar": "tl1"
                                },
                                {
                                    "useVar": "tl1",
                                    "funcName": "fromTo",
                                    "targets": [
                                        "#^[%._contextKey]_graybox"
                                    ],
                                    "fromVars": {
                                        "opacity": 1,
                                        "rotateX": 0,
                                        "rotateY": 0,
                                        "rotateZ": "0deg",
                                        "transformOrigin": "right top",
                                        "ease": "none"
                                    },
                                    "toVars": {
                                        "opacity": 0,
                                        "rotateX": -90,
                                        "rotateY": -90,
                                        "rotateZ": "90deg",
                                        "transformOrigin": "right top",
                                        "ease": "none"
                                    }
                                }
                            ]
                        },
                        "_widgets": [
                            {
                                "_contextKey": "^[%._contextKey]:testbox-1a",
                                "_widgetType": "htmltag/div",
                                "_stylizer": {
                                    "css": "Widget{background: ^[%.background];}WidgetOBox{opacity: 1; width: 100%; height: 100px; box-sizing: border-box; border: 2px dashed limegreen;}",
                                    "className": "Widget",
                                    "oBoxClassName": "WidgetOBox"
                                }
                            },
                            {
                                "_contextKey": "^[%._contextKey]:Name",
                                "_widgetType": "widget/Name",
                                "firstName": "^[%.firstName]",
                                "middleName": "^[%.middleName]",
                                "lastName": "^[%.lastName]",
                                "title": "^[%.title]",
                                "audioSrc": "^[%.audioSrc]"
                            },
                            {
                                "_contextKey": "^[%._contextKey]:testbox-1b",
                                "_widgetType": "htmltag/div",
                                "_stylizer": {
                                    "css": "XWidget{background: green;}Widget{background: green;opacity: 1; width: 100%; height: 200px; box-sizing: border-box; border: 2px dashed yellow;}",
                                    "className": "Widget"
                                }
                            }
                        ]
                    }
                ]
            }
        },
        "FlopOut": {
            "version": 1,
            "layout": {
                "_widgetType": "htmltag/fragment",
                "_widgets": [
                    {
                        "_contextKey": "^[%._contextKey]_graybox",
                        "_widgetType": "htmltag/div",
                        "_stylizer": {
                            "css": "Widget{background: ^[%.background]; padding: 40px; opacity: 1;}",
                            "className": "Widget",
                            "pipeline": [
                                {
                                    "funcName": "timeline",
                                    "vars": {
                                        "scrollTrigger": {
                                            "trigger": "#^[%._contextKey]_graybox",
                                            "toggleActions": "play none none reverse",
                                            "start": "top top",
                                            "end": "bottom top-=100",
                                            "scrub": true,
                                            "markers": false
                                        }
                                    },
                                    "returnVar": "tl1"
                                },
                                {
                                    "useVar": "tl1",
                                    "funcName": "fromTo",
                                    "targets": [
                                        "#^[%._contextKey]_graybox"
                                    ],
                                    "fromVars": {
                                        "opacity": 1,
                                        "rotateX": 0,
                                        "rotateY": 0,
                                        "rotateZ": "0deg",
                                        "transformOrigin": "right top",
                                        "ease": "none"
                                    },
                                    "toVars": {
                                        "opacity": 0,
                                        "rotateX": -90,
                                        "rotateY": -90,
                                        "rotateZ": "90deg",
                                        "transformOrigin": "right top",
                                        "ease": "none"
                                    }
                                }
                            ]
                        },
                        "_widgets": [
                            {
                                "_contextKey": "^[%._contextKey]:testbox-1a",
                                "_widgetType": "htmltag/div",
                                "_stylizer": {
                                    "css": "Widget{background: ^[%.background];}WidgetOBox{opacity: 1; width: 100%; height: 100px; box-sizing: border-box; border: 2px dashed limegreen;}",
                                    "className": "Widget",
                                    "oBoxClassName": "WidgetOBox"
                                }
                            },
                            {
                                "_contextKey": "^[%._contextKey]:Name",
                                "_widgetType": "widget/Name",
                                "firstName": "^[%.firstName]",
                                "middleName": "^[%.middleName]",
                                "lastName": "^[%.lastName]",
                                "title": "^[%.title]",
                                "audioSrc": "^[%.audioSrc]"
                            },
                            {
                                "_contextKey": "^[%._contextKey]:testbox-1b",
                                "_widgetType": "htmltag/div",
                                "_stylizer": {
                                    "css": "Widget{background: green;}Widget{background: green;opacity: 1; width: 100%; height: 200px; box-sizing: border-box; border: 2px dashed yellow;}",
                                    "className": "Widget"
                                }
                            }
                        ]
                    }
                ]
            }
        }
    } as any,
}
