import { IContextVars, IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './LayoutContainerWidgetViewMode';

export const LayoutContainerWidgetType = "widget/LayoutContainer";

export interface ILayoutContainerWidgetVars extends IContextVars { }

export interface ILayoutContainerWidgetLayout extends IWidgetLayout, ILayoutContainerWidgetVars { }
export interface ILayoutContainerWidgetInstance extends IWidgetLayoutTuple<ILayoutContainerWidgetLayout, ILayoutContainerWidgetVars> { }
export interface ILayoutContainerWidgetProps extends IWidgetProps<ILayoutContainerWidgetLayout, ILayoutContainerWidgetVars> { }
export interface ILayoutContainerWidgetSchema extends IWidgetSchema<typeof LayoutContainerWidgetType, ILayoutContainerWidgetProps> { }

export const layoutContainerWidgetSchema: ILayoutContainerWidgetSchema = {
    widgetType: LayoutContainerWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    }
}

