import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './SigninFormWidgetViewMode';

// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./SigninFormWidgetDesignMode'));

export const SigninFormWidgetType = "widget/SigninForm";

export interface ISigninFormWidgetVars {
    // Read / Write to Global Vars
    firstNameVar?: string;
    lastNameVar?: string;
    phoneVar?: string;
    emailVar?: string;
    messageVar?: string;

    verify?: "captcha" | "puzzle";
    submitMethod?: "json" | "query";
    url?: string;
    dataFormat?: string;     // "^[submitURL]?firstName=^[]" or {firstName: "^[.firstNameVar]"}
    //
    requirePhone?: boolean;
    requireName?: boolean;
    requireEmail?: boolean;
    requireMessage?: boolean;
}

export interface ISigninFormWidgetLayout extends IWidgetLayout, ISigninFormWidgetVars { }
export interface ISigninFormWidgetInstance extends IWidgetLayoutTuple<ISigninFormWidgetLayout, ISigninFormWidgetVars> { }
export interface ISigninFormWidgetProps extends IWidgetProps<ISigninFormWidgetLayout, ISigninFormWidgetVars> { }
export interface ISigninFormWidgetSchema extends IWidgetSchema<typeof SigninFormWidgetType, ISigninFormWidgetProps> { }

export const signinFormWidgetSchema: ISigninFormWidgetSchema = {
    widgetType: SigninFormWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
