import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './FieldWidget';
// import sahreModeRenderer from './FieldWidgetRendererShareMode';
import { FieldFactoryField } from '../../components/FieldFactory/FieldFactoryInterface';

// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./FieldWidgetDesignMode'));

export const FieldWidgetType = "widget/Field";

export interface IFieldWidgetVars {
    field: FieldFactoryField | FieldFactoryField[];
}

export interface IFieldWidgetLayout extends IWidgetLayout, IFieldWidgetVars { }
export interface IFieldWidgetInstance extends IWidgetLayoutTuple<IFieldWidgetLayout, IFieldWidgetVars> { }
export interface IFieldWidgetProps extends IWidgetProps<IFieldWidgetLayout, IFieldWidgetVars> {
    updateField?: (field: FieldFactoryField) => boolean; // Return true to stop propogation
}
export interface IFieldWidgetSchema extends IWidgetSchema<typeof FieldWidgetType, IFieldWidgetProps> { }

export const fieldWidgetSchema: IFieldWidgetSchema = {
    widgetType: FieldWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
