import _logger, { LOGGER_CONFIG_DEFAULT } from "../utils/logger";
import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from '@emotion/styled';
import { CardMode } from "../state/cardState";
import { IStylesCache } from "../utils/styleUtils";
import { _contextKey, IWidgetProps, WidgetAction, WidgetActionType, _widgetType, IWidgetLayoutType, IWidgetSchema, IUpdateLayoutArgs, ILayoutChanges } from "./WidgetInterface";
import { hydrateDryLayout, resolveStyleName, newContextKey, resolveClasses, makeElementEventHandlers, makeInstanceInfo, loadWidgetSchemaSync, hydrateLayoutChanges } from "./widgetUtils";
import { IWidgetLayout } from "./WidgetInterface";
import { IPageVars } from "./CardInterface";
import { useAnimationPipeline } from "./useAnimationPipeline";
import { deepEqual } from 'fast-equals';
import { _debug } from "../utils/_debug";
import { HtmlTagWidgetType } from "./HtmlTagWidget/HtmlTagWidget";
import { SnippetWidgetType } from "./SnippetWidget/SnippetWidget";
// import Widget_ShareComponent from "./Widget_ShareComponent";


//=====[ LOGGER ]==================================================================================

const logRender = false;
const logHookExecution = false;

const __filename = "Widget.tsx";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

const InvalidWidget = styled.div`
    background: rgba(255,0,0, 0.75);
    color: black;
    font-weight: bolder;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid black;
`

// const Widget_ShareComponent = lazy(() => import('./Widget_ShareComponent'));

/*
    STOP RETHINKGING IF "HYDRATED LAYOUTS SHOULD BE PASSED DOWN" -- THE ANSWER IS NO!
        Each Widget must call hydrate for it's specific context, exclusing child widgets.
        Hydration logic prunes recusive widgets to reduce processor load. This is required
        to support variables that change, such as during editing, or live variables
        in view mode.
*/

// const WidgetOBox = styled.div<any>`
// `;

// const WidgetIBox = styled.div<any>`
// `;

// const WidgetLoaderError = styled.div<{ cardMode: CardMode }>`
//     ${props => props.cardMode === CardMode.Design ? {
//         background: "#c00",
//         color: "black",
//         padding: "15px",
//     } : {
//         display: "none"
//     }}
// `;

// const ShareWidgetBox = styled.div<any>`
//     padding: 5px 0px 5px 10px;
//     border: 1px dashed gray;
//     box-sizing: border-box;
// `;

/*
    layout is a clone of props.layout (eiher dry or hydtrated), and if dry, may be updated by its editor
    hydratedLayout is what is rendered within the widget context (even if layout is already hydrated)
    dryVars is always provided to enable editing
    hydratedVars if present is generated at each widget context and not passed down
    stylesCache is generated at each widget context and not passed down
*/
/**
 * Widget Component
 * 
 * @param props Layout is a required field. It should always be explicityly hydrated by the widget.
 * @param ref 
 * @returns 
 */
// const Widget: React.FC<IWidgetProps<IWidgetLayout, IPageVars>> = (props) => {
const Widget: React.FC<IWidgetProps<IWidgetLayout, IPageVars>> = (props) => {
    // const [_widgetType, set_widgetType] = useState<_widgetType>(props.layout._widgetType);
    // const [_contextKey, set_contextKey] = useState<_contextKey>(props.layout._contextKey || newContextKey());
    // const [_key] = useState(props.layout._key || props.layout._contextKey || newContextKey());

    // Instance Info
    // const instanceInfoRef = useRef((() => {
    //     const _instanceKey = newContextKey();
    //     const _widgetType = props.layout._widgetType;
    //     const _contextKey = props._contextKey || props.layout._contextKey;
    //     const _id = props._id || props.layout._id || _contextKey;   // Use contextKey if not specific to enable animation targets
    //     const _key = props._key || props.layout._key || _contextKey || _instanceKey;
    //     return ({ _instanceKey, _widgetType, _contextKey, _id, _key });
    // })()); // Init id+key

    // const instanceInfoRef = useRef(makeInstanceInfo(props));
    const instanceInfoRef = useRef<any>();

    const varsRef = useRef<IPageVars | undefined>();            // TODO: FIX DOESN'T RENDER IF SET TO props.vars
    const hydratedVarsRef = useRef<IPageVars | undefined>(); // TODO: FIX DOESN'T RENDER IF SET to props.hydratedVars
    const layoutRef = useRef<IWidgetLayout | undefined>();
    const hydratedLayoutRef = useRef<IWidgetLayout | undefined>();
    const stylesCacheRef = useRef<IStylesCache | undefined>();
    const globalStylesCacheRef = useRef<IStylesCache | undefined>();
    const contentRef = useRef<React.ReactElement | null>(null);

    // const [node, nodeRef] = useNodeRef();
    const childWidgetElementsRef = useRef<JSX.Element[]>();
    const widgetSchemaRef = useRef<IWidgetSchema<any, any> | undefined>();

    // TODO: Remove _contextKey requirement -- change to handle this in the setAnimationPipeline
    const { setAnimationPipeline } = useAnimationPipeline({ _id: props.hydratedLayout?._id || props.layout?._id || props.hydratedLayout?._contextKey || props.layout?._contextKey });  // A specific _id always overrides _contextKey
    // const { setAnimationPipeline } = useAnimationPipeline();  // A specific _id always overrides _contextKey

    // const [forceRenderObj, forceRender] = useState<{}>({}); // Usage: forceRender({});
    const [layoutRev, setLayoutRev] = useState<number>(0);      // Usage: setLayoutRev( rev => (rev + 1));
    const layoutRevRef = useRef(-1);                            // Last layout revision rendered

    // const bumpLayoutRev = useCallback((bubble: boolean = false) => {
    const bumpLayoutRev = (bubble: boolean = false) => {
        setLayoutRev(prev => {
            // logHookExecution && logger.debug(`bumpLayoutRev of [${_contextKey}] to ${prev + 1}`);
            return (prev + 1);
        });
        if (bubble) {
            props.actionDispatcher?.({
                action: "BubbleChanges",
                layout: layoutRef.current,
                vars: varsRef.current,
                // contextVarsMapCollection: varsRef.current?.contextVarsMap,
            });
        }
    };

    /**
     * Process new layout
     * 
     * BUG: % Vars are fully hydrated causing snippet deep hydration
     */
    // const processNewLayout = (updatedLayout?: IWidgetLayout, updatedVars?: IPageVars, bubble: boolean = false) => {
    //     if (updatedLayout || updatedVars) {
    //         const changes = updateLayout({
    //             layoutRef,
    //             hydratedLayoutRef,
    //             varsRef: varsRef,
    //             hydratedVarsRef,
    //             stylesCacheRef,
    //             updatedLayout,
    //             updatedVars,
    //         });
    //         if (changes) {
    //             // instanceInfoRef.current = makeInstanceInfo({ ...props, hydratedLayout: hydratedLayoutRef.current });
    //             instanceInfoRef.current = makeInstanceInfo({
    //                 _id: props._id,
    //                 _key: props._key,
    //                 _contextKey: props._contextKey,
    //                 layout: layoutRef.current,
    //                 hydratedLayout: hydratedLayoutRef.current
    //             });
    //             bumpLayoutRev(bubble);
    //         }
    //         // changes && bumpLayoutRev(bubble);
    //     }
    // }

    const processNewLayout = (updatedLayout: IWidgetLayout | undefined, updatedVars?: IPageVars, bubble: boolean = false): { changes: ILayoutChanges | undefined, content: React.ReactElement | null } => {
        if (updatedLayout || updatedVars) {
            logger.debug(`### PROCESS WIDGET ELEMENT ### ${props.layout._widgetType} @ ${props.layout._contextKey}`);
            // const keyedLayout = seedLayoutKeys(updatedLayout);
            const layoutChangeArgs: IUpdateLayoutArgs = {
                prevVars: varsRef.current,
                prevHydratedVars: hydratedVarsRef.current,
                prevLayout: layoutRef.current,
                prevHydratedLayout: hydratedLayoutRef.current,
                prevStylesCache: stylesCacheRef.current,
                prevGlobalStylesCache: globalStylesCacheRef.current,
                // updatedLayout: keyedLayout,
                updatedLayout,
                updatedVars,
            }
            const changes = hydrateLayoutChanges(layoutChangeArgs);

            // UPDATE THE CHANGES CACHED HERE IN THIS CONTEXT
            if (changes?.varsChanged) varsRef.current = changes.vars;
            if (changes?.hydratedVarsChanged) hydratedVarsRef.current = changes.hydratedVars;
            if (changes?.layoutChanged) layoutRef.current = changes.layout;
            if (changes?.hydratedLayoutChanged) hydratedLayoutRef.current = changes.hydratedLayout;
            if (changes?.stylesChanged) stylesCacheRef.current = changes.stylesCache;
            if (changes?.globalStylesChanged) globalStylesCacheRef.current = changes.globalStylesCache;

            if (changes) {
                // instanceInfoRef.current = makeInstanceInfo({ ...props, hydratedLayout: hydratedLayoutRef.current });
                instanceInfoRef.current = makeInstanceInfo({
                    _id: props._id || hydratedLayoutRef.current?._id || layoutRef.current?._id,
                    _key: props._key || hydratedLayoutRef.current?._key || layoutRef.current?._key,
                    _contextKey: props._contextKey || hydratedLayoutRef.current?._contextKey || layoutRef.current?._contextKey,
                    // layout: layoutRef.current,
                    // hydratedLayout: hydratedLayoutRef.current
                }, props.nextKeyIndex || 0);
                logger.debug(`### GENERATE WIDGET ELEMENT ### ${props.layout._widgetType} @ ${props.layout._contextKey}`);
                const content = renderContent() || null;
                contentRef.current = content;
                bumpLayoutRev(bubble);
                return { changes, content };
            } else {
                return { changes, content: contentRef.current }
            }
        }
        return { changes: undefined, content: null };
    }

    //
    // The layout or vars that were passed in by the parent are new or changed -- hydrate and bump the layoutRev
    //
    // useEffect(() => {
    //     // Only process subsequent updates (not the first)
    //     if (layoutRev > 0) {
    //         logger.debug(`### UPDATE WIDGET ELEMENT ### ${props.layout._widgetType} @ ${props.layout._contextKey}`);
    //         processNewLayout(props.layout, props.vars);
    //     }
    // }, [props]);


    /*********************************************************************************************************/
    /*************************                                                     ***************************/
    /*************************                                                     ***************************/
    /*************************   EVERYTHING BELOW SHOULD USE LAYOUT/HYLAYOUT REF   ***************************/
    /*************************                                                     ***************************/
    /*************************                                                     ***************************/
    /*********************************************************************************************************/


    //
    // Create Child Widgets
    //
    // const [newChildrenFlag, setNewChildrenFlag] = useState<{}>({}); // Usage: setNewChildrenFlag({});
    const renderChildren = (done?: (children?: JSX.Element[]) => void) => {
        //
        // Update Child Elements
        //
        // const layout = props.layout;
        // const vars = props.vars;
        // const stylesCache = props.stylesCache;
        const contextKeysInScope: string[] = [];
        const contextKeyConflicts: string[] = [];
        const widgets: IWidgetLayoutType | undefined = hydratedLayoutRef.current?._widgets || layoutRef.current?._widgets;
        // const widgets: IWidgetLayoutArray | undefined = props.layout?._widgets;
        const childWidgetElements: Array<JSX.Element> = [];

        // logHookExecution && logger.debug(`[${props.layout?._contextKey}] useEffect regenerate childWidgetElements`);
        logHookExecution && logger.debug(`[${hydratedLayoutRef.current?._contextKey}] useEffect regenerate childWidgetElements`);

        // Update the layout array (update, add, remove widgets)
        let nextIndexKey = props.nextKeyIndex || 0;
        Array.isArray(widgets) && widgets.forEach((childLayout: IWidgetLayout | string, index: number) => {
            if (typeof childLayout === "object") {
                // let childLayout: IWidgetLayout;
                // childLayout = roChildLayout;

                // if (roChildLayout._contextKey) {
                //     childLayout = roChildLayout;
                // } else {
                //     childLayout = cloneDeep(roChildLayout);
                //     childLayout._contextKey = newContextKey();
                // }

                // Ensure a unique key for every element in this scope
                const keyConflict = childLayout._contextKey ? contextKeysInScope.includes(childLayout._contextKey) : false;
                keyConflict && contextKeyConflicts.push(childLayout._contextKey!);
                // const childContextKey = keyConflict ? newContextKey() : (childLayout._contextKey || newContextKey());
                childLayout._contextKey && contextKeysInScope.push(childLayout._contextKey);

                // Find existing element where all properties match
                let element: JSX.Element | undefined = childWidgetElementsRef.current?.find((element: JSX.Element, index) => {
                    if (element.key !== childLayout._key) {
                        return false
                    };

                    //// TODO: CHECK NEXT LINE???

                    const layoutChanged = !deepEqual(childLayout, element.props.layout);
                    let varsChanged = !deepEqual(props.vars, element.props.vars);
                    // const stylesCacheChanged = !deepEqual(stylesCache?.serializedStylesString, element?.props?.stylesCache?.serializedStylesString);
                    // if (element?.key !== childLayout._contextKey || props.cardMode !== element.props.cardMode || layoutChanged || varsChanged || stylesCacheChanged) {
                    if (element?.key !== (childLayout._key || childLayout._contextKey) || props.cardMode !== element.props.cardMode || layoutChanged || varsChanged) {
                        return false; // Something changed, re-render the element
                    } else {
                        logHookExecution && logger.debug(`[${hydratedLayoutRef.current?._contextKey}] REUSE CHILD ELEMENT: ${childLayout._contextKey}`);
                        return true;
                    }
                });

                if (!element) {
                    // logHookExecution && logger.debug(`[${props.layout._contextKey}] CREATE NEW CHILD ELEMENT: ${childLayout._contextKey}`);
                    logHookExecution && logger.debug(`[${hydratedLayoutRef.current?._contextKey}] CREATE NEW CHILD ELEMENT: ${childLayout._contextKey}`);

                    // It wasn't found, or something changed -- build a new widget element
                    // IN THIS CHILD INSTANCE ONLY, only pass the layout and vars so the widget can hydrate itself

                    // const { _widgets, ..._parentVars } = props.hydratedLayout || props.layout;






                    // HYDRATED OR DRY?
                    // const { _widgets, ..._parentVars }: any = hydratedLayoutRef.current || layoutRef.current;
                    const { _widgets, ..._parentVars }: any = layoutRef.current;





                    const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = {
                        // cardMode: props.cardMode,
                        cardMode: props.cardMode,

                        // TODO: FIX KEY THIS TO PREVENT CONSTANT RE-RENDERING!!!
                        // key: `_${instanceInfoRef.current._key}_${childLayout._key || newInstanceKey(childLayout._contextKey)}`,
                        _key: childLayout._contextKey || `${index}_${childLayout._widgetType}`,  // Best effort

                        layout: childLayout,
                        _parentVars,
                        nextKeyIndex: nextIndexKey++,
                        vars: props.vars,
                        renderChildren,
                        actionDispatcher,
                    };
                    element = <Widget {...propsUnion} key={propsUnion._key} />      // Set key = _key
                }

                contextKeyConflicts.length > 0 && logger.error(`_contxtKey conflicts within scope`, contextKeyConflicts); // TODO: Surface this error in GUI
                element && childWidgetElements.push(element);
            }
        });

        childWidgetElementsRef.current = childWidgetElements.length > 0 ? childWidgetElements : undefined;
        // setNewChildrenFlag({});
        done?.(childWidgetElementsRef.current);
    }

    // useEffect(() => {
    //     try {
    //         const widgetTypeParts = _widgetType.split("/");
    //         let wType: string;
    //         switch (widgetTypeParts[0]) {
    //             case "htmltag":
    //                 // Handle shorthand widget types (htmltag/div, htmltag/p, etc.)
    //                 wType = HtmlTagWidgetType;
    //                 break;
    //             case "snippet":
    //                 // Handle shorthand widget types (htmltag/div, htmltag/p, etc.)
    //                 wType = SnippetWidgetType;
    //                 break;
    //             default:
    //                 wType = _widgetType;
    //                 break;
    //         }

    //         loadWidgetSchema(wType).then((schema) => {
    //             setWidgetSchema(schema)
    //         });
    //     } catch (e) {
    //         logger.error(`Unsupported widget type: ${_widgetType}`);
    //         setWidgetSchema(undefined);
    //     }

    // }, [_widgetType]);

    //
    // Hydrate and render the widget
    //
    const renderContent = () => {
        //
        // Render the current hydrated layout revision
        //
        const render = (schema?: IWidgetSchema<any, any>) => {
            // //
            // // Done creating children (or none)
            // // Render the widget to the DOM
            // //
            // const done = (children?: JSX.Element[]) => {

            // if (typeof schema === "object" && typeof schema.component === "function") {
            if (schema?.viewModeSchema?.component) {
                switch (props.cardMode) {
                    /**
                     * ======================== VIEW MODE ========================
                     */
                    case CardMode.Configure:
                    case CardMode.Share:
                    case CardMode.SiteView:
                    case CardMode.View:
                    default:
                        {
                            const style = hydratedLayoutRef.current?._stylizer?.style;
                            const iBoxStyle = hydratedLayoutRef.current?._stylizer?.iBoxStyle;
                            const oBoxStyle = hydratedLayoutRef.current?._stylizer?.oBoxStyle;
                            const className = resolveClasses(hydratedLayoutRef.current?._stylizer?.className, stylesCacheRef.current) || undefined; // If no className, fallback to try Widget{} style for backward compatiblity
                            const iBoxClassName = resolveClasses(hydratedLayoutRef.current?._stylizer?.iBoxClassName, stylesCacheRef.current) || undefined; // If no className, fallback to try Widget{} style for backward compatiblity
                            const oBoxClassName = resolveClasses(hydratedLayoutRef.current?._stylizer?.oBoxClassName, stylesCacheRef.current) || undefined; // If no className, fallback to try Widget{} style for backward compatiblity

                            //
                            // Render widget
                            //
                            let eventHandlers = makeElementEventHandlers(hydratedLayoutRef.current?._eventActions, actionDispatcher);
                            eventHandlers && logger.warn(`event handlers = ${Object.keys(eventHandlers)}`);

                            // HYDRATED OR DRY?
                            // const { _widgets, ..._parentVars }: any = hydratedLayoutRef.current || layoutRef.current;
                            const { _widgets, ..._parentVars }: any = layoutRef.current;

                            const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = {
                                cardMode: props.cardMode,
                                layout: layoutRef.current,              // Passed down for upstream changes
                                vars: varsRef.current,                  // Passed down for upstream changes
                                _parentVars,
                                // nextKeyIndex: nextKeyIndexRef.current++,
                                stylesCache: stylesCacheRef.current,
                                hydratedLayout: hydratedLayoutRef.current,
                                hydratedVars: hydratedVarsRef.current,
                                ...(!iBoxClassName && !oBoxClassName && { _id: instanceInfoRef.current._id, _key: instanceInfoRef.current._key }),
                                ...((props._style || style) && { _style: props._style || style }),
                                ...((props._class || className) && { _class: props._class ? (props._class + " ") : "" + className }),
                                ...(!iBoxClassName && !oBoxClassName && { _eventHandlers: !oBoxClassName && eventHandlers }),

                                // TODO: Resolve _style, _class, _eventHandlers to _tagAttributes for spreadding within the widget?

                                resolveStyleName,
                                hydrateDryLayout,
                                renderChildren,
                                actionDispatcher,
                            } as any;

                            //
                            // Create the appropriate instance of widget (view, share, etc)
                            //
                            // const Widget = schema.viewSchema?.component || schema.component;
                            const Widget = schema.viewModeSchema?.component;
                            const widget = Widget ? <Widget {...propsUnion} /> : null;

                            //
                            // Render iBox wrapper if defined in layout _css
                            //
                            const iBox = iBoxClassName
                                ? <div {...{
                                    className: iBoxClassName,
                                    ...(iBoxStyle && { STYLE: iBoxStyle }),
                                    ...(!oBoxClassName && { id: instanceInfoRef.current._id, key: instanceInfoRef.current._key }),
                                    ...(!oBoxClassName && eventHandlers),
                                    children: widget,
                                }} />
                                : widget;

                            //
                            // Render oBox wrapper if defined in layout _css
                            //
                            const oBox = oBoxClassName
                                ? <div {...{
                                    className: oBoxClassName,
                                    ...(oBoxStyle && { STYLE: oBoxStyle }),
                                    id: instanceInfoRef.current._id,
                                    key: instanceInfoRef.current._key,
                                    children: iBox,
                                    ...eventHandlers,
                                }} />
                                : iBox;
                            // : <div key={key} children={iBox} />;

                            // ***********************************************************************************************
                            // ***********************************************************************************************
                            // ********************                         
                            // ******************** TODO: FIX THIS WORKAROUND!!!
                            // ********************                          
                            // ******************** The animation pipline cannot be started until respective elements from
                            // ******************** the layout file have been rendered. KICK OFF THE ANIMATION PIPELINE AS                          
                            // ******************** elements become available, or upon first complete render.                          
                            // ********************                          
                            // ***********************************************************************************************
                            // ***********************************************************************************************
                            // TODO: Associate with render event (not 1000ms)

                            // const animationPipeline = props.cardMode === CardMode.View && hydratedLayoutRef.current?._stylizer?.pipeline || undefined;
                            const animationPipeline = hydratedLayoutRef.current?._stylizer?.pipeline || undefined;
                            animationPipeline && setTimeout(() => {
                                if (hydratedLayoutRef.current?._stylizer?.pipeline) {
                                    setAnimationPipeline(hydratedLayoutRef.current?._stylizer.pipeline, instanceInfoRef.current?._id);
                                    // logger.error(`setAnimationPipeline for ${props.layout._contextKey}`);
                                }
                            }, 500);

                            //
                            // Set the content and attach the animation
                            //
                            // (process.env.NODE_ENV === 'development' && _debug) && logger.debug(`renderContent(), schema = ${schema.widgetType}, key = ${instanceInfoRef.current._key}`);
                            return oBox;

                            // const animationPipeline = props.cardMode === CardMode.View && hydratedLayoutRef.current?._stylizer?.pipeline || undefined;
                            // animationPipeline && setTimeout(() => {
                            //     if (hydratedLayoutRef.current?._stylizer?.pipeline) {
                            //         // setAnimationPipeline(hydratedLayoutRef.current?._stylizer.pipeline);
                            //         setAnimationPipeline(animationPipeline);
                            //     }
                            // }, 1000);
                            break;
                        }

                    /**
                     * ======================== SHARE MODE ========================
                     */
                    // case CardMode.Share: {
                    //     //
                    //     // Render widget
                    //     //
                    //     const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = deleteUndefinedKeys({
                    //         _id: instanceInfoRef.current._id,
                    //         _key: instanceInfoRef.current._key,
                    //         cardMode: props.cardMode,
                    //         layout: layoutRef.current,
                    //         vars: varsRef.current,
                    //         stylesCache: stylesCacheRef.current,
                    //         hydratedLayout: hydratedLayoutRef.current,
                    //         hydratedVars: hydratedVarsRef.current,
                    //         resolveStyleName,
                    //         hydrateLayout,
                    //         renderChildren,
                    //         actionDispatcher,
                    //     }) as any;

                    //     //
                    //     // Create the appropriate instance of widget (view, share, etc)
                    //     //
                    //     const widget = <Widget_ShareComponent widgetProps={propsUnion} widgetSchema={schema} />
                    //     logger.debug(`widget`);
                    //     // const Widget = schema.shareSchema?.LazyComponent;
                    //     // const widget = Widget
                    //     //     ? <ShareWidgetBox>
                    //     //         {layoutRef.current?._widgetType}
                    //     //         <> [ {layoutRef.current?._contextKey} ]</>
                    //     //         <br />
                    //     //         <Widget {...propsUnion} />
                    //     //     </ShareWidgetBox>
                    //     //     : null;
                    //     // const widget = Widget ? <Widget {...propsUnion} /> : null;

                    //     //
                    //     // Set the content and attach the animation
                    //     //
                    //     (process.env.NODE_ENV === 'development' && _debug) && logger.debug(`renderContent(), schema = ${schema.widgetType}, key = ${instanceInfoRef.current._key}`);
                    //     setWidgetContent(widget);
                    //     break;
                    // }

                    // default:
                    //     setWidgetContent(null);
                    //     break;
                }

            } else {
                // setWidgetContent(null);
                // TODO: Append this to an card-level error list to render at bottom
                const wc = (
                    props.cardMode === CardMode.Design || (process.env.NODE_ENV === 'development')
                        ? <InvalidWidget key={newContextKey()}>INVALID WIDGET MODULE: {props.layout._widgetType} {props.layout._contextKey ? `[${props.layout._contextKey}]` : ""}</InvalidWidget>
                        : null
                );
                return wc;
            }

            // }
            // // Build child widgets if they are supported by this widget
            // // schema.children ? renderChildren(done) : done();
            // done();
        }

        // ------------------------------------------------------------------------------------------------------------

        //
        // Render Content (main function)
        //
        (process.env.NODE_ENV === 'development' && _debug) && _debug(props.layout?._debug, logger, props.layout);

        // Ignore this widget?
        if (hydratedLayoutRef.current?._ignore
            || hydratedLayoutRef.current?._criteria?.render === false) return null;
        // if (layoutRev === 0) return;    // Ignore initial state  --- CHANGED

        try {
            const _widgetType = hydratedLayoutRef.current?._widgetType;
            if (!widgetSchemaRef.current) {
                // Load new widget type
                const widgetTypeParts = _widgetType?.split("/");
                if (_widgetType && widgetTypeParts) {
                    let wType: string;
                    switch (widgetTypeParts[0]) {
                        /**
                         * SHORTHAND HANDLERS
                         */
                        case "htmltag":
                            // Handle shorthand widget types (htmltag/div, htmltag/p, etc.)
                            wType = HtmlTagWidgetType;
                            break;
                        case "snippet":
                            // Handle shorthand widget types (snippet/SNIPPET_NAME)
                            wType = SnippetWidgetType;
                            break;
                        /**
                         * ALL OTHER WIDGETS
                         */
                        default:
                            wType = _widgetType;
                            break;
                    }

                    const schema = loadWidgetSchemaSync(wType);
                    widgetSchemaRef.current = schema;
                    return render(schema);
                } else {
                    // No widgetType, clear the widgetSchema
                    widgetSchemaRef.current = undefined;
                    return render(widgetSchemaRef.current);
                }
            } else {
                return render(widgetSchemaRef.current);
            }
        } catch (e) {
            logger.error(e);
            // logger.error(`Unsupported widget type: ${hydratedLayoutRef.current?._widgetType}`);
            widgetSchemaRef.current = undefined;
            return render(widgetSchemaRef.current);
        }
    }
    // , [layoutRev, props.cardMode]);
    // }, [layoutRev]);

    // --------------------------------------------------------------------------------------------
    // ---------- ACTION DISPATCHER ---------------------------------------------------------------
    // ----------

    // TODO: Do we need to defer (any/all) actions until root context?
    const actionDispatcher = props.actionDispatcher;
    // const actionQueue = useRef<WidgetAction[]>([]);
    // const [processActionQueue, setProcessActionQueue] = useState<{}>(); // Usage: setProcessActionQueue({});
    // const actionDispatcher = useCallback((action?: WidgetAction, deferAction: boolean = false) => {
    //     const _contextKey = hydratedLayoutRef.current?._contextKey;

    //     const doAction = (action: WidgetAction) => {
    //         logHookExecution && logger.debug(`[${_contextKey}] doAction ${action?.action}`);
    //         switch (action?.action) {
    //             // case "NavigateToPage":
    //             //     window.location.href = `${hydratedVarsRef.current?.cardLocation}/${action.pagePath}`;
    //             //     break;

    //             // case "BubbleChanges":
    //             //     //    
    //             //     // TODO: Update child layout within this (parent) array of widgets
    //             //     // CURRENT: For now, only handle var changes by passing up
    //             //     //
    //             //     break;

    //             // case "Refresh":
    //             //     layoutRef.current = hydrateLayout(layoutRef.current || props.layout, varsRef.current || props.vars);
    //             //     bumpLayoutRev(true);
    //             //     break;

    //             // case "UpdateLayout":
    //             //     const updateLayoutArgs: IUpdateLayoutArgs = {
    //             //         layoutRef,
    //             //         hydratedLayoutRef,
    //             //         varsRef,
    //             //         hydratedVarsRef,
    //             //         stylesCacheRef,
    //             //         ...("layout" in action && { updatedLayout: action.layout }),
    //             //         ...("vars" in action && { updatedVars: action.vars }),
    //             //     };
    //             //     const changes = updateLayout(updateLayoutArgs);
    //             //     if (changes) {
    //             //         bumpLayoutRev(true);
    //             //     }
    //             //     break;

    //             default:
    //                 // Bubble everything else to parent
    //                 props.actionDispatcher?.(action);
    //                 break;
    //         }
    //     }

    //     // Push new action and then either defer or process queue now
    //     action && actionQueue.current.push(action);
    //     if (deferAction) {
    //         // Defer or process pending queue
    //         logHookExecution && logger.debug(`[${_contextKey}] defer doAction ${action?.action}`);
    //         setProcessActionQueue({});
    //     } else {
    //         // Process all pending actions
    //         // logHookExecution && logger.debug(`[${_contextKey}] process actionQueue[${actionQueue.current.length}]`);
    //         while (actionQueue.current.length > 0) {
    //             const action = actionQueue.current.shift();
    //             action && doAction(action);
    //         }
    //     }
    // }, [props.actionDispatcher, setProcessActionQueue]);

    // Process pending actions
    // useEffect(() => {
    //     const _contextKey = hydratedLayoutRef.current?._contextKey;
    //     logHookExecution && logger.debug(`[${_contextKey}] useEffect processActionQueue`);
    //     actionDispatcher();
    // }, [processActionQueue]);

    // ----------
    // ---------- ACTION DISPATCHER ---------------------------------------------------------------
    // --------------------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------------------
    // ---------- RENDER
    // ----------

    // Only run once inline
    // const { content } = !contentRef.current ? processNewLayout(props.layout, props.vars) : { content: contentRef.current };
    // const content = contentRef.current || renderContent();
    // if (layoutRev <= 0) {
    //     contentRef.current = `
    // }

    useEffect(() => {
        const changes = processNewLayout(props.layout, props.vars);
    }, [props.layout, props.vars])

    // Run SSR once and then again on Client-Side
    let content: React.ReactNode | null;
    if (layoutRev != layoutRevRef.current) {
        logger.debug(`Widget ### PROCESS NEW LAYOUT/VARS RE-RENDER CONTENT ### Rev ${props.layout._widgetType} @ ${props.layout._contextKey}, Rev ${layoutRev}`);
        if (layoutRev === 0) {
            const changes = processNewLayout(props.layout, props.vars);
        }
        content = renderContent() || null;
        contentRef.current = content;
        layoutRevRef.current = layoutRev;
    } else {
        content = contentRef.current;
    }


    // logRender && logger.debug(`[${props.layout._contextKey}] ### RENDER ### instance, props, content`, instanceInfoRef.current._key, props, widgetContent);
    logger.debug(`Widget  ### RENDER ### ${props.layout._widgetType} @ ${props.layout._contextKey}, Rev ${layoutRev}`);
    // return <>{content}</>;
    return <>
        {/* Widget <div style={{color: "red", borderSpacing: "border-box", border: "2px dashed red"}}>{props._key}</div> */}
        {content}
    </>

    // ----------
    // ---------- RENDER
    // --------------------------------------------------------------------------------------------
}

export default Widget;