import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './SignupFormWidgetViewMode';

// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./SignupFormWidgetDesignMode'));

export const SignupFormWidgetType = "widget/SignupForm";

export interface ISignupFormWidgetVars {
    // Read / Write to Global Vars
    firstNameVar?: string;
    lastNameVar?: string;
    phoneVar?: string;
    emailVar?: string;
    messageVar?: string;

    verify?: "captcha" | "puzzle";
    submitMethod?: "json" | "query";
    url?: string;
    dataFormat?: string;     // "^[submitURL]?firstName=^[]" or {firstName: "^[.firstNameVar]"}
    //
    requirePhone?: boolean;
    requireName?: boolean;
    requireEmail?: boolean;
    requireMessage?: boolean;
}

export interface ISignupFormWidgetLayout extends IWidgetLayout, ISignupFormWidgetVars { }
export interface ISignupFormWidgetInstance extends IWidgetLayoutTuple<ISignupFormWidgetLayout, ISignupFormWidgetVars> { }
export interface ISignupFormWidgetProps extends IWidgetProps<ISignupFormWidgetLayout, ISignupFormWidgetVars> { }
export interface ISignupFormWidgetSchema extends IWidgetSchema<typeof SignupFormWidgetType, ISignupFormWidgetProps> { }

export const signupFormWidgetSchema: ISignupFormWidgetSchema = {
    widgetType: SignupFormWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
