import { parse } from "flatted";
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";

export const pxString = (pixels: number): string => `${pixels}px`;
export const imageOffsetPercent = (fullSize: number, offset: number, symbol: boolean = true) => `${offset / fullSize * 100}${symbol ? "%" : ""}`;

export const getAsset = async (url: string): Promise<any> => {
    let asset: any = null;

    try {
        const response = await axios.get<any>(url, { responseType: 'json' });
        asset = response.data;
        // asset = new Buffer(response.data, 'binary').toString();
    } catch (err) {
        console.error("### loadJSONAsset Error = ", err);
    }

    return asset;
};

export const getJSONAsset = async (url: string): Promise<any> => {
    let config: any = null;

    try {
        const response = await axios.get<any>(url, { responseType: 'arraybuffer' });
        const buffer = new Buffer(response.data, 'binary').toString();
        config = parse(buffer);
    } catch (err) {
        console.error("### loadJSONAsset Error = ", err);
    }

    return config;
};

export const doQuery = async (url: string, query: string, method: Method = 'post', withCookies: boolean = false, bearerToken: string | undefined = undefined): Promise<AxiosResponse> => {
    const config: AxiosRequestConfig = {
        method,
        url,
        data: { query },
        withCredentials: withCookies,      // Pass and receive cookies
    };
    // config.headers = {
    //     'Access-Control-Allow-Origin': '*',
    //     // Authorization: `bearer ${bearerToken}`
    // }

    if (bearerToken) {
        config.headers = {
            // 'Access-Control-Allow-Origin': '*',
            Authorization: `bearer ${bearerToken}`
        }
    }
    const resp: AxiosResponse<any> = await axios(config);
    return resp;
}

export const getQuery = async (url: string, query: string, withCookies: boolean = false, bearerToken: string | undefined = undefined) => {
    return doQuery(url, query, "get", withCookies, bearerToken);
}

export const postQuery = async (url: string, query: string, withCookies: boolean = false, bearerToken: string | undefined = undefined) => {
    return doQuery(url, query, "post", withCookies, bearerToken);
}

export const asyncTimeout = (ms: number): Promise<number> => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const EmptyRect = { top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0, x: 0, y: 0 };

export const DomRectToObject = (domRect: any = EmptyRect) => ({
    top: domRect.top,
    right: domRect.right,
    bottom: domRect.bottom,
    left: domRect.left,
    width: domRect.width,
    height: domRect.height,
    x: domRect.x,
    y: domRect.y,
});

export const RgbaString = (rgb: number[], opacity: number) => `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;

export const getBrowserInfo = () => {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    var OSName = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") !== -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") !== -1) OSName = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
    if (navigator.userAgent.indexOf("Android") !== -1) OSName = "Android";
    if (navigator.userAgent.indexOf("like Mac") !== -1) OSName = "iOS";

    return {
        browserName,
        fullVersion,
        majorVersion,
        browserCodeName: navigator.appCodeName,
        userAgent: navigator.userAgent,
        appVersion: nVer,
        OSName,
        platform: navigator.platform,
        cookiesEnabled: navigator.cookieEnabled
    }
}

/**
 * Truncate center of string with elipsis (preseve prefix and suffix)
 * 
 * @param str 
 * @param maxLength 
 * @returns 
 */
export const truncateCenter = (str: string, maxLength: number) => {
    // const regEx1 = /(\+?.{4})(\d+)(.{3})/g;
    // const regEx2 = /(\+?.{11})(.+)(.{4})/g;
    // var quotient = Math.floor(maxLength / 2);
    // var remainder = maxLength % 2;
    const firstLen = Math.floor((maxLength - 1) * 0.75);
    const lastLen = (maxLength - 1) - firstLen;
    const regExString = `(\\+?.{${firstLen}})(.+)(.{${lastLen}})`;
    console.debug(`regExString = ${regExString}`);
    const regEx = new RegExp(regExString, "g");
    const match = regEx.exec(str);
    return match ? `${match[1]}…${match[3]}` : (str.substring(0, (maxLength - 1)) + '…');
}

// export const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

/**
 * Platform and Standalone (INSTALLED AS PWA)
 *
 * REF: https://thomashunter.name/posts/2021-12-11-detecting-if-pwa-twa-is-installed
 * REF2: https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
 *
 */
// const UA = navigator.userAgent;
// const IOS = UA.match(/iPhone|iPad|iPod/);
// const ANDROID = UA.match(/Android/);
// export const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : 'unknown';
// const standalone = window.matchMedia('(display-mode: standalone)').matches;
// export const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)));
// export const isStandalone = () => INSTALLED;
