import React, { useRef } from 'react';
import { defaultProduct } from '../../catalog/defaultCatalogProduct';
import { loadSnippet } from '../widgetUtils';
import Widget from '../Widget';
import { ISnippet, ISnippetWidgetProps, SnippetWidgetType } from './SnippetWidget';

// let keyedSnippets: ISnippetMap | undefined;

const Snippet_WidgetView: React.FC<ISnippetWidgetProps> = (props) => {
    const contentRef = useRef<React.ReactElement | null>(null);

    function renderContent() {
        // keyedSnippets = keyedSnippets || seedSnippetMapKeys(snippets as unknown as ISnippetMap) as ISnippetMap;
        // Resolve snippet name from widgetType slash-shorthand, or property
        const snippetName = ((props.hydratedLayout?._widgetType === SnippetWidgetType)
            ? ((props.hydratedLayout || props.layout)._snippetName)
            : (props.hydratedLayout || props.layout)._widgetType?.split("/")[1]);

        // Load the snippet
        // const snippet: ISnippet = keyedSnippets?.[snippetName || ""];
        const snippet: ISnippet | undefined = snippetName ? loadSnippet(snippetName, defaultProduct.snippetMap) : undefined;

        // Hydrate the snippet
        if (snippet?.layout) {

            /**
             * 
             * TODO:
             * 
             * Try adapting the hydrator to only hydrate the snippets themselves, possibly through
             * a include/excluse key, or snippet indicator, or max hydration depth.
             * 
             * It seems each object should be evaluated as a snippet and hydrate the widgets if
             * a snippet type?
             * 
             * Is the problem that we lose context of the hydration variables. Pass those down
             * and hydrate within context? Use pinned context. Snapshot snippet vars and pass
             * inside of layout object as hidden value?
             * 
             */

            // const hydratedLayout: IWidgetLayout = props.hydrateLayout?.(snippetLayout, snippetVars, true, true) as IWidgetLayout;

            /**
             * Technique 1
             * 
             * Walk obj and convert _widgets to __widgets__, then convert back to _widgets
             */

            /**
             * Technique 2
             * 
             * Implement % snippet vars hydration stack for later JIT Hydration
             */

            // const hydratedLayout: IWidgetLayout = props.hydrateLayout?.(snippetLayout, snippetVars, false, true) as IWidgetLayout;


            const vars: any = { ...props.vars, "%": props.hydratedLayout };
            const layout: any = snippet.layout;

            // const hydratedSnippet = props.hydrateDryLayout?.(snippet, props.hydratedVars);
            // const hydratedVars: any = { ...props.hydratedVars, "%": hydratedSnippet };
            // const hydratedLayout: any = snippet.layout;

            const propsUnion = {
                key: props._key || props.hydratedLayout?._key || props.hydratedLayout?._contextKey || props.layout._key || props.layout._contextKey,
                cardMode: props.cardMode,
                ...(props._class ? { _class: props._class } : undefined),
                vars,
                layout,
                // hydratedVars,
                // hydratedLayout,
                resolveStyleName: props.resolveStyleName,
                hydrateDryLayout: props.hydrateDryLayout,
                actionDispatcher: props.actionDispatcher,
            };

            return (propsUnion ? <Widget {...propsUnion} /> : null);
        } else {
            return null;
        }
    }
    // }, [props.hydratedLayout, props.hydratedVars, props.cardMode]);

    // SSR render on first pass, otherwise use contentRef that may be updated bu hooks
    let content: React.ReactElement | null = contentRef.current || renderContent() || null;
    return content;
};

export default Snippet_WidgetView;