import { HtmlHTMLAttributes } from 'react';
import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './HtmlTagWidgetViewMode';
// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./HtmlTagWidgetDesignMode'));

export const HtmlTagWidgetType = "widget/HtmlTag";

export interface IHtmlTagWidgetVars {
    tag?: string;
    tagAttributes?: HtmlHTMLAttributes<any>;
}

export interface IHtmlTagWidgetLayout extends IWidgetLayout, IHtmlTagWidgetVars { }
export interface IHtmlTagWidgetInstance extends IWidgetLayoutTuple<IHtmlTagWidgetLayout, IHtmlTagWidgetVars> { }
export interface IHtmlTagWidgetProps extends IWidgetProps<IHtmlTagWidgetLayout, IHtmlTagWidgetVars> { }
export interface IHtmlTagWidgetSchema extends IWidgetSchema<typeof HtmlTagWidgetType, IHtmlTagWidgetProps> { }

export const htmltagWidgetSchema: IHtmlTagWidgetSchema = {
    widgetType: HtmlTagWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
