import { CardMode } from "../../state/cardState";
import { ValidationTest, ValidationResult } from "../../utils/validator";
import { IWidgetLayout, _contextKey } from "../../widgets/WidgetInterface";

export enum FieldFactoryFieldType {
    Label = "Label",
    Divider = "Divider",
    StylesString = "StylesString",
    String = "String",
    Text = "Text",
    Checkbox = "Checkbox",
    Url = "Url",
    Email = "Email",
    Phone = "Phone",
    Asset = "Asset",
    Number = "Number",
    Slider = "Slider",
    Border = "Border",
    CssBox = "CssBox",        // Css box used for Margin, Padding , etc. (Top, Right, Bottom, Left)
    RadioButton = "RadioButton",
    Date = "Date",
    DateRange = "DateRange",
    Choice = "Choice",
    Array = "Array",
    //
    Spacer = "Spacer",
    Widget = "Widget",
}

export interface IFFFieldHelpInfo {
    helpKey?: string;
    overlay?: any;
    hint?: string;
    simpleDescription?: string;
    detailDescription?: string;
}

// /**
//  * IValidationSchmea is designed to use Validator.JS (https://github.com/validatorjs/validator.js)
//  * The Validator Schmea uses keys to define the function to be run and the object of that key are the options
//  * Key preceded by a ! (not) or !! (double-not), will be applied to the validation result.
//  * The function is called as:  [!|!!] validator(str, [options]) => boolean
//  */
// export interface IValidationSchema {
//     [validator: string]: {          // key is validator function name: value is options object passed to validator funciton
//         [key: string]: any;         // Match options as defined in Validator.JS (after sanitization to prevent code execution)
//         regExp?: string;            // A regular expression string (regExp.test())
//         errorMessage?: string;      // Message passed back upon failed validation
//     }
//     // NOTE: if a validator (function name) is preceded by a not ! (or double-not !!), it will be applied to the validation result.
// }

// export interface IValidationResult {
//     isValid: boolean;
//     message?: string;
// }

interface IFieldFactoryFieldBase {
    // _contextKey?: _contextKey;
    fieldType: FieldFactoryFieldType;
    fieldKey?: string;
    disabled?: boolean;
    label?: string;
    placewholder?: any;
    heading?: string;               // Section Heading
    varPath?: string;
    value?: any;
    default?: any;      // If a default value us mandatory
    excludeVarValue?: any;      // If this value is matched, remove it from the vars
    description?: string;
    helperText?: string;
    helpInfo?: IFFFieldHelpInfo;
    //
    // required?: boolean;
    liveValidation?: boolean;
    validationTest?: ValidationTest;    // validator.js statements (https://github.com/validatorjs/validator.js)
    validateFn?: (value: string) => ValidationResult;  // !!!INTERNAL SYSTEM USE ONLY!!!
    validationResult?: ValidationResult;
    //
    focused?: boolean;
    touched?: boolean;
    changed?: boolean;

    // validate?: (field: IWidgetEditorField, value: any) => Error;
    // childrenFields?: IFieldFactoryFields;
}
// Validator.js Statement: functionName, options

export type IFieldFactoryCollection = FieldFactoryField[];

export interface IFieldFactoryLabelField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Label,
};

export interface IFieldFactoryDividerField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Divider,
    lineStyle?: "solid" | "double_line" | "dotted" | "dashed",
};

export interface IFieldFactoryStylesStringField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.StylesString,
};

export interface IFieldFactoryStringField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.String,
    trim?: boolean;
};

export interface IFieldFactoryTextField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Text,
    trim?: boolean;
};

export interface IFieldFactoryCheckboxField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Checkbox,
};

export interface IFieldFactoryUrlField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Url,
};

export interface IFieldFactoryEmailField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Email,
};

export interface IFieldFactoryPhoneField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Phone,
};

export interface IFieldFactoryAssetField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Asset,
};

export interface IFieldFactoryRadioButtonField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.RadioButton,
    group: string;
};

export interface IFieldFactoryNumberField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Number,
    min?: number;
    max?: number;
}

export interface IFieldFactorySliderField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Slider,
    min?: number;
    max?: number;
    step?: number;
    scale?: (value: number) => number | number;
    noAbsoluteZeros?: boolean;
}

export interface IFieldFactoryBorderField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Border,
    color?: string;
    style?: "none" | "dotted" | "dashed" | "solid" | "groove" | "inset";
    boxSizing?: "content-box" | "border-box";
    styleString?: string;       // If present, use this and ignore individual values
}

export interface IFieldFactoryCssBoxField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.CssBox,
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    important?: boolean;
    styleString?: string;       // If present, use this and ignore individual values
}

export interface IFieldFactoryDateField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Date,
    incTime: boolean;
}

export interface IFieldFactoryDateRangeField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.DateRange,
    incTime: boolean;
}

export interface IFieldFactoryChoiceField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Choice,
    multiple: boolean;  // True if multi-choice (more than one is possible)
    choices: {
        label: string,
        value: any,
    }[];
}

export interface IFieldFactoryArrayField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Array,
    elementType: FieldFactoryField;
    minCount: number;
    maxCount: number;
    // value: string | string[];
}

export interface IFieldFactorySpacerField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Spacer,
    space: string;
};

export interface IFieldFactoryWidgetField extends IFieldFactoryFieldBase {
    fieldType: FieldFactoryFieldType.Widget,
    layout: IWidgetLayout;
    cardMode: CardMode;
};

export type FieldFactoryField =
    IFieldFactoryDividerField |
    IFieldFactoryStylesStringField |
    IFieldFactoryStringField |
    IFieldFactoryTextField |
    IFieldFactoryCheckboxField |
    IFieldFactoryUrlField |
    IFieldFactoryEmailField |
    IFieldFactoryPhoneField |
    IFieldFactoryAssetField |
    IFieldFactoryRadioButtonField |
    IFieldFactoryNumberField |
    IFieldFactorySliderField |
    IFieldFactoryBorderField |
    IFieldFactoryCssBoxField |
    IFieldFactoryDateField |
    IFieldFactoryDateRangeField |
    IFieldFactoryChoiceField |
    IFieldFactoryArrayField |
    IFieldFactorySpacerField |
    IFieldFactoryWidgetField;

