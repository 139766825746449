import { IWidgetLayout, IWidgetLayoutTuple, IWidgetProps, IWidgetSchema, _widgetType } from '../WidgetInterface';
import viewModeRenderer from './SnippetWidgetViewMode';

// const shareModeRenderer = viewModeRenderer;
// const designModeRenderer = lazy(() => import('./SnippetWidgetDesignMode'));

export const SnippetWidgetType = "widget/Snippet";

// export interface ISnippetSchema {
//     label: string;
//     detail?: string;
//     version?: number;
//     createdBy?: string;
//     docsUrl?: string;
//     license?: string;
//     memo?: string;
//     layout: IWidgetLayout
// }

// export interface ISnippetsMap {
//     [snippetName: string]: ISnippetSchema;
// }

export interface ISnippet {
    name?: string;
    version?: string | number;
    detail?: string;
    children?: boolean;
    layout: IWidgetLayout;
    // contextVars?: IContextVarsMap;    // These will be merged (by section)
}

export type ISnippetMap = { [snippetName: string]: ISnippet };

export interface ISnippetWidgetVars {
    _snippetName: string;
}

export interface ISnippetWidgetLayout extends IWidgetLayout, ISnippetWidgetVars { }
export interface ISnippetWidgetInstance extends IWidgetLayoutTuple<ISnippetWidgetLayout, ISnippetWidgetVars> { }
export interface ISnippetWidgetProps extends IWidgetProps<ISnippetWidgetLayout, ISnippetWidgetVars> { }
export interface ISnippetWidgetSchema extends IWidgetSchema<typeof SnippetWidgetType, ISnippetWidgetProps> { }

export const snippetWidgetSchema: ISnippetWidgetSchema = {
    widgetType: SnippetWidgetType,
    viewModeSchema: {
        rendersChildren: false,
        component: viewModeRenderer,
    },
    // shareModeSchema: {
    //     rendersChildren: false,
    //     component: shareModeRenderer,
    // },
    // designModeSchema: {
    //     rendersChildren: false,
    //     component: designModeRenderer,
    // },
}
