import { IWidgetSchema } from "./WidgetInterface";
import { htmltagWidgetSchema } from "./HtmlTagWidget/HtmlTagWidget";
import { layoutContainerWidgetSchema } from "./LayoutContainerWidget/LayoutContainerWidget";
import { snippetWidgetSchema } from "./SnippetWidget/SnippetWidget";
import { spacerWidgetSchema } from "./SpacerWidget/SpacerWidget";
import { textWidgetSchema } from "./TextWidget/TextWidget";
import { fieldWidgetSchema } from "./FieldWidget/FieldWidgetSchema";
//
import { signupFormWidgetSchema } from "./SignupFormWidget/SignupFormWidget";
import { signinFormWidgetSchema } from "./SigninFormWidget/SigninFormWidget";

// import BackgroundImageWidgetSchema from "./BackgroundImage_Widget/BackgroundImage_Widget";
// import NameWidgetSchema from "./Name_Widget/Name_Widget";
// import ContactInfoSchema from "./ContactInfo_Widget/ContactInfo_Widget";
// import MessageWidgetSchema from "./Message_Widget/Message_Widget";

// TO DO -- DYNAMIC LOAD NON-ESSENTIAL WIDGETS
// import ScrollDownPrompt from "./ScrollDownPrompt_Widget/ScrollDownPrompt_Widget";
// import InputFieldSchema from "./InputField_Widget/InputField_Widget";
// import AnimationControllerSchema from "./AnimationController_Widget/AnimationController_Widget";
// import ParticlesWidgetSchema from "./Particles_Widget/Particles_Widget";
// import LottieWidgetSchema from "./Lottie_Widget/Lottie_Widget";
// import TipScrollerSchema from "./TipScroller_Widget/TipScroller_Widget";
// import FAQsWidgetSchema from "./FAQs_Widget/FAQs_Widget";
// import EasyLinksWidgetSchema from "./EasyLinks_Widget/EasyLinks_Widget";

// import TextWidget from "./Text_Widget/Text_Widget";
// import OverlaySnow from "./OverlaySnow_Widget/OverlaySnow_Widget";
// import LottieWidget from "./Lottie_Widget/Lottie_Widget";

export const widgetCollection: { [key: string]: IWidgetSchema<any, any> } = {
    "widget/HtmlTag": htmltagWidgetSchema,
    "widget/LayoutContainer": layoutContainerWidgetSchema,
    "widget/Snippet": snippetWidgetSchema,
    "widget/Spacer": spacerWidgetSchema,
    "widget/Text": textWidgetSchema,
    "widget/Field": fieldWidgetSchema,
    //
    "widget/SignupForm": signupFormWidgetSchema,
    "widget/SigninForm": signinFormWidgetSchema,

    // "widget/BackgroundImage": BackgroundImageWidgetSchema,
    // "widget/Name": NameWidgetSchema,
    // "widget/ContactInfo": ContactInfoSchema,
    // "widget/Message": MessageWidgetSchema,

    // "widget/FAQs": FAQsWidgetSchema,
    // "widget/EasyLinks": EasyLinksWidgetSchema,
    // "widget/ScrollDownPrompt": ScrollDownPrompt,
    // "widget/Particles": ParticlesWidgetSchema,
    // "widget/AnimationController": AnimationControllerSchema,
    // "widget/Lottie": LottieWidgetSchema,
    // "widget/InputField": InputFieldSchema,
    // "widget/TipScroller": TipScrollerSchema,
    // "Text": TextWidget,
    // "ContactInfo": ContactInfoWidget,
    // "OverlaySnow": OverlaySnow,
    // "Lottie": LottieWidget,
}
