/**
 * REFERENCES
 * 
 * https://github.com/emotion-js/emotion/issues/2056
 * https://codesandbox.io/s/stupefied-tdd-j70vt?file=/src/index.js
 * 
 * See
 * 
 */
//=====[ IMPORTS ]=================================================================================
import _logger, { LOGGER_CONFIG_DEFAULT } from "../utils/logger";
import { css, keyframes } from "@emotion/css";
import { COMMENT, compile, DECLARATION, IMPORT, RULESET, Element } from "stylis";
import { serializeStyles, SerializedStyles } from "@emotion/serialize";
import { clampNumber } from "./commonUtils";
// import CleanCSS from "clean-css";
import { minify } from 'csso';

//=====[ LOGGER ]==================================================================================

const __filename = "styleUtils.tsx";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

//=====[ LAZY IMPORTS ]============================================================================
//=====[ ERRORS ]==================================================================================
//=====[ TYPES ]===================================================================================
//=====[ STATE ]===================================================================================
//=====[ HELPERS ]=================================================================================
//=====[ COMPONENT ]===============================================================================

//=====[ FUNCTIONS ]===============================================================================

export const serializeToObject = (elements: Element[]): { [key: string]: any } => {
    const obj: { [key: string]: any } = {};
    elements.forEach((element) => addToObj(element, obj));
    return obj;
};

// /**
//  * Sanitize any style value (string "10px" or number "10") to a number within the desired range
//  * 
//  * @param anyValue 
//  * @param fallback 
//  * @param min 
//  * @param max 
//  * @returns 
//  */
// export const sanitizeStyleValueToNumber = (anyValue: string | number | undefined, fallback?: number, min?: number, max?: number): number => {
//     let num = fallback !== undefined ? fallback : 0;
//     try {
//         switch (typeof anyValue) {
//             case "string":
//                 num = parseInt(anyValue);
//                 break;
//             case "number":
//                 num = anyValue;
//                 break;
//             default:
//                 num = fallback === undefined ? 0 : fallback;
//                 break;
//         }
//     } catch (e) {
//         num = fallback === undefined ? 0 : fallback;
//     }

//     return (min === undefined || max === undefined) ? num : clampNumber(num, min, max);
// };

// /**
//  * Sanitize any style value (string "10px" or number "10") to a px string
//  * 
//  * @param anyValue 
//  * @param fallback 
//  * @param min 
//  * @param max 
//  * @returns         0px, 10px, etc.
//  */
// export const sanitizeStyleValueToPxString = (anyValue: string | number, fallback?: number, min?: number, max?: number): string => {
//     const num = sanitizeStyleValueToNumber(anyValue, fallback, min, max);
//     return `${num}px`;
// };

export const kebobToCamelCase = (s: string) => s.replace(/-./g, (x) => x.toUpperCase()[1]);

const addToObj = (element: any, obj: any) => {
    switch (element.type) {
        case DECLARATION:
            obj[kebobToCamelCase(element.props)] = element.children;
            return;
        case COMMENT:
            return;
        case RULESET:
            if (!element.children.length) return;
            obj[element.props.join(",")] = serializeToObject(element.children);
            return;
        case IMPORT:
            throw new Error("Not sure how to convert this properly to an object");
        default:
            if (!element?.children?.length) return;
            obj[element.value] = serializeToObject(element.children);
            return;
    }
};

export interface IStylesCache {
    // rawCss?: string | { [key: string]: any };
    compiledStyles?: Element[];
    serializedStylesString?: string;
    serializedObjects?: { [key: string]: any };
    // minified?: string;
    // beautified?: string;
    // Emotion
    emotionKeyframes?: { [key: string]: any };
    emotionStyles?: { [key: string]: any };
}

const keyframesSeparator = "@keyframes ";

export const prepareEmotionKeyframes = (cache: IStylesCache) => {
    cache.emotionKeyframes = {};

    for (const property in cache.serializedObjects) {
        const propertyParts = property.split(keyframesSeparator);
        const keyframesName = propertyParts[1];
        if (keyframesName) {
            const keyframeValue = cache.serializedObjects[property];
            cache.emotionKeyframes[keyframesName] =
                typeof keyframeValue === "string"
                    ? keyframes`${keyframeValue}`
                    : keyframes(keyframeValue);
        }
    }
};

/**
 * Walk the style cache and replace animation names with actual class instances
 * 
 * @param cache 
 */
const prepareEmotionStyles = (cache: IStylesCache) => {
    cache.emotionStyles = {};
    prepareEmotionKeyframes(cache);

    for (const property in cache.serializedObjects) {
        const propertyParts = property.split(keyframesSeparator);
        if (propertyParts.length === 1) {
            const styleObj = cache.serializedObjects[property] || {};
            if (styleObj.animation && cache.emotionKeyframes) {
                const keys = Object.keys(cache.emotionKeyframes);
                keys.forEach((key: string) => {
                    let re = new RegExp(`(${key})`, "gmi");
                    styleObj.animation = styleObj.animation.replace(
                        re,
                        cache.emotionKeyframes![key]
                    );
                });
            }
            // console.log("before", styleObj);
            cache.emotionStyles[property] = css`
                ${styleObj}
            `;
            // console.log("cache.emotionStyles[property] = ", cache.emotionStyles[property]);
        }
    }
};

/**
 * 
 * @param rawCss 
 * @param cache Passing an existing cache will update the existing cache
 */
export const prepareCss = (styles: string | { [key: string]: any }, keepDry: boolean = false, cache: IStylesCache = {}): IStylesCache => {
    const styleInputType = typeof styles;
    switch (styleInputType) {
        case "string":
            cache.compiledStyles = compile(styles as string || "");
            cache.serializedObjects = serializeToObject(cache.compiledStyles);
            cache.serializedStylesString = serializeStyles([styles], {} as any).styles || "";   // cache.serializedStylesString = styles as string;
            break;
        case "object":
            cache.serializedStylesString = serializeStyles([styles], {} as any).styles || "";
            cache.compiledStyles = compile(cache.serializedStylesString as string || "");
            cache.serializedObjects = serializeToObject(cache.compiledStyles);  // cache.serializedObjects = styles as object;
            break;
        default:
            throw new Error(`Invalid style [${styleInputType}]`)
    }

    // cache.minified = undefined;
    // cache.beautified = undefined;

    !keepDry && prepareEmotionStyles(cache);
    // console.log("serializedObjects:", cache.serializedObjects);

    return cache;
};

export const minifyCss = (styles: string): string => {
    // return new CleanCSS({}).minify(styles || "").styles;
    return minify(styles || "").css;

    // cache.minified =
    //     cache.minified ||
    //     new CleanCSS({}).minify(styles || "").styles;
    // return cache.minified;
};

export const beautifyCss = (styles: string): string => {
    // return new CleanCSS({ format: "beautify" }).minify(styles || "").styles;
    return minify(styles || "").css;

    // cache.beautified =
    //     cache.beautified ||
    //     new CleanCSS({ format: "beautify" }).minify(styles || "")
    //         .styles;
    // return cache.beautified;
};


/**
 * CssUnits
 * 
 * Ref: https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units
 * 
 */
export type CssLengthType =
    "%" | "px" | "em" | "rem" |
    "ex" | "ch" | "lh" | "vw" | "vh" | "vmin" | "vmax" | "cm" | "mm" | "Q" | "in" | "pc" | "pt";

/**
 * Normalize a style length to a string of specified units (default to %)
 * 
 * @param length 
 * @param units 
 * @returns 
 */
export const cssNormalizeLength = (length: string | number, units: CssLengthType = "%"): string => {
    let _length: number;
    
    switch (typeof length) {
        case "number":
            _length = length;
            break;
        case "string":
            _length = +length;
            if (isNaN(_length)) return length;  // Already a string with units
            break;
        default:
            _length = 0;
    }

    return `${_length}${units}`;
}

/*

transform

PRE-PARSE REGEX (Step 1 of 2 to parse a string into an translate object)
RegEx to parse translate string into an object
RegEx: (\w+)\((.+?)\)
translate(10px, 0, 20px) scale(1.1) rotateY(7deg) translateZ(-1px);
https://regex101.com/r/MT06w6/1
https://stackoverflow.com/questions/3432446/how-to-read-parse-individual-transform-style-values-in-javascript

SERIALIZE

*/

// export const prepareCss = (
//   rawCss: string | { [key: string]: any }
// ): IStylesCache => {
//   const cache: IStylesCache = {
//     // rawCss
//   };

//   switch (typeof rawCss) {
//     case "string":
//       // cache.minified = new CleanCSS({}).minify(rawCss).styles || "";
//       cache.compiledStyles = compile(rawCss || "");
//       cache.serializedObjects = serializeToObject(cache.compiledStyles);
//       // cache.serializedStyles =
//       //   serializeStyles([cache.serializedObjects], {} as any).styles || "";
//       break;

//     case "object":
//       cache.serializedStyles =
//         serializeStyles([rawCss], {} as any).styles || "";
//       cache.compiledStyles = compile(cache.serializedStyles || "");
//       cache.serializedObjects = serializeToObject(cache.compiledStyles);
//       break;

//     default:
//       break;
//   }

//   preapreEmotionStyles(cache);

//   cache.minified = new CleanCSS({}).minify(cache.serializedStyles || "").styles;
//   cache.beautified = new CleanCSS({ format: "beautify" }).minify(
//     cache.serializedStyles || ""
//   ).styles;

//   return cache;
// };

/*

# References
- [Transform between CSS string and JS object #2056](https://github.com/emotion-js/emotion/issues/2056)
- [Styles broken in CRA Production Build but work fine in development mode #2221](https://github.com/emotion-js/emotion/issues/2221)

*/