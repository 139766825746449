import validatorJs from 'validator';
import { asArray, getNestedKeyValue } from './commonUtils';
import { deepEqual } from 'fast-equals';

// lazy loading: const equals = (await import('validator/es/lib/equals')).default;

/**
 * This validator leverages validatorJS and uses the validtor functions therein
 *  REF: (https://github.com/validatorjs/validator.js)
 */

export interface IValidationTest {      // key is validator function name: value is options object passed to validator funciton
    validator: string;                  // NOTE: if a validator (function name) is preceded by a not ! (or double-not !!), it will be applied to the validation result.
    of?: ValidationTest;                // Any/All OF the child validaton tests
    and?: ValidationTest;             // All of the child validaton tests
    varPath?: string;                   // Validation failure reason
    failureReason?: string;             // Validation failure reason
    [key: string]: any;                 // Object of options required for the specific validator
}

export type ValidationTest = IValidationTest | IValidationTest[];

export interface IValidationResult {
    isValid: boolean;                   // Valid flag
    result?: any;                       // Used by sanitizing or normalizing functions
    failureReason?: string[];           // An concatenated string of failure reasons
    failedTest?: ValidationTest;        // The test that failed
    error?: Error;                      // Error
}

export const ValidationError_InvalidTest = "Invalid test";

export type ValidationResult = IValidationResult;
export const isValidationResultValid = (validationResult?: ValidationResult) => validationResult === undefined ? { isValid: true } : validationResult.isValid;

/**
 * Return "clean" validator name without negators, and negator count
 * 
 * @param validatorName name of validator [preceeded with ! or !! negators] (e.g. equal, !equal, !!equal)
 * @returns { validatorName, negator } // clean validator name (without negators), negator count
 */
const parseValidatorName = (validatorName: string): { validatorName: string, negator: number } => {
    const negator = (validatorName.match(/^!*/) || [""])[0].length;
    return { validatorName: validatorName.substring(negator), negator };    // Use (negator % 2) for even/odd
}

const pushFailureReason = (reason?: string, reasonStack: string[] = []) => {
    reason !== undefined && reasonStack.push(reason);
    return reasonStack;
}

const isString = (value: any) => typeof value === "string";
const isNumber = (value: any) => typeof value === "number";
const isObject = (value: any) => typeof value === "object" && value !== null;
const isUndefined = (value: any) => typeof value === "undefined";
const isNull = (value: any) => value === null;

const validateNumber = (value: number, test: IValidationTest) => (
    (test.equalTo === undefined || value === test.equalTo)
    && (test.value === undefined || value === test.value)
    && (test.min === undefined || value >= test.min)
    && (test.max === undefined || value <= test.max)
    && (test.greaterThan === undefined || value > test.greaterThan)
    && (test.lessThan === undefined || value < test.lessThan)
    && (test.even === undefined || value % 2 === 0)
    && (test.odd === undefined || value % 2 === 1)
    && (test.mod === undefined || value % test.mod === test.modResult)
    && (test.notEmpty === undefined || value > 0)     // INTENDED FOR STRINGS
    && (test.empty === undefined || value === 0)      // INTENDED FOR STRINGS
)

const normalizeString = (value: string, test: IValidationTest): { normValue: string, normTestValue: string } => {
    const normValue = test.ignoreCase ? value.toLowerCase() : value;
    const normTestValue = test.ignoreCase ? test.value.toLowerCase() : test.value;
    return { normValue, normTestValue };
}

//
// !!! WARNING !!! SANITIZE SANITIZE SANITIZE ALL THE OPTIONS
//
// Validator Functions to support in validator.js
// https://github.com/validatorjs/validator.js/
//

/**
 * Validate value against ValidationTest (or array of tests)
 * 
 * @param test 
 * @param value 
 * @param _anyOf (not to be used from top level caller)
 * @returns 
 */
const validate = (test: ValidationTest | undefined, value: any, _anyOf: boolean = false): ValidationResult => {
    // No test to run, return valid
    if (test === undefined || test === null || (Array.isArray(test) && test.length === 0)) return { isValid: true };

    // Root Obj
    // const __rootObj: any = _rootObj || (typeof value === "object" ? value : undefined);   // Never override the root object

    // Run the tests
    let vResult: ValidationResult = { isValid: false };
    try {
        const tests = asArray(test);
        // Iterate each key so long as vResult === true
        tests.every((_test) => {
            if (typeof _test !== "object") {
                vResult.failedTest = _test;
                throw new Error(ValidationError_InvalidTest);
            }

            const _value = _test.varPath ? getNestedKeyValue(value, _test.varPath) : value;
            const { validatorName, negator } = parseValidatorName(_test.validator);
            switch (validatorName) {
                case "any":
                    if (!isObject(_test.of)) {
                        vResult.failedTest = _test;
                        throw new Error(ValidationError_InvalidTest);
                    }
                    vResult = validate(_test.of, _value, true);
                    break;

                case "all":
                    if (!isObject(_test.of)) {
                        vResult.failedTest = _test;
                        throw new Error(ValidationError_InvalidTest);
                    }
                    vResult = validate(_test.of, _value, false);
                    break;

                case "isEqual": {
                    const valType = _value === null ? "null" : typeof _value;
                    switch (valType) {
                        case "string":
                            const { normValue, normTestValue } = normalizeString(_value, _test); // ignoreCase
                            vResult.isValid = validatorJs.equals(normValue, normTestValue);
                            break;
                        case "object":
                            vResult.isValid = deepEqual(_value, _test.value);
                            break;
                        default:
                            vResult.isValid = _value === _test.value;
                            break;
                    }
                    break;
                }

                case "contains": {
                    const { normValue, normTestValue } = normalizeString(_value, _test); // ignoreCase
                    vResult.isValid = normValue.includes(normTestValue);
                    break;
                }

                case "isLength":
                    vResult.isValid = isString(_value) && validateNumber(_value.length, _test);
                    break;

                case "isNumber":
                    vResult.isValid = validateNumber(_value, _test);
                    break;

                case "regExp.test": {
                    vResult.isValid = RegExp(_test.regExp).test(_value);
                    break;
                }

                case "isEmail":
                    vResult.isValid = validatorJs.isEmail(_value, _test as any);
                    break;

                // ValidatorJs Support
                case "validateJs:equals":
                    vResult.isValid = validatorJs.equals(_value, _test.comparison);
                    break;

                case "validateJs:contains":
                    vResult.isValid = validatorJs.contains(_value, _test.seed);
                    break;

                case "validateJs:isEmail":
                    vResult.isValid = validatorJs.isEmail(_value, _test as any);
                    break;

                default:
                    vResult.failedTest = _test;
                    throw new Error(ValidationError_InvalidTest);
                    break;
            }

            // If there was a validation error, bail on everything
            if (vResult.error) return false; // BAIL on the loop!

            // Negate operator
            if (negator > 0) {
                vResult.isValid = (negator % 2) ? !vResult.isValid : !!vResult.isValid;
            }

            // Handle nested-AND
            if (vResult.isValid && _test.and) {
                vResult = validate(_test.and, value, false);
            }

            // Loop so long as we have not met the criteria
            if (_anyOf) {
                return !vResult.isValid;    // When anyOf, keep looping until we have a valid test
            }

            // Record the test and reason of failure
            if (!vResult.isValid) {
                if (!vResult.failedTest) {
                    vResult.failedTest = _test;
                    if (_test.failureReason) {
                        vResult.failureReason = pushFailureReason(_test.failureReason, vResult.failureReason);
                    }
                }
            }
            return vResult.isValid;         // False breaks out of loop
        });
    } catch (error: any) {
        vResult.failureReason = pushFailureReason(error.message, vResult.failureReason)
        vResult = { ...vResult, isValid: false, error }
    }

    return vResult;
}

export default validate;
