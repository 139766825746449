import React, { useRef } from "react";
import { getTagAttributes } from '../widgetUtils';
import { _widgetType } from '../WidgetInterface';
import { HtmlTagWidgetType, IHtmlTagWidgetProps } from "./HtmlTagWidget";
import DOMPurify from 'dompurify';

/**
 * Render html tag, with Fragments
 * 
 * SHORTHAND (recommended): htmltag/div, htmltag/p, htmltag/fragment
 * LONG FORM: widget/HtmlTag with tag element continaing tag type
 * 
 * @param props 
 * @returns 
 */
const HtmlTagWidget: React.FC<IHtmlTagWidgetProps> = (props) => {
    const contentRef = useRef<React.ReactElement | null>(null);

    const renderContent = () => {
        // Render any children
        let element: any;
        props.renderChildren?.((children) => {
            // Get the tag type from the tag key or widgetType shorthand
            const tagType = (((props.hydratedLayout?._widgetType === HtmlTagWidgetType)
                ? props.hydratedLayout.tag
                : props.hydratedLayout?._widgetType?.split("/")[1].toLowerCase())) || "fragment";

            const tagAttributes = getTagAttributes(props, props.hydratedLayout?.tagAttributes);

            switch (tagType) {
                case "fragment":
                    element = (React.createElement(React.Fragment, { key: tagAttributes.key }, children));
                    break;
                case "htmlstring":
                    // TODO: Move this to a global sanitizer in the loader to improve performance??
                    const cleanHtmlString = props.hydratedLayout?._debug ? (props.hydratedLayout as any)?.htmlString : DOMPurify.sanitize((props.hydratedLayout as any)?.htmlString);
                    element = (<div {...tagAttributes} dangerouslySetInnerHTML={{ __html: cleanHtmlString }} />); // NO CHILDREN ALLOWED WHEN SETTING INNERHTML!
                    break;
                default:
                    element = (React.createElement(tagType, tagAttributes, children));
                    break;
            }
        });
        return element;
    }

    // SSR render on first pass, otherwise use contentRef that may be updated bu hooks
    let content: React.ReactElement | null = contentRef.current || renderContent() || null;
    return content;
};

export default HtmlTagWidget;