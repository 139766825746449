import React, { useRef } from 'react';
import { getTagAttributes } from '../widgetUtils';
import type { ISpacerWidgetProps } from './SpacerWidget';

const SpacerWidgetViewMode: React.FC<ISpacerWidgetProps> = (props) => {
    const contentRef = useRef<React.ReactElement | null>(null);

    const renderContent = () => {
        const element = <div {...getTagAttributes(props)} style={{ height: props.hydratedLayout?.space }} />;
        contentRef.current = element;
        return element;
    }

    // SSR render on first pass, otherwise use contentRef that may be updated bu hooks
    let content: React.ReactElement | null = contentRef.current || renderContent() || null;
    return content;
};

export default SpacerWidgetViewMode;
